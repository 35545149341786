import { Dispatch, SetStateAction, useState, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonGroup, Modal, ToggleButton } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import ModalComponent from 'components/ModalComponent';
import Button from "components/Button";
import Input from "components/Input";
import { TagData } from "types/board";
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { useBoard } from "providers/Board";
import { api } from "services";
import { toast } from "utils/toast";
import logger from "utils/logger";
import { Script } from "..";
import { AxiosResponse } from "axios";

interface ModalGroupProps {
  show: boolean;
  type: "group" | "subgroup" | "message";
  group: Script;
  subgroup: string;
  message: string;
  scriptsData: Script[];
  onHide?: (newData?: Script) => void;
}

const ModalGroup = ({
  show,
  type,
  group,
  subgroup,
  message,
  onHide,
  scriptsData,
  ...rest
}: ModalGroupProps) => {
  const { token } = useAuth();
  const { userData } = useUser();

  const [isLoading, setIsLoading] = useState(false);

  const typeText = (type === "group" ? "Grupo" : (type === "subgroup" ? "Subgrupo" : "Mensagem"));

  var modalTitle = "Adicionar";

  if ((type === "group" && group && group.id) || (type === "subgroup" && subgroup) || (type === "message" && message)) {
    modalTitle = "Editar";
  }

  const schema = yup.object().shape({
    description: yup.string().trim().required("*Campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    control,
    getValues,
    reset,
    watch,
    trigger,
    formState: { errors },
  } = useForm<Script>({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: Script) => {
    // Lógica de envio
    await saveGroup(data, (newData: Script) => {
      if (onHide) onHide(newData);
    });
  };

  const saveGroup = async (data: Script, callback?: (newData: Script) => void) => {
    // debugger;
    if (!data.description || data.description === "") {
      toast.fire({
        icon: "error",
        title: `Informe ${type === "message" ? 'a descrição da' : ' um título do'} ${typeText}`,
      });
      return;
    }
    const newDescription = data.description;
    var body = null;
    if (type === "group") {
      body = {
        ...group,
        description: newDescription,
        order: scriptsData.length ?? 0
      };
    } else if (type === "subgroup") {
      if (subgroup !== '') {
        body = {
          ...group, // Copia o objeto original
          subGroups: group.subGroups?.map(subGroup =>
            subGroup.id === subgroup
              ? { // Altera apenas o subGroup correspondente
                ...subGroup,
                description: newDescription,
                order: group?.subGroups?.length ?? 0
              }
              : subGroup // Mantém os outros subGroups inalterados
          )
        }
      } else {
        body = {
          ...group, // Copia o objeto original
          subGroups: [
            ...(group.subGroups || []), // Copia os subGroups existentes
            {
              description: newDescription,
              order: group.subGroups?.length ?? 0
            }
          ]
        }
      }
    } else {
      if (message) {
        if (subgroup !== '') {
          body = {
            ...group, // Copia o objeto original
            subGroups: group.subGroups?.map((subGroup) =>
              subGroup.id === subgroup
                ? {
                  ...subGroup, // Atualiza o subgrupo correspondente
                  messages: subGroup.messages?.map((msg) =>
                    msg.id === message
                      ? { // Atualiza a mensagem correspondente ao id
                        ...msg,
                        message: newDescription,
                        order: subGroup.messages?.length ?? 0
                      } 
                      : msg // Mantém as mensagens restantes inalteradas
                  ),
                }
                : subGroup // Mantém os outros subgrupos inalterados
            ),
          }
        } else {
          body = {
            ...group, // Copia o objeto original
            messages: group.messages?.map((msg) =>
              msg.id === message
                ? {  // Atualiza a mensagem correspondente ao id
                  ...msg, 
                  message: newDescription,
                  order: group.messages?.length ?? 0
                }
                : msg // Mantém as mensagens restantes inalteradas
            ),
          }
        }

      } else {
        if (subgroup !== '') {
          body = {
            ...group, // Copia o objeto original
            subGroups: group.subGroups?.map((subGroup) =>
              subGroup.id === subgroup
                ? {
                  ...subGroup, // Atualiza o subgrupo correspondente
                  messages: [
                    ...(subGroup.messages || []), // Garante que as mensagens existentes sejam copiadas, ou inicializa um array vazio
                    {
                      message: newDescription,
                      order: subGroup.messages?.length ?? 0
                    }, // Adiciona a nova mensagem
                  ],
                }
                : subGroup // Mantém os outros subgrupos inalterados
            ),
          }
        } else {
          body = {
            ...group, // Copia o objeto original
            messages: [
              ...(group.messages || []), // Garante que as mensagens existentes sejam copiadas, ou inicializa um array vazio
              {
                message: newDescription, 
                order: group.messages?.length ?? 0
              }, // Adiciona a nova mensagem
            ],
          }
        }
      }
    }

    if (body === null) {
      toast.fire({
        icon: "error",
        title: `Algo deu errado.`,
      });
      return;
    }

    setIsLoading(true);

    var returnTitle = `${typeText} cadastrad${type === "message" ? 'a' : 'o'}`;
    if ((type === "group" && group && group.id) || (type === "subgroup" && subgroup) || (type === "message" && message)) {
      returnTitle = `${typeText} atualizad${type === "message" ? 'a' : 'o'}`;
    }

    try {
      logger.log("saveGroup");
      var response: AxiosResponse;
      if (group && group.id) {
        response = await api.put(`/Crm/Scripts/${group.id}`, body);
      } else {
        response = await api.post(`/Crm/Scripts`, body);
      }
      if (response.data) {
        toast.fire({
          icon: "success",
          title: `${returnTitle} com sucesso!`,
        });
        if (callback) {
          callback(response.data);
          return;
        }
      }

    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    let description = "";

    if (type === "group" && group?.description) {
      description = group.description;
    } else if (type === "subgroup" && subgroup) {
      description = group.subGroups?.find((sg) => sg.id === subgroup)?.description || "";
    } else if (type === "message") {
      if (subgroup) {
        description = group.subGroups
          ?.find((sg) => sg.id === subgroup)
          ?.messages?.find((msg) => msg.id === message)?.message || "";
      } else if (message) {
        description = group.messages?.find((msg) => msg.id === message)?.message || "";
      }
    }

    if (description) {
      setValue("description", description);
      setFocus("description");
    } else {
      reset();
    }
  }, [group, type, subgroup, message, setValue, setFocus, reset]);

  return (
    <Modal
      show={show}
      size={type === 'message' ? undefined : 'sm'}
      {...rest}
      backdrop="static"
      centered
      onHide={() => {
        if (onHide) onHide();
      }}>
      <Modal.Header
        className="bg-white sticky-top modal-header p-3 m-0"
        closeButton
      >
        <Modal.Title className="h5 m-0">
          {modalTitle} {typeText} {modalTitle === "Adicionar" && (<span className="opacity-50">
            {type !== "group" ? 'em' : ''} <b>{type !== "group" && group && group.description != '' ? group.description : ''}
              {subgroup != '' ? ' / ' + (group.subGroups?.find((sg) => sg.id === subgroup)?.description || "") : ''}</b>
          </span>)}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          {type === 'message' ? (
            <TextareaAutosize
              className="form-control"
              placeholder={`Descrição da ${typeText}`}
              autoFocus
              tabIndex={1}
              minRows={4}
              {...register("description")}
            ></TextareaAutosize>
          ) : (
            <Input
              type="text"
              placeholder={`Título do ${typeText}`}
              autoComplete="off"
              autoFocus
              tabIndex={1}
              error={errors.description ? errors.description.message : ""}
              register={register("description")}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button type="button" className="btn-light rounded-pill" tabIndex={3} onClick={() => {
            if (onHide) onHide();
          }}>
            Cancelar
          </Button>

          <Button disabled={isLoading ? true : false} type="submit" tabIndex={2} className="btn-primary rounded-pill">
            Enviar
            {isLoading ? (
              <CircularProgress
                color="inherit"
                size={12}
                className="ms-2"
              />
            ) : (
              <i className="uil uil-arrow-right ms-2"></i>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ModalGroup;

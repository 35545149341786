import { Dispatch, SetStateAction, useState, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonGroup, Modal, ToggleButton } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import Button from "components/Button";
import Input from "components/Input";
import { TagData } from "types/board";
import { hsvaToHex, hexToRgba, rgbaToRgb, getContrastingColor } from '@uiw/color-convert';
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { useBoard } from "providers/Board";
import { api } from "services";
import { toast } from "utils/toast";
import logger from "utils/logger";
import { Swatch } from "@uiw/react-color";

interface ModalTagProps {
  onHide?: () => void;
}

const ModalTag = ({
  onHide,
}: ModalTagProps) => {
  const { token } = useAuth();
  const { userData } = useUser();
  
  const {
    isLoading,
    createTag,
  } = useBoard();

  const schema = yup.object().shape({
    color: yup.string().required("*Campo obrigatório"),
    title: yup.string().trim().required("*Campo obrigatório"),
  });

  const [tagColor, setTagColor] = useState("#aba5a5");

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    control,
    getValues,
    reset,
    watch,
    trigger,
    formState: { errors },
  } = useForm<TagData>({
    resolver: yupResolver(schema),
    defaultValues: {
      color: tagColor,
      title: ""
    },
  });

  const Point = (props: { color?: string; checked?: boolean }) => {
    if (!props.checked) return null;
    return (
      <div
        style={{
          height: 5,
          width: 5,
          borderRadius: '50%',
          backgroundColor: getContrastingColor(props.color!),
        }}
      />
    );
  }

  const onSubmit = async (data: TagData) => {
    // Lógica de envio
    await createTag(data);
    reset();
    if (onHide) onHide();
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <Input
              type="text"
              placeholder="Título da Tag"
              autoFocus
              autoComplete="off"
              error={errors.title ? errors.title.message : ""}
              name="title"
              register={register("title")}
            />
          </div>
          <div className="row gx-3 align-items-center mb-2">
            <div className="col col-auto pb-1">
              Color:
            </div>
            <div className="col">
              <Swatch
                colors={[
                  { color: '#aba5a5', title: 'Default' },
                  { color: '#373737', title: 'Preto' },
                  { color: '#f33b12', title: 'Vermelho' },
                  { color: '#f38412', title: 'Laranja' },
                  { color: '#FFC400', title: 'Amarelo' },
                  { color: '#36B37E', title: 'Verde' },
                  { color: '#00B8D9', title: 'Ciano' },
                  { color: '#0052CC', title: 'Azul' },
                  { color: '#6610f2', title: 'Roxo' },
                  { color: '#f312c3', title: 'Rosa' }]}
                color={tagColor}
                rectProps={{
                  children: <Point />,
                  style: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid var(--bs-border-color)'
                  },
                }}
                onChange={(hsvColor) => {
                  setValue('color', hsvaToHex(hsvColor));
                  setTagColor(hsvaToHex(hsvColor));
                }}
              />
            </div>
          </div>
          <div className="mb-4 text-center">
            {errors.color && <p className="text-danger small text-start">{errors.color.message}</p>}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button type="button" className="btn-light rounded-pill" onClick={() => {
            reset();
            if (onHide) onHide();
          }}>
            Cancelar
          </Button>

          <Button disabled={isLoading ? true : false} type="submit" className="btn-primary rounded-pill" onClick={() => { }}>
            Enviar<i className="uil uil-arrow-right ms-2"></i>
          </Button>
        </Modal.Footer>
      </form>
    </>
  );
};

export default ModalTag;

import moment from "moment";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAuth } from "providers/Auth";
import { api } from "services";
import { toast } from "utils/toast";

interface VersionNotesProviderProps {
  children: ReactNode;
}

interface VersionNotesProviderData {
  versionNotes: any;
  localVersion: string;
  showNoShow: boolean;
  showNotesModal: boolean;
  setShowNotesModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const VersionNotesContext = createContext<VersionNotesProviderData>(
  {} as VersionNotesProviderData
);

const versionNotes = [
  {
    "version": "1.7",
    "content": [
      {
        "title": `<i class="uil uil-chart-pie-alt text-primary me-2"></i>Gráficos no Dashboard`,
        "description": `Gráficos de <b>Valor, Tags e Origem</b> foram adicionados ao Dashboard contendo dados atualizados do board selecionado.`
      },
      {
        "title": `<i class="uil uil-medical-square text-primary me-2"></i>Convênio na task.`,
        "description": `Agora é possível atribuir um convênio para cada task.`
      }
    ]
  },{
    "version": "1.6",
    "content": [
      {
        "title": `<i class="uil uil-list-ul text-primary me-2"></i>Scripts`,
        "description": `Mantenha seu padrão de atendimento cadastrando e organizando seus scripts de vendas/atendimento em grupos, subgrupos e mensagens.
          <ul>
            <li>Copie e cole as mensagens pré programadas na conversa.</li>
            <li>Assinale as mensagens já utilizadas por paciente.</li>
          </ul>`
      }
    ]
  },{
    "version": "1.5",
    "content": [
      {
        "title": `<i class="uil uil-calendar-alt text-primary me-2"></i>Agendamentos Conectadoc`,
        "description": `Com um board definido como <b>Default</b> e uma colunda definida como <b>Agenda Conectadoc</b>, seus agendamentos aparecerão automaticamente nesta coluna.`
      }
    ]
  },{
  "version": "1.4",
  "content": [
    {
      "title": `<i class="uil uil-tag-alt text-primary me-2"></i>Filtro por Tags`,
      "description": `Encontre com mais facilidade seus pacientes com o novo filtro por tag na tela de Boards e Pacientes.`
    },
    {
      "title": `<i class="uil uil-paperclip text-primary me-2"></i>Anexos por Card`,
      "description": `Agora você pode anexar arquivos(PDF ou de imagem) diretamente em seus cards.`
    },
    {
      "title": `<i class="uil uil-comment-alt-lines text-primary me-2"></i>Observações Clínicas e de Atendimento`,
      "description": `Organize e veja o histórico de suas observações. Separe as observações Clínicas das de Atendimento.`
    },
  ]
},{
  "version": "1.3",
  "content": [
    {
      "title": `<i class="uil uil-server-connection text-primary me-2"></i>Tela de Integrações`,
      "description": `Uma página destinada a conectar API's externas como WhatsApp, Instagram (em breve) e Google Calendar (em breve).`
    },
    {
      "title": `<i class="uil uil-comments-alt text-primary me-2"></i>Tela de Conversas`,
      "description": `Após fazer a intergração com seu WhatsApp, converse com seus pacientes diretamente pelo CRM. <small class="d-block small opacity-50">Em fase de testes, apenas para mensagens.</small>`
    },
    {
      "title": `<i class="uil uil-chart-pie-alt text-primary me-2"></i>Tela de Dashboard`,
      "description": `Um resumo geral de todos os seus dados de pacientes, vendas, leads, conversas, etc..`
    },
    {
      "title": `<i class="uil uil-usd-circle text-primary me-2"></i>Novo campo de Valor`,
      "description": `Agora você pode ter uma estiva de valor que está em cada coluna dos seus boards.`
    },
    {
      "title": `<i class="uil uil-tag-alt text-primary me-2"></i>Organize seu cards por TAGS.`,
      "description": `Facilite sua organização adicionando tags em seu cards!`
    },,
    {
      "title": `<i class="uil uil-house-user text-primary me-2"></i>Origem de Leads`,
      "description": `Com o novo campo de Origem do Lead, você pode controlar de onde seus leads estão chegando.`
    },
  ]
},{
  "version": "1.2",
  "content": [
    {
      "title": `<i class="uil uil-clock text-primary me-2"></i>Campo de hora em agendamentos.`,
      "description": `Adicionado o campo para marcar a hora das colunas que possuem agendamento.`
    },
    {
      "title": `<i class="uil uil-calendar-alt text-primary me-2"></i>Tela de Calendário`,
      "description": `Nova tela organizadora das tasks no formato de calendário.<br/>Apenas as cards que possuem o campo de data definido vão aparecer aqui.`
    },
  ]
},{
  "version": "1.1",
  "content": [
    {
      "title": `<i class="uil uil-user-arrows text-primary me-2"></i>Encaminhamento de Pacientes`,
      "description": `Encaminhe um paciente para outro <b>board</b> ou para outro <b>profissional</b>.`
    },
    {
      "title": `<i class="uil uil-star text-primary me-2"></i>Board Default`,
      "description": `Marque um board como 'padrão' para receber encaminhamentos de outros profissionais.`
    },
    {
      "title": `<i class="uil uil-layers text-primary me-2"></i>Observações em pacientes Duplicados`,
      "description": `Caso um paciente esteja duplicado no board, ao edita-lo, é possível ver as observações de todos os cards que tem o mesmo paciente.`
    },
    {
      "title": `<i class="uil uil-file-export text-primary me-2"></i>Exportar Board`,
      "description": `Agora é possível exportar e salvar uma cópia de cada board, com ou sem os cards.`
    },
    {
      "title": `<i class="uil uil-users-alt text-primary me-2"></i>Atualizada a lista de Pacientes`,
      "description": `Agora os pacienes duplicados estão agrupados.`
    },
  ]
},{
  "version": "1.0",
  "content": [
    {
      "title": `<i class="uil uil-notes text-primary me-2"></i>Notas de Versão`,
      "description": `Fique por dentro das últimas atualizações das funcionalidades no seu CRM.`
    },
    {
      "title": `<i class="uil uil-user-plus text-primary me-2"></i>Paciente ou Task`,
      "description": `Você pode optar por adicionar um Paciente ou uma Task, esta última não possui um paciente relacionado.<br/><br/>E agora você também pode cadastrar um paciente diretamente pelo CRM.`
    },
    {
      "title": `<i class="uil uil-qrcode-scan text-primary me-2"></i>Novo menu QrCode.`,
      "description": `Imprima o qr code para seus pacientes preencherem o formulário para entrar no seu CRM.
      <br/>
      <br/>
      Você pode imprimir um QrCode para o cada board.`
    },
    {
      "title": `<i class="uil uil-chat-bubble-user text-primary me-2"></i>Novo menu Pacientes.`,
      "description": `Liste e visualize e filtre seus pacienes em uma única tela.`
    },
    {
      "title": `<i class="uil uil-calendar-alt text-primary me-2"></i>Campo para Datas`,
      "description": `Configure a coluna para exibir o campo de data ao arrastar um card para ela.`
    },
    {
      "title": `<i class="uil uil-layers text-primary me-2"></i>Pacientes Duplicados`,
      "description": `Quando o paciente aparece mais de uma vez no board, ele é automaticamente marcado como "duplicado".<br/><br/>Clique sobre o ícone no card para exibir apenas este paciente no board.`
    },
    // {
    //   "title": `<i class="uil uil-tag-alt text-primary me-2"></i>TAGs por card.`,
    //   "description": `Organize seus cards com tags.`
    // }
  ]
}];

export const VersionNotesProvider = ({
  children,
}: VersionNotesProviderProps) => {

  const localVersion:any = localStorage.getItem(`versionNotes`) ? localStorage.getItem(`versionNotes`)?.split("|") : null;
  const [showNotesModal, setShowNotesModal] = useState((versionNotes && localVersion && versionNotes[0].version == localVersion[0]) ? false : true);
  const showNoShow = true;
  return (
    <VersionNotesContext.Provider
      value={{
        versionNotes,
        localVersion,
        showNoShow,
        showNotesModal,
        setShowNotesModal,
      }}
    >
      {children}
    </VersionNotesContext.Provider>
  );
};

export const useVersionNotes = () => useContext(VersionNotesContext);

import { useState, useEffect, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { Modal, Accordion, Dropdown, OverlayTrigger, Tooltip, Tab, Nav } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import SelectTag, { SelectOptions } from 'components/SelectTag';
import { hsvaToHex, hexToRgba, rgbaToRgb, rgbaStringToHsva, getContrastingColor } from '@uiw/color-convert';
import { useAuth } from "providers/Auth";
import { usePatient } from "providers/Patient";
import { useBoard } from "providers/Board";
import { useUser } from "providers/User";
import { PatientData } from "types/patient";
import { Board, BoardDefault, Id, Column, ColumnDefault, Task, TaskDefault, TaskOrigin, Tag, Appointment } from "types/board";
import { cpfMask, mobilePhoneMask } from "utils/formMasks";
import Swal from "sweetalert2";
import { handleTwoDecimalPlacesInput } from "utils/handleInput";
import { numberValue } from "utils/formMasks";
import logger from "utils/logger";
import { isDev } from 'utils/isDev';
import { useDarkMode } from "providers/DarkMode";
import _ from "lodash";

moment.locale('pt-br');

interface PropsTaskTab {
  task: Task;
  searchPatientPhone?: string;
}

interface NewTask {
  newColumnId?: Id;
  title: string;
  date?: string;
  time?: string;
  taskVal?: string | null;
  taskOrigin?: SelectOptions[];
  healthInsurance?: SelectOptions[];
  tags?: SelectOptions[];
  othersTaskOrigin?: string;
  description?: string;
  descriptionAdmin?: string;
  appointment?: Appointment;
}

function TaskTab({
  task,
  searchPatientPhone
}: PropsTaskTab) {
  const { token, userRole } = useAuth();
  const { userData, setUserData } = useUser();

  const subtasksData = [
    { id: 1, name: "Subtask 1", completed: false },
    { id: 2, name: "Subtask 2", completed: true },
  ];

  const {
    patients,
    setPatients,
    patientData,
    setPatientData,
    editPatientData,
    patientLoading,
    isLoadingPaciente,
    isLoadingPatientSearch,
    getPatientById,
    getPatientByCpf,
    searchPatient,
    getPatientAppointments,
    appointments,
    setAppointments,
    showAddPatientModal,
    setShowAddPatientModal,
    getAppointmentById,
    appointmentData,
    setAppointmentData,
  } = usePatient();

  const {
    boards,
    setBoards,
    getBoards,
    boardData,
    isLoading,
    isLoadingBoards,
    setBoardData,
    tasksData,
    setTasksData,
    editTaskData,
    setEditTaskData,
    editColumnData,
    setEditColumnData,
    columnsData,
    setColumnsData,
    taskName,
    setTaskName,
    taskDescription,
    setTaskDescription,
    taskDate,
    setTaskDate,
    taskMoveTo,
    setTaskMoveTo,
    taskType,
    setTaskType,
    taskId,
    setTaskId,
    showAddTaskModal,
    setShowAddTaskModal,
    showForwardPatientModal,
    setShowForwardPatientModal,
    createBoard,
    updateBoard,
    deleteBoard,
    getBoardById,
    getTasksByBoard,
    getPatientsByBoards,
    patientsByBoard,
    setPatientsByBoard,
    addColumnToBoard,
    editColumnFromBoard,
    addTaskToBoardColumn,
    getTasksByColumn,
    delColumnFromBoard,
    delTaskFromBoardColumn,
    editTaskFromBoardColumn,
    moveTaskBetweenBoardColumn,
    autoUpdate,
    setAutoUpdate,
    stopAutoUpdate,
    setStopAutoUpdate,
    checkDuplicatedTasks,
    keepDuplicates,
    setKeepDuplicates,
    showDuplicates,
    setShowDuplicates,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    searchTerm,
    setSearchTerm,
    filteredTasks,
    setFilteredTasks,
    handleSearchChange,
    updateTasksOrder,
    handleDeleteTask,
    handleTaskModal,
    columnAddIndex,
    setColumnAddIndex,
    columnName,
    setColumnName,
    columnId,
    setColumnId,
    showAddColumnModal,
    setShowAddColumnModal,
    hex,
    setHex,
    createColumn,
    deleteColumn,
    updateColumn,
    showModalTag,
    setShowModalTag,
    clearData,
    updateTask,
    tags,
    setTags,
    getTags,
    leadOrigin,
    OTHERS_LEAD_ORIGIN,
    showLeadModal,
    setShowLeadModal
  } = useBoard();

  const { darkMode } = useDarkMode();

  const schemaTask = yup.object().shape({
    title: yup.string().when({
      is: (exists: any) => !!exists,
      then: yup.string().required("*Campo obrigatório"),
      otherwise: yup.string().nullable(),
    }),
    description: yup.string().nullable(),
    columnId: yup.string().nullable(),
    date: yup.string().nullable().test('format', 'A data deve estar no formato dd/mm/yyyy', (value) => {
      if (!value || value == "") return true;
      return moment(value, 'YYYY-MM-DD', true).isValid();
    }),
    time: yup.string().nullable().test('format', 'A hora deve estar no formato hh:mm', (value) => {
      if (!value || value == "") return true;
      return moment(value, 'HH:mm', true).isValid();
    }),
    taskVal: yup.string().nullable(),
    taskOrigin: yup.mixed().nullable(),
    tags: yup.mixed().nullable(),
    othersTaskOrigin: yup.string()
      .nullable()
      .when('taskOrigin', {
        is: (value: SelectOptions) => value?.value === OTHERS_LEAD_ORIGIN,  // Check if taskOrigin has a value of OTHERS_LEAD_ORIGIN
        then: yup.string().required("*Campo obrigatório"),
        otherwise: yup.string().nullable(),
      }),
    healthInsurance: yup.mixed().nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setFocus,
    control,
    reset,
    formState: { errors },
  } = useForm<NewTask>({
    resolver: yupResolver(schemaTask),
    defaultValues: {
      tags: editTaskData.tags
        ? tags.filter(item1 => editTaskData.tags?.some(item2 => item2.id === item1.id)).map((item: any) => ({ value: item.id, label: item.tagName, color: item.color }))
        : [],
      taskOrigin: leadOrigin.filter(item => item.value === (searchPatientPhone ? 2 : editTaskData.taskOrigin)), //2 = whatsapp
      healthInsurance: appointmentData && appointmentData.healthInsurance
        ?
        appointmentData.healthInsurance
          ? userData.healthInsurance?.filter(item => item.id === appointmentData?.healthInsurance?.id).map((item: any) => ({ value: item.id, label: item.name }))
          : []
        :
        editTaskData.healthInsurance
          ? userData.healthInsurance?.filter(item => item.id === editTaskData.healthInsurance?.id).map((item: any) => ({ value: item.id, label: item.name }))
          : [],
    }
  });

  const onSubmit = (task: NewTask) => {

    console.log("Form Data:", task);
    console.log("Errors:", errors);
    const descriptionInput = document.querySelector('#modal-task #description') as HTMLTextAreaElement;
    const descriptionText: string = (descriptionInput ? (descriptionInput.value).trim() : "");

    const save = async () => {
      const formatedTags = task.tags?.map((tag) => ({ tagName: tag.label, color: tag.color, id: tag.value }));
      const appointment = editTaskData.appointment?.scheduleId ? editTaskData.appointment : updateTaskDateTime(task.date || "", task.time || "");

      const taskOrigin = task.taskOrigin ? (task.taskOrigin[0] ? task.taskOrigin[0]?.value : task.taskOrigin?.value) as number : undefined;
      const healthInsurance = task.healthInsurance ? ({ id: task.healthInsurance.value as number, name: task.healthInsurance.label }) : undefined;
      var valor = task.taskVal ? parseFloat(task.taskVal?.replace(/\./g, '').replace(',', '.')) : null;

      const colId: Id = editTaskData.columnId ? editTaskData.columnId : columnId;

      var newPatient = editTaskData.patient ?? undefined;
      if (searchPatientPhone != "") {
        newPatient = {
          ...newPatient,
          whatsAppId: searchPatientPhone
        };
      }
      if (patientData && patientData.id) {
        newPatient = {
          ...newPatient,
          id: patientData.id,
          name: patientData?.name ?? "",
          email: patientData?.email,
        };
      }

      const newTaskData = {
        ...editTaskData,
        ...task,
        columnId: colId,
        tags: formatedTags,
        appointment: appointment,
        taskOrigin: taskOrigin,
        healthInsurance: healthInsurance,
        taskVal: valor,
        othersTaskOrigin: (taskOrigin === OTHERS_LEAD_ORIGIN ? task.othersTaskOrigin : ""),
        patient: newPatient
      };
      console.log(newTaskData);

      if (editTaskData.id) {
        if (task.newColumnId && task.newColumnId !== "" && task.newColumnId != colId) {
          const dataMove = {
            ...newTaskData,
            columnId: task.newColumnId
          }
          delete dataMove.newColumnId;
          await moveTaskBetweenBoardColumn(boardData.id, colId, editTaskData.id, dataMove);
          editTaskFromBoardColumn(boardData.id, dataMove.columnId, editTaskData.id, dataMove);
        } else {
          editTaskFromBoardColumn(boardData.id, colId, editTaskData.id, newTaskData);
        }
      } else {
        addTaskToBoardColumn(boardData.id, columnId, {
          ...newTaskData,
          type: taskType ?? 0,
        }, false, () => {
          if (searchPatientPhone) {
            setShowLeadModal(false);
            setShowAddPatientModal(false);
          }
        });
      }
    }
    if (editTaskData.id && descriptionText != "") {
      Swal.fire({
        title: `Deseja salvar sem enviar a observação?`,
        icon: "question",
        iconColor: 'var(--bs-danger)',
        position: "center",
        showConfirmButton: true,
        confirmButtonText: "Sim, salvar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
        reverseButtons: true,
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          confirmButton: "btn btn-outline-danger",
          cancelButton: "btn btn-light",
        },
      }).then((res) => {
        if (res.isConfirmed) {
          save();
        }
      });
    } else {
      save();
    }
  };

  const calcAge = (date: any) => {
    var years = moment(moment()).diff(date, 'years');
    var months = moment(moment()).diff(date, 'months');
    var days = moment(moment()).diff(date, 'days');
    return (years > 0 ? years + " ano" + (years != 1 ? "s" : "") : (months > 0) ? months + (years != 1 ? " meses" : " mês") : days + " dia" + (days != 1 ? "s" : ""));
  }

  const handleSearchPatient = (value: string) => {
    setValue("title", value ?? "");
    if (value) {
      if (value.length > 2) {
        searchPatient((value).replaceAll(".", "").replaceAll("-", ""), searchPatientPhone);
      }
    } else {
      setPatients([]);
    }
  };

  const updateTaskDateTime = (newDate: string | null, newTime: string | null) => {
    // Se o valor da data ou da hora for inválido, mantém o valor anterior
    const currentDate = newDate ? moment(newDate).format("YYYY-MM-DD") : (editTaskData.appointment?.date ? editTaskData.appointment?.date.split(" ")[0] : "");
    const currentTime = newTime || (taskDate ? taskDate.split(" ")[1] : "00:00");
    const combinedDateTime = `${currentDate} ${currentTime}`;
    const finalDate = moment(combinedDateTime, "YYYY-MM-DD HH:mm").toISOString(true);
    if (currentDate == "") {
      return undefined;
    }
    return {
      date: finalDate
    };
  };

  const taskById = (id: Id) => {
    const taskIndex = tasksData.findIndex((t) => t.id === id);
    if (tasksData && tasksData[taskIndex]) {
      return tasksData[taskIndex];
    } else {
      return {} as Task;
    }
  }

  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const [obsSelected, setObsSelected] = useState("clinica");

  useEffect(() => {
    if (patientData && patientData.finish) {
      setShowAddPatientModal(false);

      setPatients([]);
      if (editTaskData.id && patientData.name) {
        var newData = {
          ...editTaskData,
          title: patientData.name,
          patient: {
            ...editTaskData.patient,
            name: patientData.name
          }
        };
        logger.log(newData);
        updateTask(newData);
        setStopAutoUpdate(false);
      }
    }
  }, [patientData]);

  useEffect(() => {
    if (!showAddTaskModal) {
      setPatients([]);
      setPatientData({} as PatientData);
      reset();
    }
  }, [showAddTaskModal]);

  useEffect(() => {
    if (searchPatientPhone) {
      handleSearchPatient(searchPatientPhone);
    }
  }, []);

  const handleError = (errors: any) => {
    console.log("Erros capturados:", errors);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, handleError)} >
        <Modal.Body className="bg-light px-3 pb-1">
          {isLoadingPaciente || isLoading ? (
            <div className="text-center py-5">
              <CircularProgress size={40} />
            </div>
          ) : (
            <>
              {patientData && patientData.name ? (<>
                {editTaskData.patient ? (
                  <>
                    <label className="small me-auto"><i className="uil uil-user me-2"></i>Paciente:</label>
                    <Accordion className="mb-3">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>{patientData.name}</Accordion.Header>
                        <Accordion.Body>
                          <div className="row g-2 small">
                            {(patientData.dddMobilePhone && patientData.mobilePhone) && (
                              <div className="col-12 col-sm-6">
                                <span className="d-block small text-muted">Telefone</span>
                                {mobilePhoneMask(`${patientData.dddMobilePhone} ${patientData.mobilePhone}`)}
                              </div>
                            )}
                            {patientData.birthDate && (
                              <div className="col-12 col-sm-6">
                                <span className="d-block small text-muted">Data Nascimento</span>
                                {moment(patientData.birthDate).format("DD/MM/YYYY")} - <b>{calcAge(patientData.birthDate)}</b>
                              </div>
                            )}
                            {patientData.email && (
                              <div className="col-12">
                                <span className="d-block small text-muted">E-mail</span>
                                {patientData.email}
                              </div>
                            )}
                          </div>
                          <div className="text-center pt-3">
                            <Button type="button" className="btn-sm btn-outline-primary py-0 rounded-pill" onClick={() => {
                              setShowAddPatientModal(true);
                              setStopAutoUpdate(true);
                            }}>
                              <i className="uil uil-pen"></i> Editar dados do paciente
                            </Button>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                ) : (
                  <>
                    <label className="small me-auto"><i className="uil uil-user-circle me-2"></i>Paciente:</label>
                    <div className="border bg-white rounded py-2 ps-3 mb-3">
                      <div className="row g-0">
                        <div className="col align-self-center fw-semibold lh-sm">
                          {patientData.name}
                        </div>
                        <div className="col col-auto">
                          <a role="button" className="btn btn-link text-danger py-0 rounded-pill" onClick={() => { handleSearchPatient(patientData.name ?? ""); setPatientData({ cpf: "" }); }}>
                            <i className="uil uil-times"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>) : (<>
                {(taskType === 0 || editTaskData.type === 0) ? (<>
                  <div className="row g-3 mb-3">
                    <div className="col-12 col-sm">
                      <p className="mb-0">
                        Digite o <b>Nome{searchPatientPhone ? ', Telefone ' : ''}</b> ou o <b>CPF</b><small>(completo)</small>.

                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip className="text-left">Caso não encontre o paciente em nossa base, você pode adiciona-lo utilizando o botão ao lado.<br /><br />Se preferir, apenas informe o nome, você não precisa vincular um paciente.</Tooltip>}
                        >
                          <i className="uil uil-info-circle opacity-50 ms-2"></i>
                        </OverlayTrigger>
                      </p>
                    </div>
                    <div className="col col-auto">
                      <Button
                        type="button"
                        className="btn-outline-success btn-sm rounded-pill py-0"
                        onClick={() => {
                          setShowAddPatientModal(true);
                          setStopAutoUpdate(true);
                        }}
                      >
                        <i className="uil uil-user-plus me-1"></i>Cadastrar Paciente
                      </Button>
                    </div>
                  </div>
                  <div className="px-3 mx-n3 mb-3 position-relative">
                    <div className="border rounded-3 position-relative">
                      <label htmlFor="search-patient" className="mt-2 ms-2 ps-2 lh-1 position-absolute" style={{ zIndex: 1 }}>
                        {isLoadingPatientSearch ? (
                          <CircularProgress
                            color="inherit"
                            size={12}
                          />
                        ) : (
                          <i className="uil uil-search"></i>
                        )}
                      </label>
                      <Input
                        id="search-patient"
                        type="search"
                        placeholder="Buscar Paciente"
                        register={register("title")}
                        defaultValue={searchPatientPhone ? searchPatientPhone : editTaskData.title}
                        autoComplete="off"
                        // autoFocus
                        className="ps-5 border"
                        error={errors?.title?.message}
                        onChange={(e) => { handleSearchPatient(e.target.value); }}
                        onFocus={() => setIsTitleFocused(true)}
                        onBlur={() => setTimeout(() => {
                          setIsTitleFocused(false);
                        }, 200)}
                      />
                    </div>
                    {isTitleFocused && (<>
                      <div className="position-absolute top-100 start-0 end-0 px-3 z-index-1">
                        <div className="list-group mt-2 overflow-auto mb-3 shadow" style={{ maxHeight: "30vh" }}>
                          {patients && patients.length > 0 ? (<>
                            {patients.map((patient) => (
                              <a role="button" key={patient.id} className="list-group-item list-group-item-action small lh-1"
                                onClick={() => {
                                  setPatients([]);
                                  setPatientData(patient);
                                  setValue("title", patient.name ?? "");
                                  logger.log(patient);
                                }}>
                                <span className="fw-semibold d-block mb-1">{patient.name}</span>
                                <small className="opacity-50">{searchPatientPhone ? (<>({patient.dddMobilePhone}) {patient.mobilePhone} | </>) : (<></>)}{cpfMask(patient.cpf)}</small>
                              </a>
                            ))}
                          </>) : (<>
                            {/* {taskName != "" && !isLoadingPatientSearch && !patientData && (<> */}
                            {watch('title') != "" && !isLoadingPatientSearch && (<>
                              <div className="list-group-item text-center small py-4">
                                <p className="mb-0">Nenhum paciente encontrado para <b>{watch('title')}</b></p>
                              </div>
                            </>)}
                          </>)}
                        </div>
                      </div>
                    </>)}
                  </div>
                </>) : (<>
                  <label htmlFor="taskname" className="small me-auto"><i className="uil uil-star me-2"></i>Título da Task:</label>
                  <Input
                    type="text"
                    placeholder="Título da Task"
                    register={register("title")}
                    defaultValue={editTaskData.title}
                    autoComplete="off"
                    // autoFocus={true}
                    containerClassName="mb-3"
                    error={errors?.title?.message}
                  // onChange={(e) => { setTaskName(e.target.value) }}
                  />
                </>)}
              </>)}

              {editTaskData && columnsData[editTaskData.columnKey] && columnsData[editTaskData.columnKey].type == 3 && (<>
                <div className="row">
                  <OverlayTrigger
                    placement="top-start"
                    overlay={editTaskData.appointment?.scheduleId ? <Tooltip>Não é possível editar pois a data está definito no agendamento.</Tooltip> : (<></>)}
                  >
                    <div className="col-12 col-sm-6">
                      <label htmlFor="appointmentDate" className="small"><i className="uil uil-calendar-alt me-2"></i>Data: <b>{columnsData[editTaskData?.columnKey]?.title}</b></label>
                      <Input
                        type="date"
                        containerClassName="mb-3"
                        disabled={editTaskData.appointment?.scheduleId ? true : false}
                        // autoFocus={true}
                        id="appointmentDate"
                        register={register("date")}
                        defaultValue={editTaskData.appointment?.date ? moment(editTaskData.appointment.date).format("YYYY-MM-DD") : ""}
                        error={errors?.date?.message}
                      />
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top-start"
                    overlay={editTaskData.appointment?.scheduleId ? <Tooltip>Não é possível editar pois a hora está definita no agendamento.</Tooltip> : (<></>)}
                  >
                    <div className="col-12 col-sm-6">
                      <label htmlFor="appointmentTime" className="small"><i className="uil uil-clock me-2"></i>Hora: <b>{columnsData[editTaskData?.columnKey]?.title}</b></label>
                      <Input
                        type="time"
                        disabled={editTaskData.appointment?.scheduleId ? true : false}
                        containerClassName="mb-3"
                        id="appointmentTime"
                        register={register("time")}
                        defaultValue={editTaskData.appointment?.date ? moment(editTaskData.appointment.date).format("HH:mm") : ""}
                        error={errors?.time?.message}
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              </>)}

              <div className="mb-3">
                <div className="row">
                  <div className="col">
                    <label className="small"><i className="uil uil-usd-circle me-2"></i>Valor</label>
                  </div>
                  {isDev() && (
                    <div className="col col-auto">
                      <label className="form-check form-switch mb-0 me-2 ms-n2">
                        <input className="form-check-input" type="checkbox" role="switch" onChange={() => { }} />
                        <small>
                          Marcar como pago.
                        </small>
                      </label>
                    </div>
                  )}
                </div>
                <div className="row align-items-center g-2">
                  <div className="col col-auto opacity-50">
                    R$
                  </div>
                  <div className="col">
                    <Input
                      type="tel"
                      placeholder="0,00"
                      register={register("taskVal")}
                      defaultValue={editTaskData.taskVal ? numberValue(editTaskData.taskVal) : ""}
                      onChange={(e) => {
                        handleTwoDecimalPlacesInput(e, setValue, "taskVal")
                      }}
                      error={errors?.taskVal?.message}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="small"><i className="uil uil-tag-alt me-2"></i>Tags</label>
                </div>
                <div className="col col-auto">
                  <Button type="button" className="btn-outline-indigo btn-sm rounded-pill mt-n2 py-0" onClick={() => {
                    setShowModalTag(true);
                    setStopAutoUpdate(true);
                  }}><i className="uil uil-plus me-1"></i>Nova Tag</Button>
                </div>
              </div>
              <SelectTag
                control={control}
                name="tags"
                // defaultValue={tags.filter(item1 => editTaskData.tags?.some(item2 => item2.id === item1.id)).map((item: any) => ({ value: item.id, label: item.tagName, color: item.color }))}
                isMulti
                className="mb-3"
                options={tags.map((tag) => ({
                  value: tag.id,
                  label: tag.tagName,
                  color: tag.color
                }))}
                error={errors?.tags?.message}
                closeMenuOnSelect={true}
              />

              {editTaskData && editTaskData.id && (<>
                <div className="mb-3">
                  <label className="small"><i className="uil uil-grids me-2"></i>Mover de <i className="uil uil-arrow-right"></i> para:</label>
                  <div className="row align-items-center g-2">
                    <div className="col col-auto">
                      <div className="form-control" style={{ background: columnsData.find((item) => item.id === editTaskData.columnId)?.color ?? "#FFFFFF", color: getContrastingColor(darkMode ? rgbaStringToHsva(columnsData.find((item) => item.id === editTaskData.columnId)?.color ?? "#FFFFFF") : (columnsData.find((item) => item.id === editTaskData.columnId)?.color ?? "#FFFFFF")) }}>
                        <b>{columnsData.find((item) => item.id === editTaskData.columnId)?.title}</b>
                      </div>
                    </div>
                    <div className="col col-auto">
                      <i className="uil uil-arrow-right"></i>
                    </div>
                    <div className="col">
                      <select
                        className="form-select"
                        {...register("newColumnId")}
                        defaultValue={editTaskData.columnId}
                      // onChange={(e) => { setTaskMoveTo(e.target.value) }}
                      >
                        {columnsData.sort((a, b) => (a.order < b.order ? -1 : 1)).map((col) => (
                          <option style={{ 'background': col.color }} key={col.id} className={`text-pure-black ${editTaskData.columnId == col.id ? 'fw-bold' : ''}`} value={col.id}>{col.title}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </>)}

              {editTaskData && editTaskData.id ? (<></>) : (<>
                <div className="mb-3">
                  <label htmlFor="description" className="small me-auto"><i className="uil uil-comment-alt-lines me-2"></i>Observações:</label>
                  <div className="d-flex">
                    <div className="w-100 d-flex">
                      <div className="d-flex flex-column gap-1">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Clínica</Tooltip>}
                        >
                          <label className={`btn px-2 py-1 ${obsSelected === 'clinica' ? 'btn-primary border-primary' : 'btn-outline-primary'} border border-end-0 rounded-end-0`}>
                            <i className="uil uil-heartbeat"></i>
                            <input type="radio" className="d-none" name="obsType" onChange={(e) => { setObsSelected(e.target.value) }} defaultChecked={obsSelected === 'clinica'} value={'clinica'} />
                          </label>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>Atendimento</Tooltip>}
                        >
                          <label className={`btn px-2 py-1 ${obsSelected === 'admin' ? 'btn-success border-success' : 'btn-outline-success'} border border-end-0 rounded-end-0`}>
                            <i className="uil uil-comment-alt-notes"></i>
                            <input type="radio" className="d-none" name="obsType" onChange={(e) => { setObsSelected(e.target.value) }} defaultChecked={obsSelected === 'admin'} value={'admin'} />
                          </label>
                        </OverlayTrigger>
                      </div>
                      <TextareaAutosize
                        placeholder={`Nova Observação Clínica...`}
                        defaultValue={""}
                        {...register("description")}
                        name="description"
                        id="description"
                        minRows={2}
                        className={`form-control rounded-start-0 ${errors?.description?.message ? 'is-invalid' : ''} ${obsSelected === 'clinica' ? '' : 'd-none'}`}
                      // autoFocus={editTaskData && columnsData[editTaskData.columnKey] && columnsData[editTaskData.columnKey].type != 3}
                      ></TextareaAutosize>
                      <TextareaAutosize
                        placeholder={`Nova Observação de Atendimento...`}
                        defaultValue={""}
                        {...register("descriptionAdmin")}
                        name="descriptionAdmin"
                        id="descriptionAdmin"
                        minRows={2}
                        className={`form-control rounded-start-0 ${errors?.descriptionAdmin?.message ? 'is-invalid' : ''} ${obsSelected === 'clinica' ? 'd-none' : ''}`}
                      // autoFocus={editTaskData && columnsData[editTaskData.columnKey] && columnsData[editTaskData.columnKey].type != 3}
                      ></TextareaAutosize>
                    </div>
                  </div>
                  {errors?.description?.message || errors?.descriptionAdmin?.message ? (
                    <div className="text-start text-danger ps-4">
                      <small className="ms-2">{errors?.description?.message ? errors?.description?.message : errors?.descriptionAdmin?.message}</small>
                    </div>
                  ) : (<></>)}
                </div>
              </>)}

              {(taskType === 0 || editTaskData.type === 0) && (<>
                <div className="mb-3">
                  <div className="row">
                    <div className="col">
                      <label className="small"><i className="uil uil-house-user me-2"></i>Origem do Lead</label>
                    </div>
                  </div>
                  <SelectTag
                    control={control}
                    name="taskOrigin"
                    // defaultValue={leadOrigin.filter(item => item.value === editTaskData.taskOrigin)}
                    placeholder="Selecione"
                    className="mb-3"
                    options={leadOrigin}
                    closeMenuOnSelect={true}
                  />
                </div>
                {((watch("taskOrigin")?.[0] ? watch("taskOrigin")?.[0]?.value : watch("taskOrigin")?.value) === OTHERS_LEAD_ORIGIN) ? (
                  <div className="mb-3 mt-n2">
                    <label htmlFor="taskname" className="small me-auto"><i className="uil uil-corner-down-right me-2"></i>Informe a Origem do Lead:</label>
                    <Input
                      type="text"
                      placeholder="Informe a Origem"
                      register={register("othersTaskOrigin")}
                      defaultValue={editTaskData.othersTaskOrigin}
                      autoComplete="off"
                      // autoFocus={true}
                      className="ms-1"
                      containerClassName="mb-3 ps-3"
                      error={errors?.othersTaskOrigin?.message}
                    />
                  </div>
                ) : (<></>)}

                <OverlayTrigger
                  placement="top-start"
                  overlay={editTaskData.appointment && appointmentData.healthInsurance ? <Tooltip>Não é possível editar pois o convênio está definito no agendamento.</Tooltip> : (<></>)}
                >
                  <div className="mb-3">
                    <div className="row">
                      <div className="col">
                        <label className="small"><i className="uil uil-medical-square me-2"></i>Convênio</label>
                        {editTaskData.appointment?.healthInsurance?.name}
                      </div>
                    </div>
                    <SelectTag
                      control={control}
                      isDisabled={editTaskData.appointment && appointmentData.healthInsurance ? true : false}
                      name="healthInsurance"
                      // defaultValue={userData.healthInsurance?.filter(item => item.id.toString() === editTaskData.healthInsurance?.value)}
                      placeholder="Selecione"
                      className="mb-3"
                      options={userData.healthInsurance ? userData.healthInsurance.sort((a: any, b: any) => {
                        if (a.name === "Particular") return -1; // "Particular" em primeiro lugar
                        if (b.name === "Particular") return 1; // "Particular" em primeiro lugar
                        return a.name.localeCompare(b.name);   // Ordem alfabética para os demais
                      }).map((item: any) => { return { value: item.id, label: item.name } }) : []}
                      closeMenuOnSelect={true}
                    />
                  </div>
                </OverlayTrigger>
              </>)}
            </>
          )}
        </Modal.Body>
        {!isLoadingPaciente && !isLoading && (
          <Modal.Footer className={"bg-body position-sticky bottom-0 justify-content-between"}>
            {searchPatientPhone ? (<>
              <div className="alert alert-warning small p-2 col-12 col-lg-auto">
                Ao adicionar, o número <b>{searchPatientPhone}</b> ficará atrelado ao card do paciente.
              </div>
            </>) : (<></>)}
            {(editTaskData.id && (<>
              <Button
                type="button"
                className="btn-outline-danger rounded-pill"
                tabIndex={-1}
                onClick={() => {
                  handleDeleteTask(editTaskData.id);
                }}
              >
                <i className="uil uil-trash-alt me-2"></i>Remover
              </Button>
              {(taskType === 0 || editTaskData.type === 0) && (<>
                <Button
                  type="button"
                  className="btn-outline-dark rounded-pill ms-auto"
                  onClick={() => {
                    setShowForwardPatientModal(true);
                  }}
                >
                  Encaminhar<i className="uil uil-user-arrows ms-2"></i>
                </Button>
              </>)}
            </>))}
            {(patientData && patientData.name) || editTaskData.id ? (<>
              <Button
                type="submit"
                className="btn-primary ms-auto rounded-pill"
              // disabled={(watch("title")?.trim() == "" ? true : false)}
              >
                {editTaskData.id ? 'Salvar' : 'Adicionar Paciente'}
              </Button>
            </>) : (<>
              <Button
                type="submit"
                className="btn-primary ms-auto rounded-pill"
              // disabled={(watch("title")?.trim() == "" ? true : false)}
              >
                Adicionar{(taskType === 0 || editTaskData.type === 0 ? ' Paciente' : ' Task')}
              </Button>
            </>)}
          </Modal.Footer>
        )}
      </form>
    </>
  );
};

export default TaskTab;

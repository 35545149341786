import { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router';
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";
import Footer from "components/Footer";
import { useUser } from "providers/User";
import { useWhatsapp } from "providers/Whatsapp";
import { useBoard } from "providers/Board";
import Swal from "sweetalert2";
import logger from "utils/logger";
import Aside from "components/Aside";
import Scripts from "pages/Chats/components/Scripts";
import Button from "components/Button";
import userImg from "assets/user.png";
import chatImg from "assets/chat.png";
import intagrationImg from "assets/integration.png";
import "moment/locale/pt-br";
import moment from "moment";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import { Chat, Message, GroupedMessages, NewMessage } from "types/whatsapp";
import ModalComponent from 'components/ModalComponent';
import "./utils/chat.css";
import ModalLead from "./components/ModalLead";
import Image from "components/Image";
import ChatHeader from "./components/ChatHeader";
import ChatInput from "./components/ChatInput";
import ChatMessages from "./components/ChatMessages";

interface LocationState {
  id?: string;
}

interface Step {
  id: number;
  content: string;
}

interface Script {
  id: number;
  title: string;
  steps: Step[];
}

const salesScriptsData: Script[] = [
  {
    id: 1,
    title: 'Script de venda por telefone',
    steps: [
      { id: 1, content: 'Passo 1: Introdução. Cumprimente o cliente educadamente.' },
      { id: 2, content: 'Passo 2: Apresentação. Apresente-se e explique o motivo do contato.' },
      { id: 3, content: 'Passo 3: Proposta. Faça a oferta de forma clara e objetiva.' },
    ],
  },
  {
    id: 2,
    title: 'Script de follow-up',
    steps: [
      { id: 1, content: 'Passo 1: Relembrar o cliente sobre a proposta anterior.' },
      { id: 2, content: 'Passo 2: Perguntar se há dúvidas ou algo a esclarecer.' },
    ],
  },
  {
    id: 3,
    title: 'Script de fechamento de venda',
    steps: [
      { id: 1, content: 'Passo 1: Reforçar os benefícios do produto ou serviço.' },
      { id: 2, content: 'Passo 2: Perguntar se o cliente está pronto para fechar a compra.' },
      { id: 3, content: 'Passo 3: Finalizar a venda e agradecer pela confiança.' },
    ],
  },
];

const INTERVAL_CHAT = 500000000;

const Chats = () => {
  useEffect(() => { document.title = 'Conversas | CRM - Conectadoc'; }, []);

  const { token } = useAuth();
  const { userData } = useUser();
  const location = useLocation();
  const state = location.state as LocationState;
  const chatId = state?.id ?? null;

  const {
    showWhatsappModal,
    setShowWhatsappModal,
    isLoadingWhatsapp,
    setIsLoadingWhatsapp,
    conectedWhatsapp,
    setConectedWhatsapp,
    checkInstance,
    inicializeInstance,
    qrCode,
    setQrCode,
    finishInstance,
    getChats,
    chats,
    setChats,
    isLoadingChats,
    getMessages,
    setIsLoadingChats,
    isLoadingMessages,
    setIsLoadingMessages,
    isLoadingChat,
    setIsLoadingChat,
    activeChat,
    setActiveChat,
    sendMessage,
    messages,
    setMessages,
    deleteChat,
    isSendingMessages
  } = useWhatsapp();

  const navigate = useNavigate();

  const [asideLeftOpen, setAsideLeftOpen] = useState(true);
  const [asideRightOpen, setAsideRightOpen] = useState(true);

  const [selectedMessage, setSelectedMessage] = useState<string>("");

  const handleToggleAside = (aside: string) => {
    const asideContent = document.querySelector("aside#" + aside);
    if (asideContent) {
      const button = asideContent.querySelector(".abre-aside");
      if (button) {
        (button as HTMLElement).click();
      }
    }
  }

  const handleDeleteChat = (id: string) => {
    Swal.fire({
      title: `Todas as mensgens serão perdidas, deseja mesmo remover esta conversa?`,
      icon: "question",
      iconColor: 'var(--bs-danger)',
      position: "center",
      showConfirmButton: true,
      confirmButtonText: "Sim, remover",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      reverseButtons: true,
      allowOutsideClick: false,
      focusConfirm: false,
      customClass: {
        confirmButton: "btn btn-outline-danger",
        cancelButton: "btn btn-light",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        deleteChat(id, () => {
          // Código a ser executado se a deleção for bem-sucedida
          if (activeChat && ((activeChat.sentOrReceived ? activeChat.toId : activeChat.fromId) == id)) {
            navigate("/conversas");
          }
        });
      }
    });
  }

  const handleScriptSelection = (scriptContent: string) => {
    setSelectedMessage(scriptContent);
  };
  useEffect(() => {
    setSelectedMessage('');
  }, [chatId]);

  const {
    showLeadModal,
    setShowLeadModal
  } = useBoard();

  const [filteredChats, setFilteredChats] = useState<Chat[]>([]);

  const [filterUnread, setFilterUnread] = useState<string | null>(localStorage.getItem("filterUnread"));

  const isElementInViewport = (el: HTMLElement): boolean => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  const handleFilter = (filter: boolean) => {
    if (filter === true) {
      setFilterUnread(filter.toString());
      localStorage.setItem("filterUnread", filter.toString());
      return;
    }
    setFilterUnread(null);
    localStorage.removeItem("filterUnread");
  }

  const customDate = (data: Date) => {
    const hoje = moment();
    const dataMoment = moment(data);

    if (dataMoment.isSame(hoje, 'day')) {
      // Se for hoje, exibe apenas o horário
      return dataMoment.format('HH:mm');
    } else if (dataMoment.isSame(hoje.clone().subtract(1, 'days'), 'day')) {
      // Se for ontem, exibe "Yesterday"
      return 'Ontem';
    } else if (dataMoment.isSame(hoje, 'week')) {
      // Se for na mesma semana, exibe o dia da semana
      return dataMoment.format('dddd');
    } else {
      // Se passar de uma semana, exibe o formato dd/MM/YYYY
      return dataMoment.format('DD/MM/YYYY');
    }
  }

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    // verifica o whats
    checkInstance();
  }, [token, userData.id]);

  useEffect(() => {
    if (conectedWhatsapp) {
      getChats();
      const intervalChat = setInterval(() => { getChats(false) }, INTERVAL_CHAT);
      return () => {
        clearInterval(intervalChat);
      };
    }
  }, [conectedWhatsapp]);

  useEffect(() => {
    if (document.body.clientWidth <= 991) {
      const left = document.querySelector("aside#left");
      if (left && !left.classList.contains("active")) {
        const button = left.querySelector(".abre-aside");
        if (button) {
          (button as HTMLElement).click();
        }
      }
    }
  }, []);

  useEffect(() => {
    if (filterUnread) {
      const filtered = chats.filter((chat: Chat) => !chat.sentOrReceived && !chat.isRead);
      setFilteredChats(filtered);
    } else {
      setFilteredChats(chats);
    }
    if (chatId) {
      var chatKey = Number(Object.keys(chats).find((x: any) => {
        if (chats[x].sentOrReceived) {
          return chats[x].toId === chatId;
        }
        return chats[x].fromId === chatId;
      }));
      if (chats[chatKey]) {
        const chat = chats[chatKey];
        if (chatId != (activeChat.sentOrReceived ? activeChat.toId : activeChat.fromId)) {
          setActiveChat(chat);
          getMessages(chatId);
        }
      }
    }
  }, [filterUnread, chats, chatId]);

  return (
    <>
      <ContainerAnimation className="content p-0 vh-100">
        <div id="top" className="d-print-none mb-0 mt-0">
          <div className="container">
            <div className="row justify-content-md-between align-items-center gy-2 gx-2">
              <div className="col">
                <h1 className="mb-0 fw-semibold lh-1">Conversas</h1>
              </div>
              {conectedWhatsapp && (
                <div className="col col-auto d-lg-none">
                  <a className="btn btn-primary rounded-pill" onClick={() => { handleToggleAside('left'); }}><i className="uil uil-comments-alt me-2"></i>Conversas</a>
                </div>
              )}
              {chatId && (
                <div className="col col-auto d-lg-none">
                  <a className="btn btn-primary rounded-pill" onClick={() => { handleToggleAside('right'); }}><i className="uil uil-code-branch me-2"></i>Scripts</a>
                </div>
              )}
            </div>
          </div>
        </div >
        <div id="inner-content" className="h-100">
          {conectedWhatsapp && (
            <Aside id="left" isOpen={asideLeftOpen} onToggleAside={setAsideLeftOpen}>
              <div className="d-flex flex-column h-100 position-relative">
                <div className="d-block bg-light sticky-top border-bottom p-3 d-flex gap-1 align-items-center">
                  <h6 className="mb-0 w-100">Caixa de Entrada</h6>
                  {!isLoadingChats && chats.length > 0 && (
                    <div className="d-flex gap-1">
                      <a onClick={() => { handleFilter(false); }} className={`btn btn-sm ${filterUnread ? "btn-outline-secondary" : "btn-secondary"} rounded-pill text-nowrap lh-1`} role="button"><small>Todas</small></a>
                      <a onClick={() => { handleFilter(true); }} className={`btn btn-sm ${filterUnread ? "btn-secondary" : "btn-outline-secondary"} rounded-pill text-nowrap lh-1`} role="button"><small>Não lidas</small></a>
                    </div>
                  )}
                </div>
                {isLoadingWhatsapp || isLoadingChats ? (
                  <>
                    <div className="list-group list-group-flush border-bottom">
                      <a className={`list-group-item list-group-item-action`}>
                        <div className="row g-0">
                          <div className="col col-auto position-relative">
                            <span className={`bg-light px-1 rounded-circle position-absolute bottom-0 end-0 me-2`}><i className={`uil uil-whatsapp opacity-0`}></i></span>
                            <img src={userImg} className="avatar img-fluid rounded-circle me-3" />
                          </div>
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <div className="name text-truncate">
                                  <p className="mb-2 placeholder-wave lh-1"></p>
                                </div>
                              </div>
                              <div className="col col-auto">
                                <div className="text-10 opacity-50">
                                  <p className="mb-0 placeholder-wave"></p>
                                </div>
                              </div>
                            </div>
                            <div className="small text-truncate last-message pe-4">
                              <p className="mb-0 placeholder-wave lh-1"></p>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a className={`list-group-item list-group-item-action`}>
                        <div className="row g-0">
                          <div className="col col-auto position-relative">
                            <span className={`bg-light px-1 rounded-circle position-absolute bottom-0 end-0 me-2`}><i className={`uil uil-whatsapp opacity-0`}></i></span>
                            <img src={userImg} className="avatar img-fluid rounded-circle me-3" />
                          </div>
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <div className="name text-truncate">
                                  <p className="mb-2 placeholder-wave lh-1"></p>
                                </div>
                              </div>
                              <div className="col col-auto">
                                <div className="text-10 opacity-50">
                                  <p className="mb-0 placeholder-wave"></p>
                                </div>
                              </div>
                            </div>
                            <div className="small text-truncate last-message pe-4">
                              <p className="mb-0 placeholder-wave lh-1"></p>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a className={`list-group-item list-group-item-action`}>
                        <div className="row g-0">
                          <div className="col col-auto position-relative">
                            <span className={`bg-light px-1 rounded-circle position-absolute bottom-0 end-0 me-2`}><i className={`uil uil-whatsapp opacity-0`}></i></span>
                            <img src={userImg} className="avatar img-fluid rounded-circle me-3" />
                          </div>
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <div className="name text-truncate">
                                  <p className="mb-2 placeholder-wave lh-1"></p>
                                </div>
                              </div>
                              <div className="col col-auto">
                                <div className="text-10 opacity-50">
                                  <p className="mb-0 placeholder-wave"></p>
                                </div>
                              </div>
                            </div>
                            <div className="small text-truncate last-message pe-4">
                              <p className="mb-0 placeholder-wave lh-1"></p>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a className={`list-group-item list-group-item-action`}>
                        <div className="row g-0">
                          <div className="col col-auto position-relative">
                            <span className={`bg-light px-1 rounded-circle position-absolute bottom-0 end-0 me-2`}><i className={`uil uil-whatsapp opacity-0`}></i></span>
                            <img src={userImg} className="avatar img-fluid rounded-circle me-3" />
                          </div>
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <div className="name text-truncate">
                                  <p className="mb-2 placeholder-wave lh-1"></p>
                                </div>
                              </div>
                              <div className="col col-auto">
                                <div className="text-10 opacity-50">
                                  <p className="mb-0 placeholder-wave"></p>
                                </div>
                              </div>
                            </div>
                            <div className="small text-truncate last-message pe-4">
                              <p className="mb-0 placeholder-wave lh-1"></p>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a className={`list-group-item list-group-item-action opacity-75`}>
                        <div className="row g-0">
                          <div className="col col-auto position-relative">
                            <span className={`bg-light px-1 rounded-circle position-absolute bottom-0 end-0 me-2`}><i className={`uil uil-whatsapp opacity-0`}></i></span>
                            <img src={userImg} className="avatar img-fluid rounded-circle me-3" />
                          </div>
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <div className="name text-truncate">
                                  <p className="mb-2 placeholder-wave lh-1"></p>
                                </div>
                              </div>
                              <div className="col col-auto">
                                <div className="text-10 opacity-50">
                                  <p className="mb-0 placeholder-wave"></p>
                                </div>
                              </div>
                            </div>
                            <div className="small text-truncate last-message pe-4">
                              <p className="mb-0 placeholder-wave lh-1"></p>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a className={`list-group-item list-group-item-action opacity-50`}>
                        <div className="row g-0">
                          <div className="col col-auto position-relative">
                            <span className={`bg-light px-1 rounded-circle position-absolute bottom-0 end-0 me-2`}><i className={`uil uil-whatsapp opacity-0`}></i></span>
                            <img src={userImg} className="avatar img-fluid rounded-circle me-3" />
                          </div>
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <div className="name text-truncate">
                                  <p className="mb-2 placeholder-wave lh-1"></p>
                                </div>
                              </div>
                              <div className="col col-auto">
                                <div className="text-10 opacity-50">
                                  <p className="mb-0 placeholder-wave"></p>
                                </div>
                              </div>
                            </div>
                            <div className="small text-truncate last-message pe-4">
                              <p className="mb-0 placeholder-wave lh-1"></p>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a className={`list-group-item list-group-item-action opacity-25`}>
                        <div className="row g-0">
                          <div className="col col-auto position-relative">
                            <span className={`bg-light px-1 rounded-circle position-absolute bottom-0 end-0 me-2`}><i className={`uil uil-whatsapp opacity-0`}></i></span>
                            <img src={userImg} className="avatar img-fluid rounded-circle me-3" />
                          </div>
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                <div className="name text-truncate">
                                  <p className="mb-2 placeholder-wave lh-1"></p>
                                </div>
                              </div>
                              <div className="col col-auto">
                                <div className="text-10 opacity-50">
                                  <p className="mb-0 placeholder-wave"></p>
                                </div>
                              </div>
                            </div>
                            <div className="small text-truncate last-message pe-4">
                              <p className="mb-0 placeholder-wave lh-1"></p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="list-group list-group-flush border-bottom">
                      {filteredChats.map((chat, index) => (
                        <li key={index} style={{ zIndex: "initial!important" }} className={`list-group-item list-group-item-action ${(chat.sentOrReceived && chatId == chat.toId || !chat.sentOrReceived && chatId == chat.fromId) ? 'bg-primary active' : (!chat.sentOrReceived && !chat.isRead ? 'unread' : '')}`}>
                          <div className="position-relative content-hover">
                            <Dropdown className="position-absolute end-0 top-50 z-3 show-hover me-n2">
                              <Dropdown.Toggle variant="light" className="no-arrow rounded-circle lh-1 p-1">
                                <i className="uil uil-ellipsis-v"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu align="start" className="p-0" style={{ maxHeight: "initial" }}>
                                <Dropdown.Item role="button" className="text-danger" as="a" onClick={() => { handleDeleteChat(chat.sentOrReceived ? chat.toId : chat.fromId); }} >
                                  <i className="uil uil-trash me-2"></i>Remover
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <a className="stretched-link" role="button"
                              onClick={() => {
                                navigate(`/conversas/${chat.sentOrReceived ? chat.toId : chat.fromId}`, { state: { id: (chat.sentOrReceived ? chat.toId : chat.fromId) } });
                                setAsideLeftOpen(false);
                                setMessages([]);
                                setIsLoadingMessages(true);
                              }}></a>
                            {!chat.sentOrReceived && !chat.isRead && (
                              <div className="bg-primary rounded-circle p-1 position-absolute end-0 top-50"></div>
                            )}
                            <div className="row g-0">
                              <div className="col col-auto position-relative">
                                <span className={`badge bg-whatsapp px-1 rounded-circle position-absolute bottom-0 end-0 me-2`}><i className={`uil uil-whatsapp`}></i></span>
                                {/* <span className={`badge bg-${chat.origin == "WA" ? 'whatsapp' : 'instagram'} px-1 rounded-circle position-absolute bottom-0 end-0 me-2`}><i className={`uil uil-${chat.origin == "WA" ? 'whatsapp' : 'instagram'}`}></i></span> */}
                                <Image src={chat.sentOrReceived ? (chat.senderImageURL ?? userImg) : (chat.senderImageURL ?? userImg)} alt={chat.sentOrReceived ? (chat.toName && chat.toName != "" ? chat.toName : chat.toId) : (chat.fromName && chat.fromName != "" ? chat.fromName : chat.fromId)} className="avatar img-fluid rounded-circle me-3" />
                              </div>
                              <div className="col">
                                <div className="row">
                                  <div className="col">
                                    <div className="name text-truncate">{chat.sentOrReceived ? (chat.toName && chat.toName != "" ? chat.toName : chat.toId) : (chat.fromName && chat.fromName != "" ? chat.fromName : chat.fromId)}</div>
                                  </div>
                                  <div className="col col-auto">
                                    <div className="text-10 opacity-50">{customDate(chat.date)}</div>
                                  </div>
                                </div>
                                <div className="small text-truncate last-message pe-4">
                                  {chat.sentOrReceived && (
                                    <>
                                      <i className={`uil uil-check ${chat.isRead ? "text-success" : "opacity-50"}`}></i>
                                      <i className={`uil uil-check ${chat.isRead ? "text-success" : "opacity-50"} me-1`} style={{ marginLeft: -9 }}></i>
                                    </>
                                  )}
                                  {chat.message ? chat.message : "(imagem ou vídeo)"}
                                </div>
                              </div>
                            </div>
                          </div>

                        </li>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </Aside>
          )}
          <div id="center" className="h-100">
            <div className="container d-flex flex-column h-100 pt-4">
              <div className="card box h-100">
                {isLoadingWhatsapp || isLoadingChats ? (
                  <>
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <CircularProgress size={40} />
                    </div>
                  </>
                ) : (
                  <>
                    {conectedWhatsapp ? (
                      <>
                        {!chatId ? (
                          <div className="d-flex flex-column justify-content-center rounded py-5">
                            {chats.length <= 0 ? (
                              <>
                                <h4 className="text-center">
                                  Você ainda não recebeu nenhuma mensagem.
                                </h4>
                                <p className="text-center pt-3">Se você já conectou o WhatsApp, aguarde alguns instantes.</p>
                                <p className="text-center pb-5 small opacity-75">Só é possível exibir mensagens que foram recebidas enquanto o seu WhatsApp estava conectado.</p>
                              </>
                            ) : (
                              <>
                                <h4 className="text-center">
                                  Nenhuma conversa selecionada!
                                </h4>
                                <p className="text-center pt-3 pb-5">
                                  <i className="uil uil-arrow-left me-2"></i>Selecione uma conversa ao lado!
                                </p>
                              </>
                            )}
                            <div className="text-center mb-4">
                              <Image src={chatImg} style={{ maxHeight: '30vh', maxWidth: "100%" }} />
                            </div>
                          </div>
                        ) : (
                          <>
                            {/* Navbar */}
                            <ChatHeader handleDeleteChat={handleDeleteChat} />
                            {/* Messages */}
                            <div className="flex-grow-1 bg-light position-relative">
                              <ChatMessages chatMessages={messages} className="h-100 position-absolute top-0 bottom-0 start-0 end-0 overflow-y-auto" />
                            </div>
                            {/* Input */}
                            <div className="rounded-bottom align-items-center flex-row d-flex border-top">
                              <ChatInput selectedMessage={selectedMessage} chatId={chatId} />
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <div className="d-flex flex-column justify-content-center rounded py-5 px-3 px-lg-5">
                        <div className="row justify-content-center">
                          <div className="col-12 col-sm-11 col-md-10 col-lg-8 col-xl-7 col-xxl-6 text-center">
                            <h4>
                              Você precisa conectar o WhastApp para conseguir receber e enviar mensagens.
                            </h4>
                            <p className="pt-3">
                              Acesse o menu Integrações para conectar o WhatsApp!
                            </p>
                            <p className="pt-3 pb-5"><a role="button" className="btn btn-primary rounded-pill" onClick={() => { navigate('/integracoes') }}><i className="uil uil-server-connection me-2"></i>Integrações</a></p>
                          </div>
                        </div>
                        <div className="text-center mb-4">
                          <img src={intagrationImg} style={{ maxHeight: '40vh', maxWidth: "100%" }} />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <Footer />
          </div>
          {chatId && (
            <Aside id="right" isOpen={asideRightOpen} onToggleAside={setAsideRightOpen}>
              <Scripts chatId={chatId} onScriptSelect={handleScriptSelection} />
            </Aside>
          )}
        </div>
      </ContainerAnimation>


      {/* modal board */}
      < ModalComponent
        title={"Transformar em Lead"}
        show={showLeadModal}
        onHide={() => {
          setShowLeadModal(false);
        }}
      >
        <ModalLead
          chat={activeChat}
          onHide={() => {
            setShowLeadModal(false);
          }} />
      </ModalComponent>
    </>
  );
};

export default Chats;

import { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router';
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

import ModalComponent from 'components/ModalComponent';
import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";
import Footer from "components/Footer";
import Button from "components/Button";
import { useUser } from "providers/User";
import { useBoard } from "providers/Board";
import { useDarkMode } from "providers/DarkMode";
import { Board, BoardDefault, Id, Column, ColumnDefault, Task, TaskDefault, Tag, TagData } from "types/board";
import { hsvaToHex, hexToRgba, rgbaToRgb, getContrastingColor } from '@uiw/color-convert';
import Swal from "sweetalert2";
import logger from "utils/logger";
import Aside from "components/Aside";
import Scripts from "pages/Chats/components/Scripts";
import user from "assets/user.png";
import chatImg from "assets/chat.png";
import moment from "moment";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import Input from "components/Input";
import FlowBuilder from "../FlowBuilder";
import ModalTag from "pages/Boards/components/ModalTag";
import tagsImg from "assets/tags.png";

const TagsPage = () => {
  useEffect(() => { document.title = 'Tags | CRM - Conectadoc'; }, []);

  const { token } = useAuth();
  const { userData } = useUser();
  const navigate = useNavigate();
  const { darkMode } = useDarkMode();

  const {
    isLoadingBoards,
    tags,
    getTags,
    deleteTag,
    createTag,
    setShowModalTag,
    showModalTag
  } = useBoard();

  const [searchTag, setSearchTag] = useState('');
  const [filtered, setFiltered] = useState<Tag[]>(tags);

  const [selectedNavTab, setSelectedNavTab] = useState("tags");

  const handleSearch = (q: string) => {
    setSearchTag(q);
    const term = q.toLowerCase().trim();

    // Filter based on the current nav tab
    const isActive = selectedNavTab !== 'lixeira';

    // Base filtered list based on active/inactive status
    let baseFilteredTags = tags.filter(item => item.isActive === isActive);

    // If search term is empty, return the base filtered list
    if (term === '') {
      setFiltered(baseFilteredTags);
      return;
    }

    // If search term is not empty, apply search filter
    const terms = term.split(' ').filter(t => t);
    const searchFilteredTags = baseFilteredTags.filter(tag =>
      terms.every(t => tag.tagName.toLowerCase().includes(t))
    );

    setFiltered(searchFilteredTags);
  };

  const handleTabClick = (id: string) => {
    setSelectedNavTab(id);
    handleSearch(searchTag);
  };

  const handleDeleteTag = (id: Id) => {
    Swal.fire({
      title: "Tem certeza que deseja remover esta tag?",
      html: "Ela ficará na <b>lixeira</b>, e deixará de aparecer em todos os cards.",
      icon: "question",
      iconColor: 'var(--bs-danger)',
      position: "center",
      showConfirmButton: true,
      confirmButtonText: "Sim, remover",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      reverseButtons: true,
      allowOutsideClick: false,
      focusConfirm: false,
      customClass: {
        confirmButton: "btn btn-outline-danger",
        cancelButton: "btn btn-light",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        deleteTag(id);
      }
    });
  };

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    getTags("", "all");
  }, [token, userData.id]);


  useEffect(() => {
    if (userData && userData.id) {
      handleSearch(searchTag);
    }
  }, [tags, selectedNavTab]);

  return (
    <>
      <ContainerAnimation className="content">

        <div id="top">
          <div className="container">
            <div className="row justify-content-md-between align-items-center gy-2 gx-2">
              <div className="col order-0">
                <h1 className="mb-0 fw-semibold lh-1 d-inline-block me-2">Tags</h1>
                {!isLoadingBoards && (<span className="badge bg-secondary">{tags.length}</span>)}
              </div>
              <div className="col-12 col-md-auto order-5 order-md-1">
                <div className="border rounded-3 position-relative">
                  <span className="mt-2 ms-2 ps-2 lh-1 position-absolute" style={{ zIndex: 1 }}><i className="uil uil-search"></i></span>
                  <Input
                    type="search"
                    placeholder="Buscar Item"
                    onChange={(e) => { handleSearch(e.target.value); }}
                    className="ps-5 border"
                    defaultValue={searchTag}
                  />
                </div>
              </div>
              <div className="col col-auto">
                <Button type="button" className="btn-outline-indigo rounded-pill" onClick={() => {
                  setShowModalTag(true);
                }}><i className="uil uil-plus me-1"></i>Cadastrar Tag</Button>
              </div>
              {/* <div className="col col-auto order-2">
                <Dropdown>
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip>Configurações do Board</Tooltip>}
                  >
                    <Dropdown.Toggle variant="outline-dark" className="no-arrow rounded-circle">
                      <i className="uil uil-cog"></i>
                    </Dropdown.Toggle>
                  </OverlayTrigger>
                  <Dropdown.Menu align='end'>
                    <Dropdown.Item as="button" onClick={() => { }}>
                      <i className="uil uil-edit-alt me-2"></i>Editar Board
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => { }}>
                      <i className="uil uil-qrcode-scan me-2"></i>Ver Qr-Code
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => { }}>
                      <i className="uil uil-file-export me-2"></i>Exportar Board
                    </Dropdown.Item>
                    <Dropdown.Item as="button" className='text-danger' onClick={() => { }}>
                      <i className="uil uil-trash me-2"></i>Excluir Board
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}
            </div>
            <ul className="nav nav-tabs pt-3">
              <li className="nav-item">
                <button
                  type="button"
                  className={`nav-link link-primary ${(selectedNavTab == "tags") ? "active" : ""}`}
                  onClick={() => { handleTabClick("tags") }}
                >
                  Ativas
                </button>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  className={`nav-link link-primary ${(selectedNavTab == "lixeira") ? "active" : ""}`}
                  onClick={() => { handleTabClick("lixeira") }}
                >
                  <span className="text-danger"><i className="uil uil-trash me-2 small"></i>Lixeira</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="container">
          {filtered.length != tags.length && (
            <>
              {filtered.length > 0 ? (
                <p className="text-center">Exibindo <b>{filtered.length}</b> de <b>{tags.length}</b> tag{tags.length != 1 ? 's' : ''}{searchTag != "" ? (<> na busca por <b>{searchTag}</b></>) : (<>.</>)}</p>
              ) : (
                <>
                  <p className="text-center">Nenhuma tag encontrada{searchTag != "" ? (<> na busca por <b>{searchTag}</b></>) : (<>.</>)}</p>
                  <div className="text-center mb-4">
                    <img src={tagsImg} style={{ maxHeight: '50vh', maxWidth: "100%" }} />
                  </div>
                </>
              )}
            </>
          )}
          <div className="row g-1">
            {tags.length > 0 ? (<>
              {filtered.map((tag, index) => (
                <div key={index} className="card card-hover mb-2">
                  <div className="card-body py-1 px-3">
                    <div className="row gx-3 content-hover align-items-center">
                      <div className="col pt-2">
                        <span className="badge fw-semibold mb-0" style={{ background: tag.color, color: getContrastingColor(tag.color ?? "#FFF") }}>{tag.tagName}</span>
                      </div>
                      <div className="col small">
                        <span className="text-upper text-10 d-block lh-1 show-hover"><span className="opacity-50">CRIADO EM:</span></span>
                        {moment(tag.createdDate).format("DD/MM/YYYY HH:mm")}
                      </div>

                      <div className="col col-auto small align-self-center">
                        {(!tag.isActive) ? (<>
                          <OverlayTrigger
                            container={document.body}
                            overlay={<Tooltip className="small">Ativar</Tooltip>}
                          >
                            <a role="button" className={`btn btn-sm btn-outline-dark p-2 lh-1 rounded-circle show-hover`} onClick={() => {
                              createTag({
                                color: tag.color ?? "#FFFFFF",
                                title: tag.tagName
                              }, "all");
                            }}>
                              <i className="uil uil-redo"></i>
                            </a>
                          </OverlayTrigger>
                        </>) : (<>
                          <OverlayTrigger
                            container={document.body}
                            overlay={<Tooltip className="small">Remover</Tooltip>}
                          >
                            <a role="button" className={`btn btn-sm btn-outline-danger p-2 lh-1 rounded-circle show-hover`} onClick={() => { handleDeleteTag(tag.id); }}>
                              <i className="uil uil-trash"></i>
                            </a>
                          </OverlayTrigger>
                        </>)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>) : (<>
              <div>
                <h4 className="text-center">
                  Você não possui nenhuma tag cadastrada.
                </h4>
                <div className="text-center mb-4">
                  <img src={tagsImg} style={{ maxHeight: '50vh', maxWidth: "100%" }} />
                </div>
              </div>
            </>)}
          </div>
        </div>
      </ContainerAnimation>

      {/* modal tag */}
      < ModalComponent
        title={"Tag"}
        show={showModalTag}
        size="sm"
        onHide={() => {
          setShowModalTag(false);
        }}
      >
        <ModalTag onHide={() => {
          setShowModalTag(false);
        }} />
      </ModalComponent>
    </>
  );
};

export default TagsPage;

import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import { pwdRecoveryStep1 } from "pages/Login/utils/pwdRecoveryPopUp";
import { useAuth } from "providers/Auth";
import { UserLoginData } from "types/auth";
import { cpfAndCnpjMask } from "utils/formMasks";
import { validateCpfAndCnpj } from "utils/formValidations";
import imgLogo from "assets/logo-conectadoc.svg";

const LoginForm = () => {
  const navigate = useNavigate();
  const { isLoading, login } = useAuth();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("*Campo obrigatório")
      .when("isEmail", {
        is: true,
        then: yup.string().email("*Formato incorreto"),
        otherwise: yup
          .string()
          .test("cpfValidator", "*CPF/CNPJ inválido", validateCpfAndCnpj)
          .transform((value) => value.replace(/\D/g, "")), // Pode ser cpf ou cnpj
      }),
    password: yup.string().required("*Campo obrigatório"),
    isEmail: yup.boolean(),
    keepConnected: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UserLoginData>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: UserLoginData) => {
    login(data, navigate);
  };

  const handleUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue.match("@")) {
      setValue("isEmail", true);
      setValue("email", inputValue);
    }
    if (!inputValue.match(/([a-z])/gi)) {
      setValue("isEmail", false);
      setValue("email", cpfAndCnpjMask(inputValue));
    }
  };

  const openPwdRecoveryPopUp = () => {
    // Step 1 - Identification
    pwdRecoveryStep1();
  };

  return (
    <div className="bg-form-login">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form-login aos-init"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-once
      >
        <div className="mb-3">
          <h1 className="mb-5 text-center"><img src={imgLogo} className="img-fluid" alt="CRM" width={150} /></h1>
          <h2 className="fw-semibold">Login</h2>
          <p className="text-muted">Informe seu usuário e senha para entrar!</p>
        </div>
        <Input
          containerClassName="form-floating mb-3"
          placeholder="E-mail, CPF ou CNPJ"
          label="E-mail, CPF ou CNPJ"
          register={register("email")}
          error={errors?.email?.message}
          onChange={handleUserInput}
          type="text"
          autoFocus
        />
        <Input
          containerClassName="form-floating mb-3"
          placeholder="Senha"
          label="Senha"
          isPasswordInput
          register={register("password")}
          error={errors?.password?.message}
        />

        <div className="d-grid gap-2">
          <Button type="submit" className="btn-lg btn-primary rounded-pill" disabled={isLoading}>
            Entrar
            {!!isLoading && (
              <CircularProgress
                color="inherit"
                size={12}
                className="ms-2"
              />
            )}
          </Button>
        </div>
        <div className="py-4 row justify-content-between">
          <div className="col col-auto">
            <a
              role="button"
              onClick={() => { navigate('/') }}
              className={(isLoading ? 'disabled' : '') + "text-decoration-hover"}
            >
              <i className="uil uil-arrow-left"></i>Voltar à página inicial
            </a>
          </div>
          <div className="col col-auto">
            <a
              role="button"
              onClick={openPwdRecoveryPopUp}
              className={(isLoading ? 'disabled' : '') + "text-decoration-hover"}
            >
              Esqueci a senha
            </a>

          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
import { useState, useEffect, useRef } from "react";
import _ from "lodash";

import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { api } from "services";
import { toast } from "utils/toast";
import logger from "utils/logger";
import Swal from "sweetalert2";
import ContainerAnimation from "components/ContainerAnimation";
import Input from "components/Input";
import scriptsImg from "assets/checklist.png";

import { DndContext, PointerSensor, useSensor, useSensors, DragOverlay, DragStartEvent, DragOverEvent, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import ModalGroup from "./components/ModalGroup";
import SortableGroup from "./components/sortableGroup";

export interface Message {
  order: number;
  id?: string;
  message: string;
  isActive: boolean;
  createdDate: Date;
  updatedDate: Date;
  isExecuted: boolean;
  executionDate?: Date;
}
export interface Group {
  id?: string;
  order: number;
  description: string;
  messages: Message[];
  isActive: boolean;
  createdDate?: Date;
  updatedDate?: Date;
}

export interface Script extends Group {
  subGroups?: Group[];
}


const importGroup = async (body: Script) => {
  try {
    logger.log("saveGroup");
    const response = await api.post(`/Crm/Scripts`, body);
    return response.data;

  } catch (error: any) {
    console.error(error);
    return error.response.data;
  }
}

export const orderScript = (script: Script[]) => {
  const newScript = _.cloneDeep(script);
  const filterInactive = (items: any[]) => {
    return items
      .filter(item => item.isActive)
      .map(item => {
        if (item.subGroups && item.subGroups.length > 0) {
          item.subGroups = filterInactive(item.subGroups);
          item.subGroups.sort((a: Group, b: Group) => a.order - b.order);
          item.subGroups.forEach((subGroup: Group) => {
            logger.log(`SubGroup Order: ${subGroup.order}, Description: ${subGroup.description}`);
            if (subGroup.messages && subGroup.messages.length > 0) {
              subGroup.messages = filterInactive(subGroup.messages);
              subGroup.messages.sort((a: Message, b: Message) => a.order - b.order);
              subGroup.messages.forEach((message: Message) => {
                logger.log(`Message Order: ${message.order}, Message: ${message.message}`);
              });
            }
          });
        }
        return item;
      });
  };

  const filteredScript = filterInactive(newScript);
  filteredScript.sort((a, b) => a.order - b.order);
  return filteredScript;
};

const Scripts: React.FC = () => {

  useEffect(() => { document.title = 'Scripts | CRM - Conectadoc'; }, []);

  const { token } = useAuth();
  const { userData } = useUser();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [scriptsData, setScriptsData] = useState([] as Script[]);
  const [initialScriptsData, setInitialScriptsData] = useState([] as Script[]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filtered, setFiltered] = useState<Script[]>(scriptsData);
  const [group, setGroup] = useState({} as Script);
  const [type, setType] = useState<"group" | "message" | "subgroup">('group');
  const [subgroupId, setSubgroupId] = useState('');
  const [messageId, setMessageId] = useState('');


  const handleSearch = (q: string) => {
    setSearchTerm(q);
    const term = q.toLowerCase().trim();
    if (term === '') {
      setFiltered(scriptsData);
    } else {
      const terms = term.split(' ').filter(t => t);
      let newScripts = scriptsData.filter(script => {
        return terms.every(t => script.description.toLowerCase().includes(t))
      });
      setFiltered(newScripts);
    }
  }

  const getScripts = async () => {
    setIsLoading(true);
    try {
      logger.log("getScripts");
      const response = await api.get(`/Crm/Scripts`);
      if (response.data) {
        logger.log(response.data)
        let newScripts = orderScript(response.data);
        setScriptsData(newScripts);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    if (scriptsData.length <= 0) {
      getScripts();
    }
  }, [token, userData.id]);

  useEffect(() => {
    if (scriptsData.length > 0) {
      handleSearch(searchTerm);
    }
  }, [token, userData, scriptsData]);

  const [showModalGroup, setShowModalGroup] = useState(false);

  const handleShowModal = (newType: "group" | "subgroup" | "message", group = {} as Script, subgroupId = '', messageId = '') => {
    setType(newType);
    setGroup(group);
    setShowModalGroup(true);
    setSubgroupId(subgroupId);
    setMessageId(messageId);
  };

  const handleRemoveItem = (newType: "group" | "subgroup" | "message", group = {} as Script, subgroupId = '', messageId = '') => {
    const typeText = (newType === "group" ? "Grupo" : (newType === "subgroup" ? "Subgrupo" : "Mensagem"));
    Swal.fire({
      title: `Tem certeza que deseja remover ${newType === "message" ? "esta" : "este"} ${typeText}?`,
      icon: "question",
      iconColor: 'var(--bs-danger)',
      position: "center",
      showConfirmButton: true,
      confirmButtonText: "Sim, excluir",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      reverseButtons: true,
      allowOutsideClick: false,
      focusConfirm: false,
      customClass: {
        confirmButton: "btn btn-outline-danger",
        cancelButton: "btn btn-light",
      },
    }).then(async (res) => {
      if (res.isConfirmed) {
        var body = null;
        if (newType === "group") {
          body = {
            ...group,
            isActive: false
          };
        } else if (newType === "subgroup") {
          if (subgroupId !== '') {
            body = {
              ...group, // Copia o objeto original
              subGroups: group.subGroups?.filter((sg) => sg.id !== subgroupId) || [],
            }
          }
        } else {
          if (messageId) {
            if (subgroupId !== '') {
              body = {
                ...group, // Copia o objeto original
                subGroups: group.subGroups?.map((subGroup) =>
                  subGroup.id === subgroupId
                    ? {
                      ...subGroup, // Atualiza o subgrupo correspondente
                      messages: subGroup.messages?.filter((msg) => msg.id !== messageId) || [],
                    }
                    : subGroup // Mantém os outros subgrupos inalterados
                ),
              }
            } else {
              body = {
                ...group, // Copia o objeto original
                messages: group.messages?.filter((msg) => msg.id !== messageId) || [],
              }
            }
          }
        }

        if (body === null) {
          toast.fire({
            icon: "error",
            title: `Algo deu errado.`,
          });
          return;
        }

        // setIsLoading(true);

        try {
          logger.log("updateGroup");
          var response = await api.put(`/Crm/Scripts/${group.id}`, body);
          if (response.data) {
            toast.fire({
              icon: "success",
              title: `${typeText} removid${newType === "message" ? "a" : "o"} com sucesso!`,
            });
            let tempData: Script[] = [];
            if (newType === "group") {
              tempData = scriptsData.filter((g) => g.id !== group.id) || [];
            } else {
              tempData = scriptsData.map((g) =>
                g.id === group.id
                  ? response.data
                  : g
              );
            }
            setScriptsData(tempData);
          }

        } catch (error: any) {
          console.error(error);
        } finally {
          // setIsLoading(false);
        }
      }
    });
  };

  // drag n drop functions
  const [activeId, setActiveId] = useState<string | null>(null);
  const [activeItem, setActiveItem] = useState<any | null>(null);
  const [activeType, setActiveType] = useState<string | null>(null);
  const [openGroups, setOpenGroups] = useState<string[]>([]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const findItemById = (id: string) => {
    let item = scriptsData.find(group => group.id === id);
    if (item) return { item, type: 'group' };

    for (const group of scriptsData) {
      if (group.subGroups) {
        item = group.subGroups.find(subgroup => subgroup.id === id);
        if (item) return { item, type: 'subgroup' };

        for (const subgroup of group.subGroups) {
          if (subgroup.messages) {
            item = subgroup.messages.find(message => message.id === id);
            if (item) return { item, type: 'message' };
          }
        }
      }

      if (group.messages) {
        item = group.messages.find(message => message.id === id);
        if (item) return { item, type: 'message' };
      }
    }

    return null;
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const result = findItemById(active.id as string);

    if (result) {
      setActiveId(active.id as string);
      setActiveItem(result.item);
      setActiveType(result.type);
      setInitialScriptsData(_.cloneDeep(scriptsData))
    }
  };

  const handleDragOver = (event: DragOverEvent): Script[] | undefined => {
    const { active, over } = event;

    if (!over) {
      logger.log('No valid target for drag over.');
      return;
    }

    const activeItem = findItemById(active.id as string);
    const overItem = findItemById(over.id as string);

    if (!activeItem || !overItem) {
      logger.log('Active item or over item not found.');
      return;
    }

    // Abrir o Accordion do grupo se estiver fechado
    if (overItem.type === 'group' && activeItem.type !== 'group' && !openGroups.includes(over.id as string)) {
      setOpenGroups(prevOpenGroups => [...prevOpenGroups, over.id as string]);
    }

    // Abrir o Accordion do subgrupo se estiver fechado
    if (overItem.type === 'subgroup' && activeItem.type === 'message' && !openGroups.includes(over.id as string)) {
      setOpenGroups(prevOpenGroups => [...prevOpenGroups, over.id as string]);
    }

    // Create appropriate copy of data
    let newData = [...scriptsData];

    // Group to group movement
    if (activeItem.type === 'group' && overItem.type === 'group') {
      const oldIndex = newData.findIndex(g => g.id === active.id);
      const newIndex = newData.findIndex(g => g.id === over.id);
      newData = arrayMove(newData, oldIndex, newIndex);
    }

    // Subgroup movement
    if (activeItem.type === 'subgroup' && (overItem.type === 'subgroup' || overItem.type === 'group')) {
      const sourceGroupIndex = newData.findIndex(g => g.subGroups?.find(sg => sg.id === active.id));
      const targetGroupIndex = overItem.type === 'group'
        ? newData.findIndex(g => g.id === over.id)
        : newData.findIndex(g => g.subGroups?.find(sg => sg.id === over.id));

      if (sourceGroupIndex !== -1 && targetGroupIndex !== -1) {
        const sourceGroup = newData[sourceGroupIndex];
        const targetGroup = newData[targetGroupIndex];

        const oldIndex = sourceGroup.subGroups?.findIndex(sg => sg.id === active.id) ?? -1;
        const newIndex = overItem.type === 'group'
          ? targetGroup.subGroups?.length ?? 0
          : targetGroup.subGroups?.findIndex(sg => sg.id === over.id) ?? -1;

        if (oldIndex !== -1 && newIndex !== -1) {
          const movedSubgroup = sourceGroup.subGroups![oldIndex];
          sourceGroup.subGroups?.splice(oldIndex, 1);
          if (!targetGroup.subGroups) targetGroup.subGroups = [];
          targetGroup.subGroups.splice(newIndex, 0, movedSubgroup);
        }
      }
    }

    // Message movement
    if (activeItem.type === 'message') {
      let sourceGroupIndex = -1;
      let sourceSubgroupId: string | undefined;
      let sourceMessage: Message | undefined;

      // Find source message
      for (let groupIndex = 0; groupIndex < newData.length; groupIndex++) {
        const group = newData[groupIndex];
        const messageInMainGroup = group.messages?.find(m => m.id === active.id);
        if (messageInMainGroup) {
          sourceGroupIndex = groupIndex;
          sourceMessage = messageInMainGroup;
          break;
        }

        const subgroupWithMessage = group.subGroups?.find(sg =>
          sg.messages?.some(m => m.id === active.id)
        );

        if (subgroupWithMessage) {
          sourceGroupIndex = groupIndex;
          sourceSubgroupId = subgroupWithMessage.id;
          sourceMessage = subgroupWithMessage.messages?.find(m => m.id === active.id);
          break;
        }
      }

      if (!sourceMessage || sourceGroupIndex === -1) return newData;

      // Handle different target types
      if (overItem.type === 'message') {
        // Find target message location
        for (let groupIndex = 0; groupIndex < newData.length; groupIndex++) {
          const group = newData[groupIndex];
          // Check main group messages
          const targetMessageIndex = group.messages?.findIndex(m => m.id === over.id) ?? -1;
          if (targetMessageIndex !== -1) {
            // Remove from source
            if (sourceSubgroupId) {
              const sourceSubgroup = newData[sourceGroupIndex].subGroups?.find(sg => sg.id === sourceSubgroupId);
              sourceSubgroup?.messages?.splice(sourceSubgroup.messages.findIndex(m => m.id === active.id), 1);
            } else {
              newData[sourceGroupIndex].messages?.splice(
                newData[sourceGroupIndex].messages?.findIndex(m => m.id === active.id) ?? -1,
                1
              );
            }

            // Add to target group
            if (!group.messages) group.messages = [];
            group.messages.splice(targetMessageIndex, 0, sourceMessage);
            break;
          }

          // Check subgroups
          for (const subgroup of (group.subGroups || [])) {
            const targetSubgroupMessageIndex = subgroup.messages?.findIndex(m => m.id === over.id) ?? -1;
            if (targetSubgroupMessageIndex !== -1) {
              // Remove from source
              if (sourceSubgroupId) {
                const sourceSubgroup = newData[sourceGroupIndex].subGroups?.find(sg => sg.id === sourceSubgroupId);
                sourceSubgroup?.messages?.splice(sourceSubgroup.messages.findIndex(m => m.id === active.id), 1);
              } else {
                newData[sourceGroupIndex].messages?.splice(
                  newData[sourceGroupIndex].messages?.findIndex(m => m.id === active.id) ?? -1,
                  1
                );
              }

              // Add to target subgroup
              if (!subgroup.messages) subgroup.messages = [];
              subgroup.messages.splice(targetSubgroupMessageIndex, 0, sourceMessage);
              break;
            }
          }
        }
      }

      // Handle dropping on group or subgroup
      if (overItem.type === 'group' || overItem.type === 'subgroup') {
        const targetGroupIndex = overItem.type === 'group'
          ? newData.findIndex(g => g.id === over.id)
          : newData.findIndex(g => g.subGroups?.some(sg => sg.id === over.id));

        if (targetGroupIndex !== -1) {
          // Remove from source
          if (sourceSubgroupId) {
            const sourceSubgroup = newData[sourceGroupIndex].subGroups?.find(sg => sg.id === sourceSubgroupId);
            sourceSubgroup?.messages?.splice(sourceSubgroup.messages.findIndex(m => m.id === active.id), 1);
          } else {
            newData[sourceGroupIndex].messages?.splice(
              newData[sourceGroupIndex].messages?.findIndex(m => m.id === active.id) ?? -1,
              1
            );
          }

          if (overItem.type === 'group') {
            // Add to target group
            if (!newData[targetGroupIndex].messages) {
              newData[targetGroupIndex].messages = [];
            }
            newData[targetGroupIndex].messages.push(sourceMessage);
          } else {
            // Add to target subgroup
            const targetSubgroup = newData[targetGroupIndex].subGroups?.find(sg => sg.id === over.id);
            if (targetSubgroup) {
              if (!targetSubgroup.messages) targetSubgroup.messages = [];
              targetSubgroup.messages.push(sourceMessage);
            }
          }
        }
      }
    }

    // Update order parameters for all items
    newData.forEach((group, groupIndex) => {
      group.order = groupIndex;
      group.subGroups?.forEach((subGroup, subGroupIndex) => {
        subGroup.order = subGroupIndex;
        subGroup.messages?.forEach((message, messageIndex) => {
          message.order = messageIndex;
        });
      });
      group.messages?.forEach((message, messageIndex) => {
        message.order = messageIndex;
      });
    });
    setScriptsData(newData);
    console.log(event);
    return newData;
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) {
      setActiveId(null);
      setActiveItem(null);
      setActiveType(null);
      return;
    }

    const newData = handleDragOver(event);
    var updateGroups: Script[] = [];
    if (newData) {

      // seta o scritps data para a array atualizado
      var newDataNoId = _.cloneDeep(newData);

      newDataNoId.forEach((group, groupIndex) => {
        if (!_.isEqual(initialScriptsData[groupIndex], newDataNoId[groupIndex])) {
          // Adicionar grupos alterados à lista
          if (!updateGroups.includes(newDataNoId[groupIndex])) {
            updateGroups.push(newDataNoId[groupIndex]);
          }

          // Verificar e remover IDs conforme as condições
          group.subGroups?.forEach(subGroup => {
            if (!initialScriptsData[groupIndex].subGroups?.some(sg => sg.id === subGroup.id)) {
              // Subgrupo foi adicionado, remover ID do subgrupo e das mensagens
              delete subGroup.id;
              subGroup.messages?.forEach(message => delete message.id);
            }
          });

          group.messages?.forEach(message => {
            if (!initialScriptsData[groupIndex].messages?.some(m => m.id === message.id)) {
              // Mensagem foi adicionada, remover ID da mensagem
              delete message.id;
            }
          });
        }
      });

      console.log("updateGroups", updateGroups);

      // Chamar a API para salvar os grupos modificados
      try {
        const savePromises = updateGroups.map(async group => {
          const response = await api.put(`/Crm/Scripts/${group.id}`, group);
          return response.data;
        });

        const responseGroups = await Promise.all(savePromises);

        responseGroups.forEach((updatedGroup) => {
          const index = newDataNoId.findIndex(g => g.id === updatedGroup.id);
          if (index !== -1) {
            newDataNoId[index] = updatedGroup;
          }
        });

        console.log("responseGroups", responseGroups)
        console.log("newData", newDataNoId)

        // Atualizar o estado com os dados finais
        setScriptsData(newDataNoId);

        toast.fire({
          icon: "success",
          title: `${updateGroups.length === 1 ? 'Grupo atualizado' : 'Grupos atualizados'} com sucesso!`,
        });
      } catch (error: any) {
        // Em caso de erro, reverte para o estado inicial
        toast.fire({
          icon: "error",
          title: `Ocorreu um erro ao atualizar a ordem`,
        });
        console.error(error);
        setScriptsData(initialScriptsData);
      }
    }

    setActiveId(null);
    setActiveItem(null);
    setActiveType(null);
  };

  const transformData = (data: Script[]) => {
    const transformObject = (obj: any) => {
      return {
        description: obj.description,
        messages: obj.messages?.map((msg: Message) => ({
          message: msg.message
        })) || [],
        subGroups: obj.subGroups?.map(transformObject) || []
      };
    };

    return data.map(transformObject);
  };

  const handleExportData = (scriptsData: Script[]) => {
    const newObject = transformData(scriptsData);
    exportData(newObject);
  }

  const exportData = (boardData: any) => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(boardData)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `scripts-de-${userData.name}.json`;
    link.click();
    link.remove();
  };

  // upload de board
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const jsonFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return; // Verifica se há arquivos

    const file = files[0];
    if (!file.name.endsWith(".json")) { // Verifica se é um arquivo .json
      toast.fire({
        icon: "error",
        title: "Por favor, selecione um arquivo <b>.json</b>.",
      });
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");

    fileReader.onload = (e) => {
      const result = e.target?.result; // Verifica se 'result' não é nulo

      if (typeof result === "string") { // Garante que 'result' é uma string
        try {
          const newData = JSON.parse(result); // Tenta fazer o parse do JSON
          console.log("Json Data", newData); // Exibe os dados no console
          if (!newData || newData.length <= 0) {
            toast.fire({
              icon: "error",
              title: "Nenhuma sript encontrado no arquivo enviado.",
            });
          }
          // verifica se tem scripts
          if (scriptsData.length <= 0) {
            // importa os dados
            importData(newData);
          } else {
            Swal.fire({
              title: `Deseja importar ${newData.length} ${newData.length == 1 ? 'grupo' : 'grupos'}?`,
              icon: "question",
              iconColor: 'var(--bs-dark)',
              position: "center",
              showCloseButton: true,
              showConfirmButton: true,
              confirmButtonText: "Sim, importar",
              showCancelButton: false,
              showDenyButton: true,
              denyButtonText: "Cancelar",
              buttonsStyling: false,
              reverseButtons: true,
              allowOutsideClick: true,
              focusConfirm: false,
              customClass: {
                confirmButton: "btn btn-outline-dark",
                denyButton: "btn btn-light",
              },
            }).then((res) => {
              if (res.isConfirmed) {
                importData(newData);
              }
            });
          }
        } catch (error) {
          toast.fire({
            icon: "error",
            title: "Verifique a estrutura do seu arquivo e tente novamente.",
          });
          console.error("Erro ao fazer o parse do arquivo JSON:", error);
        }
      } else {
        toast.fire({
          icon: "error",
          title: "Falha ao ler o arquivo.",
        });
        console.error("Falha ao ler o arquivo como texto.");
      }
      // Limpa o valor do input após o upload
      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = "";
      }
    };

    fileReader.onerror = () => {
      toast.fire({
        icon: "error",
        title: "Erro ao carregar o arquivo.",
      });
      console.error("Erro ao carregar o arquivo.");
      // Limpa o valor do input após o upload
      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = "";
      }
    };

    const importData = async (newData: any) => {
      var qtdSuccess = 0;
      var errors: any = [];
      try {
        var newGroups: Script[] = [];
        await Promise.all(newData.map(async (group: Script) => {
          var order = scriptsData.length;
          var newItem = await importGroup({ ...group, order: order });
          // debugger;
          if (newItem && newItem.id) {
            qtdSuccess += 1;
            newGroups.push(newItem)
          } else if (newItem.detail) {
            errors.push(newItem.detail);
          }
        }));

        setScriptsData([...scriptsData, ...newGroups]);

        if (newData.length === qtdSuccess) {
          toast.fire({
            icon: "success",
            title: "Scripts importados com sucesso.",
          });
        } else if (newData.length === errors.length) {
          toast.fire({
            icon: "error",
            title: `Erro ao importar ${errors.length} ${errors.length == 1 ? 'grupo' : 'grupos'}.`,
            html: errors.map((error: any, index: number) => `<p class="mb-0"><b>${newData[index].description}</b></p><p>${error}</p>`).join('')
          });
        } else {
          toast.fire({
            icon: "warning",
            title: `Apenas ${qtdSuccess} de ${newData.length} ${newData.length == 1 ? 'grupo' : 'grupos'} importado(s).`,
          });
        }
      } catch (error: any) {
        console.error(error);
      }
    }
  };

  const {
    control,
  } = useForm();

  return (
    <>
      <ContainerAnimation className="content">
        <div id="top">
          <div className="container">
            <div className="row justify-content-md-between align-items-center gy-2 gx-2">
              <div className="col order-1">
                <h1 className="mb-0 fw-semibold lh-1 d-inline-block me-2">Scripts</h1>
                <span className="badge bg-secondary">{scriptsData.length}</span>
              </div>
              <div className="col-12 col-md-auto order-5 order-md-1">
                <div className="border rounded-3 position-relative">
                  <span className="mt-2 ms-2 ps-2 lh-1 position-absolute" style={{ zIndex: 1 }}><i className="uil uil-search"></i></span>
                  <Input
                    type="search"
                    placeholder="Buscar Item"
                    onChange={(e) => { handleSearch(e.target.value); }}
                    className="ps-5 border"
                    defaultValue={searchTerm}
                  />
                </div>
              </div>
              <div className="col col-auto order-2">
                <button type="button" onClick={() => { handleShowModal("group", {} as Script); }} className="btn btn-outline-primary rounded-pill"><i className="uil uil-plus me-2"></i>Adicionar Grupo</button>
              </div>

              <div className="col col-auto order-2">
                <Dropdown>
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip>Configurações</Tooltip>}
                  >
                    <Dropdown.Toggle variant="outline-dark" className="no-arrow rounded-circle">
                      <i className="uil uil-cog"></i>
                    </Dropdown.Toggle>
                  </OverlayTrigger>
                  <Dropdown.Menu align='end'>
                    <Dropdown.Item as="button" onClick={() => { handleExportData(scriptsData); }}>
                      <i className="uil uil-file-export me-2"></i>Exportar Scripts
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={handleUploadClick}>
                      <i className="uil uil-file-import me-2"></i>Importar Scripts
                    </Dropdown.Item>
                    <input ref={hiddenFileInput} type="file" accept="application/json" hidden onChange={jsonFileUpload} />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-6">
              <p>Mantenha seu padrão de atendimento cadastrando e organizando seus <b>scripts de vendas/atendimento</b> em grupos, subgrupos e mensagens.</p>
              {searchTerm != "" && (
                <>
                  {filtered.length > 0 ? (
                    <p className="text-center">Exibindo <b>{filtered.length}</b> de <b>{scriptsData.length}</b> fluxo{scriptsData.length != 1 ? 's' : ''} na busca por <b>{searchTerm}</b></p>
                  ) : (
                    <p className="text-center">Nenhum fluxo encontrado na busca por <b>{searchTerm}</b></p>
                  )}
                </>
              )}
              {isLoading ? (
                <div className="placeholders">
                  {[...Array(9)].map((_, i) => (
                    <div key={i} className="card border-0 pb-5 pt-3 mb-2 placeholder-wave"></div>
                  ))}
                </div>
              ) : (
                scriptsData.length > 0 ? (
                  <>
                    <div className="ps-3 ps-md-0">

                      <DndContext
                        sensors={sensors}
                        onDragStart={handleDragStart}
                        onDragOver={handleDragOver}
                        onDragEnd={handleDragEnd}
                      >
                        <SortableContext items={filtered.map(item => item.id)} strategy={verticalListSortingStrategy}>
                          {filtered.map((group) => (
                            <SortableGroup
                              key={group.id}
                              group={group}
                              onShowModal={handleShowModal}
                              onRemoveItem={handleRemoveItem}
                              openGroups={openGroups}
                              setOpenGroups={setOpenGroups}
                            />
                          ))}
                        </SortableContext>

                        <DragOverlay>
                          {activeId && activeItem && (
                            <>
                              {(activeType === 'group' || activeType === 'subgroup') && (
                                <div className="card mb-2 shadow p-3">
                                  <div className="drag-handle position-absolute top-50 translate-middle-y start-0 ms-n3 border rounded bg-white p-1">
                                    <i className="uil uil-draggabledots"></i>
                                  </div>
                                  <span className="fw-semibold lh-1">{activeItem.description}</span>
                                </div>
                              )}
                              {activeType === 'message' && (
                                <div className="card mb-2 shadow p-3">
                                  <div className="drag-handle position-absolute top-50 translate-middle-y start-0 ms-n2 border rounded bg-white p-1">
                                    <i className="uil uil-draggabledots"></i>
                                  </div>
                                  <p className="small lh-base mb-0">{activeItem.message}</p>
                                </div>
                              )}
                            </>
                          )}
                        </DragOverlay>
                      </DndContext>
                      <div className="text-center">
                        <button type="button" onClick={() => { handleShowModal("group", {} as Script); }} className="btn btn-outline-primary rounded-pill"><i className="uil uil-plus me-2"></i>Adicionar Grupo</button>
                      </div>
                    </div>

                  </>
                ) : (
                  <>
                    <div className="py-5 text-center">
                      <h4 className="text-center">
                        Você não possui nenhum script cadastrado.
                      </h4>
                      <div className="text-center pt-3 pb-5 d-print-none">
                        <button type="button" onClick={() => { setShowModalGroup(true) }} className="btn btn-outline-primary rounded-pill"><i className="uil uil-plus me-2"></i>Adicionar Grupo</button>
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
            <div className="d-none d-lg-block col-5 col-xl-6 text-end sticky-top">
              <img src={scriptsImg} style={{ maxHeight: "50vh", maxWidth: "100%" }} />
            </div>
          </div>
        </div>
      </ContainerAnimation>
      <ModalGroup
        show={showModalGroup}
        type={type}
        scriptsData={scriptsData}
        group={group}
        subgroup={subgroupId}
        message={messageId}
        onHide={(newData) => {
          setShowModalGroup(false);
          if (newData) {
            let tempData = [];
            if (scriptsData.find((g) => g.id === group.id)) {
              tempData = scriptsData.map((g) =>
                g.id === group.id
                  ? newData
                  : g
              );
            } else {
              tempData = [
                ...scriptsData,
                newData
              ];
            }
            let newScript = orderScript(tempData)
            setScriptsData(newScript);
          }
        }} />
    </>
  );
};

export default Scripts;
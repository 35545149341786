import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import Swal from "sweetalert2";

import { api } from "services";
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { usePatient } from "providers/Patient";
import { useDarkMode } from "providers/DarkMode";
import { hexToRgba, rgbaToRgb } from '@uiw/color-convert';
import { Board, BoardDefault, Id, Column, ColumnDefault, Task, TaskDefault, Tag, TagData } from "types/board";
import { EventInput } from "@fullcalendar/core";
import { SelectOptions } from 'components/SelectTag';
import { toast } from "utils/toast";
import logger from "utils/logger";
import moment from "moment";
import { Appointment } from "types/patient";

interface BoardProviderProps {
  children: ReactNode;
}

interface BoardProviderData {
  boards: Board[];
  setBoards: React.Dispatch<React.SetStateAction<Board[]>>;
  getBoards: () => Promise<void>;
  boardData: Board;
  setBoardData: React.Dispatch<React.SetStateAction<Board>>;
  tasksData: Task[];
  setTasksData: React.Dispatch<React.SetStateAction<Task[]>>;
  editTaskData: Task;
  setEditTaskData: React.Dispatch<React.SetStateAction<Task>>;
  editColumnData: Column;
  setEditColumnData: React.Dispatch<React.SetStateAction<Column>>;
  columnsData: Column[];
  setColumnsData: React.Dispatch<React.SetStateAction<Column[]>>;
  taskName: string;
  setTaskName: React.Dispatch<React.SetStateAction<string>>;
  taskDescription: string;
  setTaskDescription: React.Dispatch<React.SetStateAction<string>>;
  taskDate: string | null;
  setTaskDate: React.Dispatch<React.SetStateAction<string | null>>;
  taskMoveTo: string;
  setTaskMoveTo: React.Dispatch<React.SetStateAction<string>>;
  taskType: number;
  setTaskType: React.Dispatch<React.SetStateAction<number>>;
  taskId: Id | string;
  setTaskId: React.Dispatch<React.SetStateAction<Id | string>>;
  showAddTaskModal: boolean;
  setShowAddTaskModal: React.Dispatch<React.SetStateAction<boolean>>;

  showModalTag: boolean;
  setShowModalTag: React.Dispatch<React.SetStateAction<boolean>>;

  columnName: string;
  setColumnName: React.Dispatch<React.SetStateAction<string>>;
  columnId: Id | string;
  setColumnId: React.Dispatch<React.SetStateAction<Id | string>>;
  showAddColumnModal: boolean;
  setShowAddColumnModal: React.Dispatch<React.SetStateAction<boolean>>;
  hex: string;
  setHex: React.Dispatch<React.SetStateAction<string>>;

  createColumn: (data: ColumnDefault) => void;
  deleteColumn: (id: Id) => void;
  updateColumn: (id: Id, updateData: Column, callback?: () => void) => void;

  showForwardPatientModal: boolean;
  setShowForwardPatientModal: React.Dispatch<React.SetStateAction<boolean>>;

  createBoard: (data: BoardDefault) => Promise<void>;
  updateBoard: (boardId: Id, data: BoardDefault) => Promise<void>;
  deleteBoard: (id: Id) => Promise<void>;
  getBoardById: (boardId: Id) => Promise<void>;
  getTasksByBoard: (boardId: Id) => Promise<void>;
  getPatientsByBoards: (boards: Board[]) => Promise<void>;
  getCardsByBoards: (boards: Board[], onlyPatients?: boolean) => Promise<void>;
  patientsByBoard: any[];
  setPatientsByBoard: React.Dispatch<React.SetStateAction<any[]>>;
  cardsByBoard: any[];
  setCardsByBoard: React.Dispatch<React.SetStateAction<any[]>>;
  addColumnToBoard: (boardId: Id, data: ColumnDefault, returnData?: boolean) => Promise<Column | void>;
  editColumnFromBoard: (boardId: Id, columnId: Id, data: ColumnDefault, showToast?: boolean, callback?: () => void) => Promise<void>;
  addTaskToBoardColumn: (boardId: Id, columnId: Id, data: TaskDefault, showReturn?: boolean, callback?: () => void) => Promise<void> | Promise<Task>;
  getTasksByColumn: (boardId: Id, columnId: Id) => Promise<void>;
  getTaskById: (boardId: Id, columnId: Id, taskId: Id) => Promise<void>;
  delColumnFromBoard: (boardId: Id, columnId: Id) => Promise<void>;
  delTaskFromBoardColumn: (boardId: Id, columnId: Id, taskId: Id) => Promise<void>;
  editTaskFromBoardColumn: (boardId: Id, columnId: Id, taskId: Id, data: TaskDefault, isObservation?: boolean, callback?: () => void) => Promise<void>;
  moveTaskBetweenBoardColumn: (boardId: Id, columnId: Id, taskId: Id, data: any) => Promise<void>;
  isLoading: boolean;
  isLoadingBoards: boolean;
  setIsLoadingBoards: React.Dispatch<React.SetStateAction<boolean>>;
  obsLoading: boolean;
  setObsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  autoUpdate: boolean;
  setAutoUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  stopAutoUpdate: boolean;
  setStopAutoUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  checkDuplicatedTasks: (tasks: Task[]) => Task[];
  keepDuplicates?: Id[];
  setKeepDuplicates?: ([]: Id[]) => void;
  showDuplicates?: Id[];
  setShowDuplicates?: ([]: Id[]) => void;
  handleClick?: (task: Task) => void;
  handleMouseEnter?: (task: Task) => void;
  handleMouseLeave?: () => void;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  filteredTasks: Task[];
  setFilteredTasks: React.Dispatch<React.SetStateAction<Task[]>>;
  handleSearchChange: (q: string) => void;
  updateTasksOrder: (tasks: Task[]) => void;
  handleDeleteTask: (id: Id) => void;
  handleTaskModal: (open: boolean, task: Task) => void;

  moveTaskBetweenBoards: (boardId: Id, columnId: Id, taskId: Id, data: any, onHide?: () => void) => void;

  columnAddIndex: number;
  setColumnAddIndex: React.Dispatch<React.SetStateAction<number>>;

  clearData: () => void;
  updateTask: (task: Task, callback?: () => void) => void;
  tags: Tag[];
  setTags: React.Dispatch<React.SetStateAction<Tag[]>>;
  getTags: (filter?: string, list?: string) => void;
  createTag: (tag: TagData, list?: string) => void;
  deleteTag: (id: Id) => void;

  filterTags: Tag[];
  setFilterTags: React.Dispatch<React.SetStateAction<Tag[]>>;
  getTagsFromBoard: (boardData: Board) => void;
  handleTagChange: (e: any) => void;

  getTagsFromPatients: (patientsData: any) => void;

  showLeadModal: boolean;
  setShowLeadModal: React.Dispatch<React.SetStateAction<boolean>>;

  leadOrigin: SelectOptions[];
  OTHERS_LEAD_ORIGIN: number;
}

export const BoardContext = createContext<BoardProviderData>(
  {} as BoardProviderData
);

export const BoardProvider = ({ children }: BoardProviderProps) => {

  const { token } = useAuth();
  const { userData } = useUser();
  const { darkMode } = useDarkMode();

  const { patients, patientLoading, setPatients, patientData, setPatientData, searchPatient, getPatientById, setAppointmentData, appointmentData, getAppointmentById } = usePatient();

  const OTHERS_LEAD_ORIGIN = 99;

  const [boards, setBoards] = useState<Board[]>([]);
  const [boardData, setBoardData] = useState<Board>({} as Board);

  const [editTaskData, setEditTaskData] = useState({} as Task);
  const [editColumnData, setEditColumnData] = useState({} as Column);

  const [tasksData, setTasksData] = useState<Task[]>([]);
  const [columnsData, setColumnsData] = useState<Column[]>([]);

  const [tags, setTags] = useState<Tag[]>([]);

  const [showModalTag, setShowModalTag] = useState(false);

  const [columnAddIndex, setColumnAddIndex] = useState(-1);

  const [taskName, setTaskName] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [taskDate, setTaskDate] = useState<string | null>('');
  const [taskMoveTo, setTaskMoveTo] = useState('');
  const [taskType, setTaskType] = useState(0);
  const [taskId, setTaskId] = useState<Id | string>('');
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [showForwardPatientModal, setShowForwardPatientModal] = useState(false);

  const [columnName, setColumnName] = useState('');
  const [columnId, setColumnId] = useState<Id | string>('');
  const [showAddColumnModal, setShowAddColumnModal] = useState(false);
  const [hex, setHex] = useState("#ffffff");

  const [patientsByBoard, setPatientsByBoard] = useState<any[]>([]);
  const [cardsByBoard, setCardsByBoard] = useState<any[]>([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTasks, setFilteredTasks] = useState(tasksData);

  const [filterTags, setFilterTags] = useState<any[]>([]);

  const handleSearchChange = (q: string) => {
    setSearchTerm(q);
    const term = q.toLowerCase().trim();
    var activeTagIDs: any = [];

    filterTags.map((tag) => {
      if (tag.isFixed) {
        activeTagIDs.push(tag.id);
      }
    });

    const filtered = tasksData.filter((task) => {
      var matchesTerms = true;
      var hasActiveTag = true;
      // Verifica se a task possui pelo menos uma tag ativa
      if (filterTags.length > 0 && activeTagIDs.length > 0 && task.tags) {
        hasActiveTag = task.tags.some((tag) => activeTagIDs.includes(tag.id));
      }

      // Verifica se o título da task contém todos os termos fornecidos
      if (term !== '') {
        const terms = term.split(' ').filter(t => t);
        matchesTerms = terms.every((t) => task.title.toLowerCase().includes(t));
      }

      // Retorna true somente se ambas as condições forem atendidas
      return hasActiveTag && matchesTerms;
    });

    setFilteredTasks(filtered);
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [obsLoading, setObsLoading] = useState<boolean>(false);
  const [isLoadingBoards, setIsLoadingBoards] = useState<boolean>(false);

  const [autoUpdate, setAutoUpdate] = useState(true);
  const [stopAutoUpdate, setStopAutoUpdate] = useState(false);

  // check duplicated
  const checkDuplicatedTasks = (tasks: Task[]) => {
    // Mapa para contar as ocorrências de cada patient.id
    const patientIdMap: any = {};

    // Contando as ocorrências de cada patient.id
    tasks.forEach(obj => {
      if (obj.patient && obj.patient.id) {
        const patientId = obj.patient.id;
        if (patientIdMap[patientId]) {
          patientIdMap[patientId].push(obj.id);
        } else {
          patientIdMap[patientId] = [obj.id];
        }
      }
    });

    // Identificando objetos com patient.id repetido e adicionando uma nova chave
    tasks.forEach((obj, index) => {
      if (obj.patient && obj.patient.id && patientIdMap[obj.patient.id].length > 1) {
        const patientId = obj.patient.id;
        // Adicionando uma nova chave ao objeto
        tasks[index].duplicated = patientIdMap[patientId];
      }
    });

    return tasks;
  }
  // task duplicates
  const [keepDuplicates, setKeepDuplicates] = useState([] as Id[]);
  const [showDuplicates, setShowDuplicates] = useState([] as Id[]);

  // modal lead
  const [showLeadModal, setShowLeadModal] = useState(false);

  const handleClick = (task: Task) => {
    if (keepDuplicates.some(id => task.duplicated?.includes(id))) {
      setKeepDuplicates([]); // Desativa os IDs clicados
      handleSearchChange("");
    } else {
      setKeepDuplicates(task.duplicated || [task.id]);
      handleSearchChange(task.title)
    }
    logger.log(task.duplicated);
  };

  const handleMouseEnter = (task: Task) => {
    if (keepDuplicates.length === 0) {
      setShowDuplicates(task.duplicated || [task.id]);
      logger.log(task.duplicated);
    }
  };

  const handleMouseLeave = () => {
    setShowDuplicates([]);
  };

  const checkBoardId = (boardId: Id) => {
    if (!boardId || boardId === "") {
      toast.fire({
        icon: "error",
        title: "Informe o Board!",
      });
      return;
    }
  }

  const checkColumnId = (columnId: Id) => {
    if (!columnId || columnId === "") {
      toast.fire({
        icon: "error",
        title: "Informe a Coluna!",
      });
      return;
    }
  }

  const checkTaskId = (taskId: Id) => {
    if (!taskId || taskId === "") {
      toast.fire({
        icon: "error",
        title: "Informe a Task!",
      });
      return;
    }
  }

  const getBoards = async () => {
    setIsLoadingBoards(true);
    try {
      logger.log("getBoards");
      const response = await api.get('/Crm/Boards');
      if (response.data) {
        // logger.log(response.data);
        setBoards(response.data);
        getTags();
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoadingBoards(false);
    }
  }

  const getBoardById = async (boardId: Id) => {
    checkBoardId(boardId);
    setIsLoadingBoards(true);
    try {
      logger.log("getBoardById");
      const response = await api.get(`/Crm/Board/${boardId}`);
      if (response.data) {
        logger.log(response.data);
        setBoardData(response.data);
      }
    } catch (error: any) {
      console.error(error);
      setBoardData({} as Board);
    } finally {
      setIsLoadingBoards(false);
    }
  }

  const getTasksByBoard = async (boardId: Id) => {
    checkBoardId(boardId);
    try {
      logger.log("getBoardById");
      const response = await api.get(`/Crm/Board/${boardId}`);
      if (response.data) {
        if (response.data.columns) {
          if (response.data.columns.length > 0) {
            logger.log("Set Board Columns.");
            logger.log(response.data.columns);
            setColumnsData(response.data.columns);
          }
        }
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoadingBoards(false);
    }
  }

  const getCardsByBoard = async (boardId: Id, onlyPatients: boolean = false) => {
    checkBoardId(boardId);
    var allCards: any = [];
    try {
      logger.log("getCardsByBoard");
      const response = await api.get(`/Crm/Board/${boardId}`);
      if (response.data && response.data.columns) {
        response.data.columns.forEach((col: Column, colIndex: number) => {
          // setColumnsData((prevColumns) => [...prevColumns, col]);
          var rgbColor = rgbaToRgb(hexToRgba(((!col.color || col.color == "") ? "#FFFFFF" : col.color)));
          var bgColor = `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b},.5)`;
          col.color = (darkMode ? bgColor : col.color);
          if (col.tasks) {
            col.tasks.forEach((task: Task, index: number) => {

              if (onlyPatients && task.type !== 0) {
                return;
              }

              task.columnId = col.id;
              task.columnTitle = col.title;
              task.columnKey = colIndex;
              task.color = col.color;

              var newPatient: any = [];
              newPatient.board = response.data;
              newPatient.column = col;
              newPatient.task = task;

              if (task.patient && task.patient.id) {
                newPatient.patient = task.patient;
              }
              if (!task.patient && task.type === 0) {
                newPatient.patient = {};
                newPatient.patient.name = task.title;
              }
              allCards.push(newPatient);
            })
          }
        });
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      return allCards;
    }
  }

  const getPatientsByBoards = async (boards: Board[]) => {
    setIsLoadingBoards(true);
    logger.log("getPatientsByBoards");
    // setColumnsData([]);
    var allPatients: any = [];
    try {
      const allPatientsPromises = boards.map((board: Board) => {
        checkBoardId(board.id);
        return getCardsByBoard(board.id, true);
      });

      // Aguardar que todas as promessas sejam resolvidas
      const allPatientsResults = await Promise.all(allPatientsPromises);

      // Combinar todos os pacientes de todas as boards
      allPatients = allPatientsResults.flat();

      // Cria um array final que receberá os itens agrupados e os itens sem patient.id
      const finalArray: any = [];

      // Objeto para armazenar os itens agrupados por patient.id
      const groupedItems: any = {};

      // Percorre os itens para agrupar por patient.id ou adicionar diretamente os itens sem id
      allPatients.forEach((item: any) => {
        const patientId = item.patient?.id;
        if (patientId) {
          // Se o patient.id existir, agrupa no objeto groupedItems
          if (!groupedItems[patientId]) {
            groupedItems[patientId] = {
              patientId: patientId,
              items: []
            };
          }
          groupedItems[patientId].items.push(item);  // Adiciona o item ao grupo
        } else {
          // Se não houver patient.id, adiciona diretamente ao finalArray com chave autoincrementada
          finalArray.push({
            patientId: null,
            items: [item]  // Mantém a estrutura com a chave 'items'
          });
        }
      });

      // Adiciona os itens agrupados no finalArray
      Object.values(groupedItems).forEach(group => finalArray.push(group));
      setPatientsByBoard(finalArray);
      logger.log(finalArray);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingBoards(false);
    }
  }

  const getCardsByBoards = async (boards: Board[], onlyPatients: boolean = false) => {
    setIsLoadingBoards(true);
    logger.log("getAllCardsByBoards");

    // setColumnsData([]);
    var allCards: any = [];
    try {
      const allCardsPromises = boards.map((board: Board) => {
        checkBoardId(board.id);
        return getCardsByBoard(board.id, onlyPatients);
      });

      // Aguardar que todas as promessas sejam resolvidas
      const allCardsResults = await Promise.all(allCardsPromises);

      // Combinar todos os pacientes de todas as boards
      allCards = allCardsResults.flat();

      // Cria um array final que receberá os itens agrupados e os itens sem patient.id
      const finalArray: any = [];

      // Percorre os itens para agrupar por patient.id ou adicionar diretamente os itens sem id
      allCards.forEach((item: any) => {
        // Se não houver patient.id, adiciona diretamente ao finalArray com chave autoincrementada
        finalArray.push(item);
      });

      // Adiciona os itens agrupados no finalArray
      setCardsByBoard(finalArray);
      logger.log(allCards);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingBoards(false);
    }
  }

  const createBoard = async (data: BoardDefault) => {

    if (!data.name || data.name === "") {
      toast.fire({
        icon: "error",
        title: "Informe o nome do Board!",
      });
      return;
    }

    const body = {
      name: data.name,
      isDefault: data.isDefault
    };
    setIsLoading(true);
    try {
      logger.log("createBoard");
      const response = await api.post('/Crm/Board', body);
      // logger.log(response);
      if (response.data) {
        toast.fire({
          icon: "success",
          title: "Board cadastrado com sucesso!",
        });
        setTasksData([]);
        setColumnsData([]);
        setBoardData({} as Board);
        setBoards({} as Board[]);
        getBoards();
      }

    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const updateBoard = async (boardId: Id, data: BoardDefault) => {
    checkBoardId(boardId);
    setIsLoading(true);
    if (!data.name || data.name === "") {
      toast.fire({
        icon: "error",
        title: "Informe o nome do Board!",
      });
      return;
    }

    const body = {
      name: data.name,
      isDefault: data.isDefault
    };

    try {
      logger.log("updateBoard");
      const response = await api.put(`/Crm/Board/${boardId}`, body);
      logger.log(response);
      if (response.status === 200) {
        toast.fire({
          icon: "success",
          title: "Board atualizado com sucesso!",
        });
        setTasksData([]);
        setColumnsData([]);
        setBoardData({} as Board);
        setBoards({} as Board[]);
        getBoards();
      }

    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const deleteBoard = async (id: Id) => {
    if (!id || id === "") {
      toast.fire({
        icon: "error",
        title: "Informe o ID do Board!",
      });
      return;
    }
    setIsLoading(true);

    try {
      logger.log("deleteBoard");
      const response = await api.delete(`/Crm/Board/${id}`);
      // logger.log(response);
      if (response.data) {
        toast.fire({
          icon: "success",
          title: "Board removido com sucesso!",
        });
        setTasksData([]);
        setColumnsData([]);
        setBoardData({} as Board);
        getBoards();
        sessionStorage.removeItem(`crm-board`);
      }

    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const addColumnToBoard = async (boardId: Id, data: ColumnDefault, returnData: boolean = false) => {
    checkBoardId(boardId);

    if (!data.title || data.title === "") {
      toast.fire({
        icon: "error",
        title: "Informe o nome da Coluna!",
      });
      return;
    }

    setIsLoading(true);

    const body = {
      title: data.title,
      color: data.color,
      order: data.order,
      type: data.type,
    };

    try {
      logger.log("addColumnToBoard");
      const response = await api.post(`/Crm/Board/${boardId}/Column`, body);

      // logger.log(response);
      if (response.data && response.data.id) {
        toast.fire({
          icon: "success",
          title: "Coluna cadastrada com sucesso!",
        });

        var newColumn: Column = response.data;
        if (returnData) {
          return newColumn;
        }
        setColumnsData((prevColumns) => [...prevColumns, newColumn]);
      }

    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const editColumnFromBoard = async (boardId: Id, columnId: Id, data: ColumnDefault, showToast = true, callback?: () => void) => {
    var columnKey = Number(Object.keys(columnsData).find((x: any) => columnsData[x].id === columnId));

    checkBoardId(boardId);
    checkColumnId(columnId);

    if (!data.title || data.title === "") {
      toast.fire({
        icon: "error",
        title: "Informe o nome da Coluna!",
      });
      return;
    }

    if (!data.order) {
      data.order = columnsData[columnKey].order;
    }

    const tempColumns = [...columnsData];

    Object.assign(tempColumns[columnKey], data);
    const body = tempColumns[columnKey];

    try {
      logger.log("editColumnFromBoard");
      const response = await api.put(`/Crm/Board/${boardId}/Column/${columnId}`, body);
      if (response.status === 200) {
        if (showToast) {
          toast.fire({
            icon: "success",
            title: "Coluna atualizada com sucesso!",
          });
        }
        setColumnsData(tempColumns);
        if (callback) {
          callback();
          return;
        }
      } else {
        setColumnsData(columnsData);
      }

      logger.log(tempColumns);
    } catch (error: any) {
      setColumnsData(columnsData);
    } finally {
      setIsLoading(false);
    }
  }

  const delColumnFromBoard = async (boardId: Id, columnId: Id) => {
    checkBoardId(boardId);
    checkColumnId(columnId);

    setIsLoading(true);

    try {
      logger.log("delColumnFromBoard");
      const response = await api.delete(`/Crm/Board/${boardId}/Column/${columnId}`);
      // logger.log(response);
      if (response.status === 200) {
        toast.fire({
          icon: "success",
          title: "Coluna removida com sucesso!",
        });
        const filteredColumns = columnsData.filter((col) => col.id !== columnId);
        setColumnsData(filteredColumns);
      }

    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const addTaskToBoardColumn = async (boardId: Id, columnId: Id, data: TaskDefault, showReturn: boolean = false, callback?: () => void) => {
    checkBoardId(boardId);
    checkColumnId(columnId);
    if (!data.title || data.title === "") {
      toast.fire({
        icon: "error",
        title: `Informe o nome ${taskType === 0 ? 'do Paciente' : 'da Task'}!`,
      });
      return;
    }

    setIsLoading(true);
    const body = data;

    try {
      logger.log("addTaskToBoardColumn");
      const response = await api.post(`/Crm/Board/${boardId}/Column/${columnId}/Task`, body);

      if (response.data && response.data.id) {


        setShowAddTaskModal(false);
        setStopAutoUpdate(false);
        clearData();

        if (showReturn) {
          return response.data;
        }

        toast.fire({
          icon: "success",
          title: `${data.type === 0 ? "Paciente cadastrado" : "Task cadastrada"} com sucesso!`,
        });

        var newColumns = columnsData.map((column) => {
          if (column.id == columnId) {
            column.tasks?.push(response.data);
          }
          return column;
        });
        setColumnsData(newColumns);

        // Executa o callback se fornecido
        if (callback) {
          callback();
          return;
        }
        // getBoardById(boardId);
      }

    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const getTasksByColumn = async (boardId: Id, columnId: Id) => {
    checkBoardId(boardId);
    checkColumnId(columnId);
    setIsLoading(true);

    try {
      logger.log("getTasksByColumn");
      const response = await api.get(`/Crm/Board/${boardId}/Column/${columnId}/Tasks`);
      if (response.data) {
        var columnKey = Number(Object.keys(columnsData).find((x: any) => columnsData[x].id === columnId));
        columnsData[columnKey].tasks = response.data;
        setColumnsData(columnsData);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const getTaskById = async (boardId: Id, columnId: Id, taskId: Id) => {
    checkTaskId(taskId);
    checkBoardId(boardId);
    checkColumnId(columnId);
    // setIsLoading(true);

    try {
      logger.log("getTaskById");
      const response = await api.get(`/Crm/Board/${boardId}/Column/${columnId}/Task/${taskId}`);
      if (response.data) {
        const newColumns = [...columnsData];
        const columnKey = Number(Object.keys(newColumns).find((x: any) => newColumns[x].id === columnId));
        if (newColumns[columnKey]?.tasks) {
          const tasks = newColumns[columnKey].tasks;
          if (tasks) {
            // Atualiza as tasks da coluna
            newColumns[columnKey].tasks = tasks.map((task) => {
              if (task.id === taskId) {
                // Retorna o valor atualizado
                return response.data;
              }
              return task; // Mantém as tasks não alteradas
            });
            setColumnsData(newColumns);
          }
        }

      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const delTaskFromBoardColumn = async (boardId: Id, columnId: Id, taskId: Id) => {
    var taskKey = Number(Object.keys(tasksData).find((x: any) => tasksData[x].id === taskId));
    checkBoardId(boardId);
    checkColumnId(columnId);
    checkTaskId(taskId);

    setIsLoading(true);
    setShowAddTaskModal(false);
    setStopAutoUpdate(false);

    try {
      logger.log("delTaskFromBoardColumn");
      const response = await api.delete(`/Crm/Board/${boardId}/Column/${columnId}/Task/${taskId}`);

      if (response.status === 200) {
        toast.fire({
          icon: "success",
          title: `${tasksData[taskKey].type === 0 ? 'Paciente removido' : 'Task removida'} com sucesso!`,
        });

        const newColumns = columnsData.map((column) => {
          if (column.id === columnId) {
            if (column.tasks) {
              column.tasks = column.tasks.filter((task) => task.id !== taskId);
            }
          }
          return column;
        });
        setColumnsData(newColumns);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const editTaskFromBoardColumn = async (boardId: Id, columnId: Id, taskId: Id, data: TaskDefault, isObservation = false, callback?: () => void) => {
    // var taskKey = Number(Object.keys(tasksData).find((x: any) => tasksData[x].id === taskId));
    checkBoardId(boardId);
    checkColumnId(columnId);
    checkTaskId(taskId);

    if (!data.title || data.title === "") {
      toast.fire({
        icon: "error",
        title: `Informe o nome ${data.type === 0 ? "do Paciente" : "da Task"}!`,
      });
      return;
    }
    if (isObservation) {
      setObsLoading(true);
    } else {
      setIsLoading(true);
    }

    if (data.changeUpdatedDate) {
      data.updatedDate = (new Date()).toString();
    }

    const body = data;

    logger.log(body);
    try {
      logger.log("editTaskFromBoardColumn");
      const response = await api.put(`/Crm/Board/${boardId}/Column/${columnId}/Task/${taskId}`, body);
      if (response.status === 200) {
        const newColumns = columnsData.map((column) => {
          if (column.id === columnId) {
            if (column.tasks) {
              column.tasks = column.tasks.map((task) => {
                if (task.id == taskId) {
                  task = { ...task, ...data };
                }
                return task;
              });
            }
          }
          return column;
        });
        setColumnsData(newColumns);

        if (isObservation) {
          setObsLoading(false);
          toast.fire({
            icon: "success",
            title: `Observação salva!`,
          });
          getTaskById(boardId, columnId, taskId);
          // Executa o callback se fornecido
          if (callback) {
            callback();
            return;
          }
        } else {
          toast.fire({
            icon: "success",
            title: `${data.type === 0 ? "Paciente editado" : "Task editada"} com sucesso!`,
          });
          setIsLoading(false);
          setShowAddTaskModal(false);
          setStopAutoUpdate(false);
          clearData();// Executa o callback se fornecido
          if (callback) {
            callback();
            return;
          }
        }
      }

    } catch (error: any) {
      console.error(error);
      toast.fire({
        icon: "error",
        title: "Ops, ocorreu um erro ao salvar.",
      });
      setIsLoading(false);
      setObsLoading(false);
    }
  }

  const moveTaskBetweenBoardColumn = async (boardId: Id, columnId: Id, taskId: Id, data: any) => {
    checkBoardId(boardId);
    checkColumnId(columnId);
    checkTaskId(taskId);

    if (!data.columnId || data.columnId === "") {
      toast.fire({
        icon: "error",
        title: "Informe a nova Coluna!",
      });
      return;
    }

    setIsLoading(true);

    const newColumnId = data.columnId;

    const body = {
      columnId: newColumnId
    };

    try {
      logger.log("moveTaskBetweenBoardColumn");
      const response = await api.patch(`/Crm/Board/${boardId}/Column/${columnId}/Task/${taskId}`, body);

      if (response.status === 200) {
        toast.fire({
          icon: "success",
          title: `${data.type === 0 ? "Paciente editado" : "Task editada"} com sucesso!`,
        });

        // const tempColumns: Column[] = [...columnsData];
        const tempTasks: Task[] = [...tasksData];
        const newTask = tempTasks.find(task => task.id === taskId);

        if (newTask) {
          const newColKey = columnsData.findIndex((c) => c.id === newColumnId);
          newTask.columnId = newColumnId;
          newTask.updatedDate = (new Date()).toString();
          if (columnsData[newColKey]?.color) {
            newTask.color = columnsData[newColKey].color;
          } else {
            newTask.color = "";
          }
          const tempColumns = columnsData.map(column => {
            const newTasks = tempTasks.filter((t) => t.columnId === column.id);
            column.tasks = newTasks;
            return column;
          });
          setColumnsData(tempColumns);
        }

        setShowAddTaskModal(false);
        setStopAutoUpdate(false);
        clearData();

      }

    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const updateTasksOrder = (updateTasks: Task[]) => {
    logger.log("updateTasksOrder");
    logger.log(updateTasks);
    updateTasks.forEach((t, index) => {
      t.order = index;
      t.changeUpdatedDate = false;
      editTaskFromBoardColumn(boardData.id, t.columnId, t.id, t);
    });
  }

  const handleDeleteTask = (id: Id) => {
    var taskKey = Number(Object.keys(tasksData).find((x: any) => tasksData[x].id === id));
    Swal.fire({
      title: `Tem certeza que deseja remover este ${tasksData[taskKey].type === 0 ? 'Paciente' : 'Task'}?`,
      icon: "question",
      iconColor: 'var(--bs-danger)',
      position: "center",
      showConfirmButton: true,
      confirmButtonText: "Sim, remover",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      reverseButtons: true,
      allowOutsideClick: false,
      focusConfirm: false,
      customClass: {
        confirmButton: "btn btn-outline-danger",
        cancelButton: "btn btn-light",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        var taskKey = Number(Object.keys(tasksData).find((x: any) => tasksData[x].id === id));
        var columnId = tasksData[taskKey].columnId;
        delTaskFromBoardColumn(boardData.id, columnId, id);
      }
    });
  }

  const handleTaskModal = async (open: boolean, task: any = null) => {
    logger.log(task);
    setStopAutoUpdate(true);
    if (task) {
      var taskPromises: any = [];
      setColumnId(task.columnId);
      setEditTaskData(task);
      setTaskId(task.id);
      setTaskDescription(task.description ?? "");
      setTaskType(task.type ?? 0);
      if (task.patient && task.patient.id) {
        taskPromises.push(getPatientById(task.patient.id));
      } else {
        setPatientData({ cpf: "" });
      }
      if (task.appointment?.scheduleId) {
        taskPromises.push(getAppointmentById(task.appointment.scheduleId));
      } else {
        setAppointmentData({} as Appointment);
      }

      await Promise.all(taskPromises);

      setShowAddTaskModal(open);
      // if (task.appointment && task.appointment.date) {
      //   setTaskDate(task.appointment.date);
      // }
      // if (task.columnId) {
      //   setTaskMoveTo(task.columnId);
      // }
    } else {
      setEditTaskData({} as Task);
      setTaskId("");
      setTaskDescription("");
      setAppointmentData({} as Appointment);
      setShowAddTaskModal(open);
    }
  }

  const moveTaskBetweenBoards = async (boardId: Id, columnId: Id, taskId: Id, data: any, onHide?: () => void) => {
    checkBoardId(boardId);
    checkColumnId(columnId);
    checkTaskId(taskId);
    var body;

    if (!data.sendDescriptionFlag) {
      data.sendDescriptionFlag = false;
    }

    if (data.destinationUserId) {
      body = {
        destinationUserId: data.destinationUserId,
        sendDescriptionFlag: data.sendDescriptionFlag
      };
    } else if (data.destinationBoardId) {
      body = {
        destinationBoardId: data.destinationBoardId,
        sendDescriptionFlag: data.sendDescriptionFlag
      };
    } else {
      toast.fire({
        icon: "error",
        title: "Informe o board ou o profissional",
      });
      return;
    }

    if (!data.destinationBoardId && (!data.destinationUserId || data.destinationUserId === "")) {
      toast.fire({
        icon: "error",
        title: "Informe a Profissional!",
      });
      return;
    }
    if (!data.destinationUserId && (!data.destinationBoardId || data.destinationBoardId === "")) {
      toast.fire({
        icon: "error",
        title: "Informe a novo Board!",
      });
      return;
    }

    setIsLoading(true);

    const url = data.destinationUserId ? "ForwardBoardToOtherUser" : "ForwardBoardToSameUser"

    try {
      logger.log("moveTaskBetweenBoards");
      const response = await api.post(`/Crm/Board/${boardId}/Column/${columnId}/Task/${taskId}/${url}`, body);

      if (response.status === 200) {
        toast.fire({
          icon: "success",
          title: `Encaminhamento realizado com sucesso!`,
        });
        if (onHide) {
          onHide();
        }
      } else if(response.data.detail){
        toast.fire({
          icon: "error",
          title: response.data.detail,
        });
      } else {
        toast.fire({
          icon: "error",
          title: `Este profissional ainda não definiu um board para receber encaminhamentos.`,
        });
      }

    } catch (error: any) {
      console.error(error);
      toast.fire({
        icon: "error",
        title: `Este profissional ainda não definiu um board para receber encaminhamentos.`,
      });
    } finally {
      setIsLoading(false);
    }
  }

  const createColumn = async (data: ColumnDefault) => {

    await addColumnToBoard(boardData.id, data);

    setShowAddColumnModal(false);
    setStopAutoUpdate(false);
    setColumnName('');
  }

  const deleteColumn = async (id: Id) => {
    delColumnFromBoard(boardData.id, id);
  }

  const updateColumn = async (id: Id, updateData: ColumnDefault, callback?: () => void) => {

    await editColumnFromBoard(boardData.id, id, updateData, true, callback);

    setShowAddColumnModal(false);
    setStopAutoUpdate(false);
    setEditColumnData({} as Column);
    setColumnName('');
  }

  const clearData = () => {
    setTaskName('');
    setTaskDescription('');
    setTaskType(0);
    setColumnId('');
    setPatients([]);
    setPatientData({ cpf: "" });
    setEditTaskData({} as Task);
    setTaskMoveTo('');
    setTaskDate('');
  };

  const updateTask = (task: Task, callback?: () => void) => {
    var taskKey = Number(Object.keys(tasksData).find((x: any) => tasksData[x].id === task.id));
    var newPatient = task.patient ?? undefined;
    if (patientData && patientData.id) {
      newPatient = {
        ...newPatient,
        id: patientData.id,
        name: patientData?.name ?? "",
        email: patientData?.email
      };
    }

    const taskBody = {
      ...task,
      patient: newPatient,
    };

    logger.log(boardData.id, tasksData[taskKey].columnId, task.id, taskBody);

    editTaskFromBoardColumn(boardData.id, tasksData[taskKey].columnId, task.id, taskBody, false, callback);

  }


  const createTag = async (data: TagData, list: string = "") => {
    setIsLoading(true);

    if (!data.title || data.title === "") {
      toast.fire({
        icon: "error",
        title: "Informe um título para a Tag!",
      });
      return;
    }

    const body = {
      tagName: data.title,
      color: data.color
    };

    try {
      logger.log("createTag");
      const response = await api.post('/Crm/TagTask', body);
      // logger.log(response);
      if (list != "") {
        getTags("", list);
      } else {
        getTags();
      }
      if (response.data) {
        toast.fire({
          icon: "success",
          title: "Tag cadastrada com sucesso!",
        });
      }

    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const getTags = async (filter: string = "", list: string = "") => {
    setIsLoading(true);
    try {
      logger.log("getTags");
      const response = await api.get(`/Crm/TagTask/filter=${filter}${["all", "inactive", "active"].includes(list) ? '&' + list : ''}`);
      if (response.data) {
        logger.log(response.data);
        setTags(response.data);
      } else {
        setTags([]);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const deleteTag = async (id: Id) => {
    setIsLoading(true);
    if (!id || id == "") {
      toast.fire({
        icon: "error",
        title: "Informe o ID da tag!",
      });
      return
    }
    try {
      logger.log("deleteTags");
      const response = await api.delete(`/Crm/TagTask/${id}`);
      if (response.status === 200) {
        toast.fire({
          icon: "success",
          title: `Tag removida com sucesso!`,
        });
        const newTags = tags.map((tag) => {
          if (tag.id === id) {
            tag.isActive = false;
          }
          return tag;
        });
        setTags(newTags);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (columnsData.length > 0) {
      logger.log("atualizou coluna", columnsData);
      var newTasks: Task[] = [];
      columnsData.sort((a, b) => {
        if (a.order === b.order) {
          // Se os 'order' são iguais, ordenar por 'createdDate'
          return moment(a.createdDate).valueOf() - moment(b.createdDate).valueOf();
        }
        // Caso contrário, ordenar por 'order'
        return a.order - b.order;
      }).map((col, colIndex) => {
        if (col.order != colIndex && [0, 3, 4].includes(col.type)) {
          col.order = colIndex;
          editColumnFromBoard(boardData.id, col.id, col, false);
        }
        col.order = colIndex;
        if (col.tasks) {
          col.tasks.map((task, index) => {
            task.columnId = col.id;
            task.columnTitle = col.title;
            task.columnKey = colIndex;
            task.color = col.color;
            newTasks.push(task);
          })
          logger.log("Set Board Tasks.");
          setTasksData(checkDuplicatedTasks(newTasks));
        }
      });
    }
  }, [columnsData]);

  const leadOrigin: SelectOptions[] = [
    { value: 5, label: 'Instagram', icon: 'instagram', color: '#d9317a' },
    { value: 4, label: 'Facebook', icon: 'facebook', color: '#3b5998' },
    { value: 3, label: 'QRCode', icon: 'qrcode-scan', color: '#795548' },
    { value: 2, label: 'WhatsApp', icon: 'whatsapp', color: '#25d366' },
    { value: 1, label: 'Indicação Profissional', icon: 'medkit', color: '#03a9f4' },
    { value: 6, label: 'Agendamento Conectadoc', icon: 'conecta', color: '#FFFFFF' },
    { value: 99, label: 'Outro', icon: 'chat-bubble-user', color: '#3d0a91' },
  ];

  const getTagsFromBoard = (boardData: Board) => {
    var newTagsIDs: any[] = [];
    if (boardData.columns) {
      boardData.columns.forEach((col: Column, colIndex: number) => {
        if (col.tasks) {
          col.tasks.forEach((task: Task, index: number) => {
            if (task.tags) {
              task.tags.forEach((tag: Tag, i: number) => {
                if (newTagsIDs.indexOf(tag.id) === -1) {
                  newTagsIDs.push(tag.id);
                }
              });
            }
          });
        }
      });
    }
    setFilterTags(tags.filter((tag) => newTagsIDs.indexOf(tag.id) !== -1));
  }

  const getTagsFromPatients = (patientsData: any) => {
    var newTagsIDs: any[] = [];
    if (patientsData) {
      patientsData.forEach((patient: any) => {
        if (patient.items) {
          patient.items.forEach((item: any) => {
            if (item.task && item.task.tags) {
              item.task.tags.forEach((tag: Tag) => {
                if (newTagsIDs.indexOf(tag.id) === -1) {
                  newTagsIDs.push(tag.id);
                }
              });
            }
          });
        }
      });
    }
    setFilterTags(Array.from(new Map(tags.filter((tag) => newTagsIDs.indexOf(tag.id) !== -1).map((item) => [item.id, item])).values()));
  }

  const handleTagChange = (e: any) => {
    const selectedArrayIDs = e.map((item: any) => (item.value));
    const updatedFilterArray = filterTags.map((item) => ({
      ...item,
      isFixed: selectedArrayIDs.includes(item.id), // Adiciona isFixed se o id estiver no primeiro array
    }));
    setFilterTags(updatedFilterArray);
  };

  return (
    <BoardContext.Provider
      value={{
        boards,
        setBoards,
        getBoards,
        boardData,
        isLoading,
        isLoadingBoards,
        setIsLoadingBoards,
        setBoardData,
        tasksData,
        setTasksData,
        editTaskData,
        setEditTaskData,
        editColumnData,
        setEditColumnData,
        columnsData,
        setColumnsData,
        taskName,
        setTaskName,
        taskDescription,
        setTaskDescription,
        taskDate,
        setTaskDate,
        taskMoveTo,
        setTaskMoveTo,
        taskType,
        setTaskType,
        taskId,
        setTaskId,
        showAddTaskModal,
        setShowAddTaskModal,
        showForwardPatientModal,
        setShowForwardPatientModal,
        showModalTag,
        setShowModalTag,
        createBoard,
        updateBoard,
        deleteBoard,
        getBoardById,
        getTasksByBoard,
        getPatientsByBoards,
        getCardsByBoards,
        patientsByBoard,
        setPatientsByBoard,
        cardsByBoard,
        setCardsByBoard,
        addColumnToBoard,
        editColumnFromBoard,
        addTaskToBoardColumn,
        getTasksByColumn,
        delColumnFromBoard,
        delTaskFromBoardColumn,
        editTaskFromBoardColumn,
        moveTaskBetweenBoardColumn,
        autoUpdate,
        setAutoUpdate,
        stopAutoUpdate,
        setStopAutoUpdate,
        checkDuplicatedTasks,
        keepDuplicates,
        setKeepDuplicates,
        showDuplicates,
        setShowDuplicates,
        handleClick,
        handleMouseEnter,
        handleMouseLeave,
        searchTerm,
        setSearchTerm,
        filteredTasks,
        setFilteredTasks,
        handleSearchChange,
        updateTasksOrder,
        handleDeleteTask,
        handleTaskModal,
        moveTaskBetweenBoards,
        columnAddIndex,
        setColumnAddIndex,
        columnName,
        setColumnName,
        columnId,
        setColumnId,
        showAddColumnModal,
        setShowAddColumnModal,
        hex,
        setHex,
        createColumn,
        deleteColumn,
        updateColumn,
        clearData,
        updateTask,
        tags,
        setTags,
        getTags,
        deleteTag,
        createTag,
        leadOrigin,
        OTHERS_LEAD_ORIGIN,
        obsLoading,
        setObsLoading,
        getTaskById,
        filterTags,
        setFilterTags,
        getTagsFromBoard,
        handleTagChange,
        getTagsFromPatients,
        showLeadModal,
        setShowLeadModal
      }}>
      {children}
    </BoardContext.Provider>
  );
};

export const useBoard = () => useContext(BoardContext);

import React, { useState, useEffect } from 'react';
import { toast } from "utils/toast";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Script, Message, Group, orderScript } from 'pages/Scripts';
import logger from "utils/logger";
import { useNavigate } from "react-router-dom";
import { api } from "services";
import _ from "lodash";

interface msgStatus {
  groupId?: string,
  subGroupId?: string,
  messageId?: string,
  isExecuted: boolean
}
interface SalesScriptsProps {
  chatId: string;
  onScriptSelect: (scriptContent: string) => void; // Prop recebendo a função do componente pai
}
function SalesScripts({ chatId, onScriptSelect }: SalesScriptsProps) {
  const navigate = useNavigate();

  const [isLoadingScripts, setIsLoadingScripts] = useState(false);
  const [scriptsData, setScriptsData] = useState([] as Script[]);

  const getScripts = async () => {
    setIsLoadingScripts(true);
    try {
      logger.log("getScripts");
      const response = await api.get(`/Crm/Scripts/${chatId}`);
      if (response.data) {
        let newScripts = orderScript(response.data);
        // debugger;
        setScriptsData(newScripts);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoadingScripts(false);
    }
  }

  // Função para copiar o conteúdo do passo para a área de transferência
  const handleCopyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content);
    toast.fire({
      icon: "success",
      title: "Mensagem copiada para a área de transferência!",
    });
  };


  const changeMsgStatus = async (data: msgStatus) => {
    // debugger;
    if (!data.messageId) {
      toast.fire({
        icon: "error",
        title: `Informe a mensagem que deseja atualizar o status.`,
      });
      return;
    }

    var body = data;
    console.log(body);

    var oldData = _.cloneDeep(scriptsData);

    if (data.messageId && data.groupId) {
      const updatedData = scriptsData.map(group => {
        return {
          ...group,
          subGroups: group.subGroups?.map(subGroup => ({
            ...subGroup,
            messages: subGroup.messages?.map(message =>
              message.id === data.messageId
                ? { ...message, isExecuted: data.isExecuted }
                : message
            )
          })),
          messages: group.messages?.map(message =>
            message.id === data.messageId
              ? { ...message, isExecuted: data.isExecuted }
              : message
          )
        };
      });
      setScriptsData(updatedData);
    }

    // setIsLoading(true);
    try {
      logger.log("change script msg status");
      const response = await api.post(`/Crm/Scripts/ChangeExecutionStatus/${chatId}`, body);
      if (response.data) {
        toast.fire({
          icon: "success",
          title: `Mensagem ${data.isExecuted ? '' : 'des'}marcada com utilizada!`,
        });
      }

    } catch (error: any) {
      console.error(error);
      setScriptsData(oldData);
    } finally {
      // setIsLoading(false);
    }
  }

  const getSubgroupProgress = (messages?: Message[]) => {
    if (!messages?.length) return { completed: 0, total: 0, isComplete: false };
    const completed = messages.filter(msg => msg.isExecuted).length;
    return {
      completed,
      total: messages.length,
      isComplete: completed === messages.length
    };
  };

  const getGroupProgress = (group: Script) => {
    let completed = 0;
    let total = 0;

    // Count messages in group
    if (group.messages?.length) {
      completed += group.messages.filter(msg => msg.isExecuted).length;
      total += group.messages.length;
    }

    // Count messages in subgroups
    if (group.subGroups?.length) {
      group.subGroups.forEach(subgroup => {
        const { completed: subCompleted, total: subTotal } = getSubgroupProgress(subgroup.messages);
        completed += subCompleted;
        total += subTotal;
      });
    }

    return {
      completed,
      total,
      isComplete: total > 0 && completed === total
    };
  };

  const CompletionIcon = () => (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>Todas as mensagens realizadas</Tooltip>}
    >
      <i className="uil uil-check-circle text-success ms-2"></i>
    </OverlayTrigger>
  );

  const ProgressIndicator = ({ completed, total }: { completed: number, total: number }) => (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{completed} de {total} mensagens realizadas</Tooltip>}
    >
      <span className="ms-2 small text-muted">
        {completed}/{total}
      </span>
    </OverlayTrigger>
  );


  useEffect(() => {
    getScripts();
  }, [chatId]);

  return (
    <div className="d-flex flex-column h-100">
      <div className="h-50">
        <div className="d-block bg-light sticky-top border-bottom p-3">
          <h6 className="mb-0 w-100">Scripts</h6>
        </div>
        <div className="p-2">
          {isLoadingScripts ? (
            <div className="placeholders">
              {[...Array(9)].map((_, i) => (
                <div key={i} className="card border-0 pb-5 pt-3 mb-2 placeholder-wave"></div>
              ))}
            </div>
          ) : (
            scriptsData.length > 0 ? (
              <>
                {scriptsData.map(group => {
                  // Filtra subgrupos que possuem mensagens
                  const filteredSubGroups = group.subGroups?.filter(subGroup => subGroup.messages && subGroup.messages.length > 0) || [];

                  // Verifica se o grupo tem mensagens ou subgrupos com mensagens
                  if ((group.messages && group.messages.length > 0) || filteredSubGroups.length > 0) {
                    return {
                      ...group,
                      subGroups: filteredSubGroups, // Inclui apenas subgrupos filtrados
                    };
                  }
                  return null; // Ignorar grupos sem mensagens ou subgrupos com mensagens
                }).map((group, index) => {
                  if (group !== null) {
                    const groupProgress = getGroupProgress(group);
                    return (
                      <Accordion key={index} className="mb-2">
                        <Accordion.Item eventKey={group.id as string}>
                          <Accordion.Header>
                            <span className="fw-semibold d-flex w-100 pe-3">
                              {group.description}
                              <span className='ms-auto'>
                                {groupProgress.isComplete ? <CompletionIcon /> : <ProgressIndicator completed={groupProgress.completed} total={groupProgress.total} />}
                              </span>
                            </span>
                          </Accordion.Header>
                          <Accordion.Body className='p-2 pb-0'>
                            {/* subgrupo */}
                            {group.subGroups?.map((subgroup, subIndex) => {
                              const subgroupProgress = getSubgroupProgress(subgroup.messages);
                              return (
                                <Accordion key={subIndex} className="mb-2">
                                  <Accordion.Item eventKey={subgroup.id as string}>
                                    <Accordion.Header>
                                      <span className="fw-semibold d-flex w-100 pe-3">
                                        {subgroup.description}
                                        <span className='ms-auto'>
                                          {subgroupProgress.isComplete ? <CompletionIcon /> : <ProgressIndicator completed={subgroupProgress.completed} total={subgroupProgress.total} />}
                                        </span>
                                      </span>
                                    </Accordion.Header>
                                    <Accordion.Body className="py-0 px-2">
                                      {subgroup.messages && subgroup.messages.length > 0 && (
                                        <div className="list-group list-group-flush mx-n2">
                                          {subgroup.messages?.map((message, msgIndex) => (
                                            <div key={msgIndex} className={`list-group-item py-3 d-flex align-items-center ${msgIndex + 1 == subgroup.messages?.length ? 'rounded-bottom' : ''}`}>
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>{(1 == 1) ? 'Marcar como não realizada.' : 'Marcar como concluída.'}</Tooltip>}
                                              >
                                                <label className="form-check form-switch mb-0 me-2 ms-n2">
                                                  <input className="form-check-input" type="checkbox" role="switch" checked={message.isExecuted} onChange={(e) => {
                                                    changeMsgStatus({
                                                      groupId: group.id,
                                                      subGroupId: subgroup.id,
                                                      messageId: message.id,
                                                      isExecuted: e.target.checked
                                                    })
                                                  }} />
                                                </label>
                                              </OverlayTrigger>
                                              <p className='small lh-base mb-0 w-100' style={{ whiteSpace: "pre-wrap" }}>{message.message}</p>
                                              <OverlayTrigger
                                                placement="top"
                                                container={document.body}
                                                overlay={<Tooltip>Copiar</Tooltip>}
                                              >
                                                <button className='btn btn-link text-body float-end me-n3' onClick={() => { handleCopyToClipboard(message.message); onScriptSelect(message.message); }}>
                                                  <i className='uil uil-copy'></i>
                                                </button>
                                              </OverlayTrigger>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              )
                            })}
                            {/* mensagens */}
                            {group.messages && group.messages.length > 0 && (
                              <div className="list-group list-group-flush mx-n2 mt-n2">
                                {group.messages?.map((message, msgIndex) => (
                                  <div key={msgIndex} className={`list-group-item py-3 d-flex align-items-center ${msgIndex + 1 == group.messages?.length ? 'rounded-bottom' : ''}`}>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>{(1 == 1) ? 'Marcar como não realizada.' : 'Marcar como concluída.'}</Tooltip>}
                                    >
                                      <label className="form-check form-switch mb-0 me-2 ms-n2">
                                        <input className="form-check-input" type="checkbox" role="switch" checked={message.isExecuted} onChange={(e) => {
                                          changeMsgStatus({
                                            groupId: group.id,
                                            messageId: message.id,
                                            isExecuted: e.target.checked
                                          })
                                        }} />
                                      </label>
                                    </OverlayTrigger>
                                    <p className='small lh-base mb-0 w-100' style={{ whiteSpace: "pre-wrap" }}>{message.message}</p>
                                    <OverlayTrigger
                                      placement="top"
                                      container={document.body}
                                      overlay={<Tooltip>Copiar</Tooltip>}
                                    >
                                      <button className='btn btn-link text-body float-end me-n3' onClick={() => { handleCopyToClipboard(message.message); onScriptSelect(message.message); }}>
                                        <i className='uil uil-copy'></i>
                                      </button>
                                    </OverlayTrigger>
                                  </div>
                                ))}
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    )
                  }
                })}
              </>
            ) : (
              <>
                <div className="py-5 text-center">
                  <h4 className="text-center">
                    Você não possui nenhum script cadastrado.
                  </h4>
                  <div className="text-center pt-3 pb-5 d-print-none">
                    <button type="button" onClick={() => { navigate('/scripts'); }} className="btn btn-outline-primary rounded-pill">Configurar Scripts</button>
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesScripts;

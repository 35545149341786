import axios, { AxiosResponse } from "axios";
import moment from "moment";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAuth } from "providers/Auth";
import { api } from "services";
import { RoleDescription } from "types/enums";
import { UserEditData, User, UserAdminEditData } from "types/user";
import { toast } from "utils/toast";
import { API_URL } from "../../config";

interface UserProviderProps {
  children: ReactNode;
}

interface UserProviderData {
  userData: User;
  setUserData: React.Dispatch<React.SetStateAction<User>>;
  isLoading: boolean;
}

export const UserContext = createContext<UserProviderData>(
  {} as UserProviderData
);

export const UserProvider = ({ children }: UserProviderProps) => {
  const { token, userId, userRole } = useAuth();
  const [userData, setUserData] = useState<User>({} as User);
  const [isLoading, setIsLoading] = useState(false);

  const getUserData = async () => {

    try {
      const response = await axios.get(
        `${API_URL}SpecialistDoctor/id?id=${userId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUserData(response.data);

      if (!response.data.healthInsurance) {
        // pega os planos
        try {
          const response = await axios.get(
            `${API_URL}/SpecialistDoctor/list?page=1&size=1&idSpecialistDoctor=${userId}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          if (response.data && response.data.specialistDoctors && response.data.specialistDoctors[0]) {
            const insurences = response.data.specialistDoctors[0].healthInsurance ?? [];
            setUserData(prev => ({
              ...prev,
              healthInsurance: insurences
            }));
          }
        } catch (error) {
          console.error(error);
        }
      }

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (token) {
      getUserData();
    }
  }, [token]);

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        isLoading,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
import React from 'react';
import { Accordion, Dropdown } from 'react-bootstrap';
import { DndContext, PointerSensor, useSensor, useSensors, DragOverlay, DragStartEvent, DragOverEvent, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Group, Script } from '..';
import SortableSubgroup from './sortableSubgroup';
import SortableMessage from './sortableMessage';

interface SortableGroupProps {
  group: Script;
  onShowModal: (type: "group" | "subgroup" | "message", group: Group, subgroupId?: string, messageId?: string) => void;
  onRemoveItem: (type: "group" | "subgroup" | "message", group: Group, subgroupId?: string, messageId?: string) => void;
  openGroups: string[];
  setOpenGroups: React.Dispatch<React.SetStateAction<string[]>>;
}

const SortableGroup: React.FC<SortableGroupProps> = ({ group, onShowModal, onRemoveItem, openGroups, setOpenGroups }) => {

  const isOpen = openGroups.includes(group.id);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: group.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const toggleOpen = () => {
    if (isOpen) {
      setOpenGroups(openGroups.filter(id => id !== group.id));
    } else {
      setOpenGroups([...openGroups, group.id]);
    }
  };

  return (
    <Accordion activeKey={isOpen ? group.id : ''} key={group.id} className="mb-2">
      <Accordion.Item eventKey={group.id} ref={setNodeRef} style={style}>
        <div className="content-hover position-relative">
          <div className="drag-handle position-absolute top-50 translate-middle-y start-0 ms-n3 border rounded bg-white p-1 show-hover"
            style={{ zIndex: 4 }}
            {...attributes}
            {...listeners}>
            <i className="uil uil-draggabledots"></i>
          </div>
          <div className="position-absolute end-0 top-50 translate-middle-y me-5" style={{ zIndex: 4 }}>
            <Dropdown>
              <Dropdown.Toggle variant="light" size="sm" className="show-hover no-arrow p-2 lh-1 rounded-circle">
                <i className="uil uil-ellipsis-h"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu align='end'>
                <Dropdown.Item as="button" onClick={() => onShowModal("subgroup", group)}>
                  <i className="uil uil-plus me-2"></i>Adicionar Subgrupo
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onShowModal("message", group)}>
                  <i className="uil uil-comment-alt-check me-2"></i>Adicionar Mensagem
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onShowModal("group", group)}>
                  <i className="uil uil-edit-alt me-2"></i>Editar Grupo
                </Dropdown.Item>
                <Dropdown.Item as="button" className='text-danger' onClick={() => onRemoveItem("group", group)}>
                  <i className="uil uil-trash me-2"></i>Remover Grupo
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Accordion.Header onClick={toggleOpen}>
            <div className="d-flex align-items-center w-100 pe-2 content-hover">
              <span className="fw-semibold">{group.description}</span>
            </div>
          </Accordion.Header>
        </div>
        <Accordion.Body>
          <SortableContext items={group.subGroups?.map(subgroup => subgroup.id) || []} strategy={verticalListSortingStrategy}>
            {group.subGroups?.map((subgroup) => (
              <SortableSubgroup
                key={subgroup.id}
                subgroup={subgroup}
                group={group}
                onShowModal={onShowModal}
                onRemoveItem={onRemoveItem}
                openGroups={openGroups}
                setOpenGroups={setOpenGroups}
              />
            ))}
          </SortableContext>

          <SortableContext items={group.messages?.map(message => message.id) || []} strategy={verticalListSortingStrategy}>
            {group.messages && group.messages.length > 0 && (
              <div className="list-group list-group-flush mx-n3 mb-2 mt-n2 border-bottom">
                {group.messages?.map((message) => (
                  <SortableMessage
                    key={message.id}
                    message={message}
                    group={group}
                    onShowModal={onShowModal}
                    onRemoveItem={onRemoveItem}
                  />
                ))}
              </div>
            )}
          </SortableContext>

          <div className="d-flex justify-content-center gap-2 pt-2">
            <button type="button" onClick={() => onShowModal("subgroup", group)} className="btn btn-sm btn-outline-primary rounded-pill">
              <i className="uil uil-plus"></i> Adicionar Subgrupo
            </button>
            <button type="button" onClick={() => onShowModal("message", group)} className="btn btn-sm btn-outline-primary rounded-pill">
              <i className="uil uil-comment-alt-check"></i> Adicionar Mensagem
            </button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default SortableGroup;
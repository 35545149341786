import { useState, useEffect } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import icon from "assets/crm-branco.svg";
import { useAuth } from "providers/Auth";
import { useDarkMode } from "providers/DarkMode";
import { useFeedback } from "providers/Feedback";
import { useTutorial } from "providers/Tutorial";
import { RoleDescription } from "types/enums";
import VersionModal from "components/VersionModal";
import ModalFeedback from "components/ModalFeedback";
import cuidadoDigital from "assets/icon.png";
import { isDev } from 'utils/isDev';

import DropdownMenu from "./components/DropdownMenu";
import NotificationAlert from "./components/NotificationAlert";

const Header = () => {
  const { token, userRole } = useAuth();
  const { pathname } = useLocation();
  const { darkMode, menuShow, setMenuShow, handleMenuShow } = useDarkMode();
  const { showFeedbackModal, setShowFeedbackModal } = useFeedback();
  const { steps, setSteps, stepIndex, setStepIndex, run, setRun, tourActive, setTourActive } = useTutorial();


  const [hideTooltip, setHideTooltip] = useState(false);

  const handleMenuClick = () => {
    if (document.body.clientWidth <= 640) {
      setMenuShow(false);
    }
  }

  const handleResize = () => {
    if (document.body.clientWidth < 576) {
      setHideTooltip(true);
    } else {
      setHideTooltip(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      <div id="header" className="d-print-none">
        <Link to="/" className={`icone`} >
          <img src={icon} alt="CRM Conectadoc" />
        </Link>
        <a role="button" className="abre-menu" onClick={handleMenuShow}><i className="uil uil-angle-right"></i> <span>Menu</span></a>
        <div id="menu" className="d-flex flex-column flex-sm-row flex-md-column justify-content-center justify-content-md-between">
          <ul>
            <li className={pathname == "/" ? "active" : undefined}>
              <OverlayTrigger key={pathname} placement="right" overlay={!menuShow && !hideTooltip ? <Tooltip>Dashboard</Tooltip> : <></>}>
                <Link to="/" onClick={() => { handleMenuClick(); }}>
                  <i className="uil uil-chart-pie-alt"></i>
                  <span>Dashboard</span>
                </Link>
              </OverlayTrigger>
            </li>
            <li className={pathname == "/boards" ? "active" : undefined}>
              <OverlayTrigger key={pathname} placement="right" overlay={!menuShow && !hideTooltip ? <Tooltip>Boards</Tooltip> : <></>}>
                <Link to="/boards" onClick={() => { handleMenuClick(); }}>
                  <i className="uil uil-grids"></i>
                  <span>Boards</span>
                </Link>
              </OverlayTrigger>
            </li>
            <li className={pathname.includes("/pacientes") ? "active" : undefined}>
              <OverlayTrigger key={pathname} placement="right" overlay={!menuShow && !hideTooltip ? <Tooltip>Pacientes</Tooltip> : <></>}>
                <Link to="/pacientes" onClick={() => { handleMenuClick(); }}>
                  <i className="uil uil-chat-bubble-user"></i>
                  <span>Pacientes</span>
                </Link>
              </OverlayTrigger>
            </li>
            <li className={pathname.includes("/calendario") ? "active" : undefined}>
              <OverlayTrigger key={pathname} placement="right" overlay={!menuShow && !hideTooltip ? <Tooltip>Calendário</Tooltip> : <></>}>
                <Link to="/calendario" onClick={() => { handleMenuClick(); }}>
                  <i className="uil uil-calendar-alt"></i>
                  <span>Calendário</span>
                </Link>
              </OverlayTrigger>
            </li>
            <li className={pathname.includes("/qr-code") ? "active" : undefined}>
              <OverlayTrigger key={pathname} placement="right" overlay={!menuShow && !hideTooltip ? <Tooltip>QR Code</Tooltip> : <></>}>
                <Link to="/qr-code" onClick={() => { handleMenuClick(); }}>
                  <i className="uil uil-qrcode-scan"></i>
                  <span>QR Code</span>
                </Link>
              </OverlayTrigger>
            </li>
            <li className={pathname.includes("/conversas") ? "active" : undefined}>
              <OverlayTrigger key={pathname} placement="right" overlay={!menuShow && !hideTooltip ? <Tooltip>Conversas</Tooltip> : <></>}>
                <Link to="/conversas" onClick={() => { handleMenuClick(); }}>
                  <i className="uil uil-comments-alt"></i>
                  <span>Conversas</span>
                </Link>
              </OverlayTrigger>
            </li>

            <li className={pathname.includes("/tags") ? "active" : undefined}>
              <OverlayTrigger key={pathname} placement="right" overlay={!menuShow && !hideTooltip ? <Tooltip>Tags</Tooltip> : <></>}>
                <Link to="/tags" onClick={() => { handleMenuClick(); }}>
                  <i className="uil uil-tag-alt"></i>
                  <span>Tags</span>
                </Link>
              </OverlayTrigger>
            </li>
            <li className={pathname.includes("/scripts") ? "active" : undefined}>
              <OverlayTrigger key={pathname} placement="right" overlay={!menuShow && !hideTooltip ? <Tooltip>Scripts</Tooltip> : <></>}>
                <Link to="/scripts" onClick={() => { handleMenuClick(); }}>
                  <i className="uil uil-list-ul"></i>
                  <span>Scripts</span>
                </Link>
              </OverlayTrigger>
            </li>
            {isDev() && (
              <>
                <li className={pathname.includes("/fluxos") ? "active" : undefined}>
                  <OverlayTrigger key={pathname} placement="right" overlay={!menuShow && !hideTooltip ? <Tooltip>Fluxos</Tooltip> : <></>}>
                    <Link to="/fluxos" onClick={() => { handleMenuClick(); }}>
                      <i className="uil uil-code-branch"></i>
                      <span>Fluxos</span>
                    </Link>
                  </OverlayTrigger>
                </li>
              </>
            )}
          </ul>
          <hr className="my-2 d-lg-none" />
          <ul>
            <li className={pathname.includes("/integracoes") ? "active" : undefined}>
              <OverlayTrigger key={pathname} placement="right" overlay={!menuShow && !hideTooltip ? <Tooltip>Integrações</Tooltip> : <></>}>
                <Link to="/integracoes" onClick={() => { handleMenuClick(); }}>
                  <i className="uil uil-server-connection"></i>
                  <span>Integrações</span>
                </Link>
              </OverlayTrigger>
            </li>
            {isDev() && (
              <>
                <li className={showFeedbackModal ? "active" : undefined}>
                  <OverlayTrigger key={pathname} placement="right" overlay={!menuShow && !hideTooltip ? <Tooltip>Feedback</Tooltip> : <></>}>
                    <a role="button" onClick={() => { setShowFeedbackModal(true); }}>
                      <i className="uil uil-comment-dots"></i>
                      <span>Feedback</span>
                    </a>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger key={pathname} placement="right" overlay={!menuShow && !hideTooltip ? <Tooltip>Ajuda</Tooltip> : <></>}>
                    <a role="button" onClick={() => { setRun(true); setTourActive(true); setStepIndex(0); }}>
                      <i className="uil uil-question-circle"></i>
                      <span>Ajuda</span>
                    </a>
                  </OverlayTrigger>
                </li>
              </>
            )}
            <li className="p-3 d-none d-md-block">
              <OverlayTrigger key={pathname} placement="right" overlay={<Tooltip>{!menuShow && (<>Já conhece o <b>Cuidado Digital Assinaturas</b>?<br /><br /></>)} Gestão de marketing completo para profissionais da saúde.<br /><br />Clique e saiba mais.</Tooltip>}>
                <a href="https://wa.me/5541987788556?text=Gostaria%20de%20saber%20mais%20sobre%20o%20Cuidado%20Digital%20Assinaturas." target="_blank" className="link-cuidado text-decoration-none text-bg-white d-block rounded-4 p-2 small">
                  <img src={cuidadoDigital} alt="Cuidado Digital Conectadoc" style={{ width: 60, maxWidth: '100%' }} />
                  {menuShow && (
                    <p className="mb-0 pt-t">Já conhece o <b>Cuidado Digital Assinaturas</b>?</p>
                  )}
                </a>
              </OverlayTrigger>
            </li>
          </ul>
        </div>
        <DropdownMenu />
      </div >
      <ModalFeedback show={showFeedbackModal} />
    </>
  );
};

export default Header;

import moment from "moment";
import { createContext, ReactNode, useContext, useState } from "react";
import { NavigateFunction } from "react-router-dom";
import axios, { AxiosResponse } from "axios";

import { api } from "services";
import { PatientData, Appointment } from "types/patient";
import { toast } from "utils/toast";
import { API_URL } from "../../config";
import logger from "utils/logger";

interface PatientProviderProps {
  children: ReactNode;
}

interface PatientProviderData {
  patients: PatientData[];
  setPatients: (data: PatientData[]) => void;
  patientData: PatientData;
  setPatientData: (data: PatientData) => void;
  editPatientData: (data: PatientData) => Promise<void>;
  patientLoading: boolean;
  isLoadingPaciente: boolean;
  isLoadingPatientSearch: boolean;
  getPatientById: (id: number) => Promise<void>;
  getPatientByCpf: (cpf: string,) => Promise<void>;
  searchPatient: (filter: string, phone?:string) => Promise<void>;
  getPatientAppointments: (id: number, token?: string) => Promise<void>;
  appointments: any[];
  setAppointments: (data: any[]) => void;

  showAddPatientModal: boolean;
  setShowAddPatientModal: React.Dispatch<React.SetStateAction<boolean>>;

  getAppointmentById: (id: number) => Promise<void>;
  appointmentData: Appointment;
  setAppointmentData: React.Dispatch<React.SetStateAction<Appointment>>;
}

export const PatientContext = createContext<PatientProviderData>(
  {} as PatientProviderData
);

export const PatientProvider = ({ children }: PatientProviderProps, navigate: NavigateFunction) => {
  const [appointments, setAppointments] = useState<any[]>({} as any[]);
  const [patients, setPatients] = useState<PatientData[]>({} as PatientData[]);
  const [patientData, setPatientData] = useState<PatientData>({} as PatientData);
  const [patientLoading, setPatientLoading] = useState(false);
  const [isLoadingPaciente, setIsLoadingPaciente] = useState(false);
  const [isLoadingPatientSearch, setIsLoadingPatientSearch] = useState(false);

  const [showAddPatientModal, setShowAddPatientModal] = useState(false);
  const [appointmentData, setAppointmentData] = useState<Appointment>({} as Appointment);

  const getPatientById = async (
    id: number,
  ) => {
    setPatientLoading(true);
    setIsLoadingPaciente(true);
    try {
      const response = await api.get(`/Patient/${id}`);
      setPatientData(response.data);
      logger.log(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setPatientLoading(false);
      setIsLoadingPaciente(false);
    }
  };

  const getPatientByCpf = async (
    cpf: string,
  ) => {
    setPatientLoading(true);
    setIsLoadingPaciente(true);
    try {
      const response = await api.get(`/Patient/find/${cpf}`);
      // encontrou o paciente
      if (response.data && response.data.patients.length > 0) {
        setPatientData(mapPatientData(response.data.patients[0]));
      } else {
        setPatientData({ cpf: cpf });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPatientLoading(false);
      setIsLoadingPaciente(false);
    }
  };

  const searchPatient = async (
    filter: string,
    phone:string|undefined
  ) => {
    setIsLoadingPatientSearch(true);
    try {
      const response = await api.get(`/Patient/${phone == undefined ? 'find' : 'findByCellToo'}/${filter}`, {
        params: {
          forceCancel: true
        }
      });
      // encontrou o paciente
      if (response.data && response.data.patients) {
        {
          response.data.patients.map((patient: any, index: any) => {
            response.data.patients[index] = mapPatientData(patient);
          })
        }
        setPatients(response.data.patients);
      }
      setIsLoadingPatientSearch(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Busca cancelada');
      } else {
        setIsLoadingPatientSearch(false);
        console.error(error);
      }
    }
  };

  const getPatientAppointments = async (
    id: number,
    token?: string,
  ) => {
    setPatientLoading(true);
    try {
      if (token && token != "") {
        const response = await axios.get(`${API_URL}Attendance/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            paginate: false
          }
        });
        // encontrou o paciente
        if (response.data) {
          setAppointments(response.data);
        }
      } else {
        const response = await api.get(`${API_URL}Attendance/patientcommitments/${id}`, {
          params: {
            paginate: false
          }
        });
        // encontrou o paciente
        if (response.data) {
          setAppointments(response.data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPatientLoading(false);
    }
  };

  const editPatientData = async (data: PatientData) => {
    setPatientLoading(true);

    // edit or create
    if (patientData.id) {

      const body = {
        ...patientData,
        ...data,
        cpf: data.cpf ? data.cpf.replace(/\D/g, "") : patientData.cpf,
        birthDate: data.birthDate
          ? moment(data.birthDate, "DD/MM/YYYY").toISOString()
          : patientData.birthDate,
        dddMobilePhone: data.mobilePhone
          ? data.mobilePhone.slice(1, 3)
          : patientData.dddMobilePhone,
        mobilePhone: data.mobilePhone
          ? data.mobilePhone.replace("-", "").slice(5)
          : patientData.mobilePhone,
        scheduleHealthInsurance: data.healthInsurance?.label ?? ""
      };

      try {
        const response = await api.put(`/Patient/FromSchedule/${patientData.id}`, body);
        logger.log(response);

        toast.fire({
          icon: "success",
          title: "Dados atualizados com sucesso!",
        });

        setPatientData({
          ...body,
          finish: true,
        });

      } catch (error: any) {

        let message = "";
        if (error.response.status === 400) {
          message = (Object.values(error.response.data)[0] as string[])[0];
        } else {
          message = "Ocorreu um erro, tente novamente.";
        }

        toast.fire({
          icon: "error",
          title: message,
        });
      } finally {
        setPatientLoading(false);
        setIsLoadingPaciente(false);
      }
    } else {

      const body = {
        name: data.name,
        cpf: data.cpf ? data.cpf.replace(/\D/g, "") : patientData.cpf,
        sex: data.sex,
        email: data.email,
        birthDate: data.birthDate ? moment(data.birthDate, "DD/MM/YYYY").toISOString() : patientData.birthDate,
        mobilePhone: data.mobilePhone ? data.mobilePhone.replace("-", "").slice(5) : patientData.mobilePhone,
        dddMobilePhone: data.mobilePhone ? data.mobilePhone.slice(1, 3) : patientData.dddMobilePhone,
        termsOfUse: moment().toISOString(true),
        password: createRandomString(8),
        scheduleHealthInsurance: data.healthInsurance?.label ?? "",

        // TODO: ARRUMAR A API. ESTES CAMPOS ABAIXO ESTÃO OBRIGATÓRIOS.
        addressRoad: "n/a",
        addressNumber: "0",
        addressComplement: "n/a",
        addressDistrict: "n/a",
        addressCity: "n/a",
        addressState: "--",
        addressCEP: "n/a",
        // rg: "",
        // socialName: "",
        // maritalStatus: 'C'

      };

      try {
        const response = await api.post(`/Patient/register`, body);

        logger.log(response.data);

        if (response.status === 200 && response.data.userId) {
          toast.fire({
            icon: "success",
            title: "Paciente cadastrado com sucesso!",
          });

          setPatientData({
            ...patientData,
            id: response.data.userId,
            finish: true,
          });
        } else {
          console.error("Paciente cadastrado, mas não retornou o ID");
        }

      } catch (error: any) {

        let message = "";
        if (error.response.status === 400) {
          if (error.response.data.errors) {
            message = mapErrors(error.response.data.errors);
          } else if (error.response.data.title) {
            message = error.response.data.title;
          } else {
            message = (Object.values(error.response.data)[0] as string[])[0];
          }
        } else {
          message = "Ocorreu um erro, tente novamente.";
        }

        toast.fire({
          icon: "error",
          title: message,
        });
      } finally {
        setPatientLoading(false);
      }
    }
  };

  const mapErrors = function (errors: any) {
    let message: string = "";
    Object.keys(errors).forEach(function (key) {
      message += "<p>" + errors[key][0] + "</p>";
    });
    return message;
  }

  const mapPatientData = function (data: any) {
    const newData: PatientData = {
      id: data.idPaciente,
      name: data.nome,
      cpf: data.cpf,
      rg: data.numeroRg,
      sex: data.sexo,
      socialName: data.nomeSocial,
      birthDate: data.dataNasc,
      mobilePhone: data.telefoneCelular,
      dddMobilePhone: data.dddtelefoneCelular,
      addressCEP: data.cep,
      addressRoad: data.endereco,
      addressNumber: data.numero,
      addressComplement: data.complemento,
      addressDistrict: data.bairro,
      addressCity: data.cidade,
      addressState: data.estado,
      email: data.email,
    };
    return newData;
  }

  const createRandomString = function (size: number) {
    var randString = "";
    var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < size; i++) {
      randString += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return randString;
  }

  const getAppointmentById = async (id: number) => {
    setPatientLoading(true);
    try {
      const response = await api.get(`/Attendance/${id}`);
      logger.log(response.data);
      if (response.data) {
        setAppointmentData(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPatientLoading(false);
    }
  };

  return (
    <PatientContext.Provider
      value={{
        patients,
        setPatients,
        patientData,
        setPatientData,
        editPatientData,
        patientLoading,
        isLoadingPaciente,
        isLoadingPatientSearch,
        getPatientById,
        getPatientByCpf,
        searchPatient,
        getPatientAppointments,
        appointments,
        setAppointments,
        showAddPatientModal,
        setShowAddPatientModal,
        getAppointmentById,
        appointmentData,
        setAppointmentData,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

export const usePatient = () => useContext(PatientContext);

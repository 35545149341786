import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { useDarkMode } from "providers/DarkMode";
import { useWhatsapp } from "providers/Whatsapp";
import intagrationImg from "assets/integration.png";
import ModalWhatsapp from "pages/Integrations/components/ModalWhatsapp";
import ModalComponent from "components/ModalComponent";
import Swal from "sweetalert2";

const Integrations = () => {
  useEffect(() => { document.title = 'Intergrações | CRM - Conectadoc'; }, []);
  const { token } = useAuth();
  const { userData } = useUser();
  const navigate = useNavigate();

  const {
    showWhatsappModal,
    setShowWhatsappModal,
    isLoadingWhatsapp,
    setIsLoadingWhatsapp,
    conectedWhatsapp,
    setConectedWhatsapp,
    checkInstance,
    inicializeInstance,
    finishInstance
  } = useWhatsapp();

  const handleFinishWhastapp = () => {
    Swal.fire({
      title: "Tem certeza que deseja desconectar o WhatsApp?",
      icon: "question",
      iconColor: 'var(--bs-danger)',
      position: "center",
      showConfirmButton: true,
      confirmButtonText: "Sim, desconectar",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      reverseButtons: true,
      allowOutsideClick: false,
      focusConfirm: false,
      customClass: {
        confirmButton: "btn btn-outline-danger",
        cancelButton: "btn btn-light",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        finishInstance();
      }
    });
  }

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    checkInstance();
  }, [token, userData.id]);

  return (
    <>
      <ContainerAnimation className="content">
        <div id="top">
          <div className="container">
            <div className="row justify-content-md-between align-items-center gy-2 gx-2">
              <div className="col">
                <h1 className="mb-0 fw-semibold lh-1">Integrações</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-6">
              <p>Conecte com a API de terceiros para que seus boards fiquem ainda mais dinâmicos e automatizados.</p>
              <div className="d-flex flex-column gap-3">
                <div className="card p-3">
                  <div className="row g-3">
                    <div className="col col-auto position-relative align-self-center">
                      <div className={`${conectedWhatsapp ? 'on-pulse bg-success' : 'bg-danger'} p-1 rounded-circle`}></div>
                    </div>
                    <div className="col">
                      <div className="row g-3">
                        <div className="col col-auto position-relative align-self-center order-0">
                          <span className="bg-whatsapp px-2 fs-2 d-block rounded-circle lh-1 py-2 text-pure-white"><i className="uil uil-whatsapp"></i></span>
                        </div>
                        <div className="col-12 col-sm order-2 order-sm-1">
                          <b>WhatsApp</b>
                          <p className="text-muted small mb-0">Converse diretamente com seus pacientes sem perder nenhuma conversa, mesmo que seu telefone mude.</p>
                        </div>
                        <div className="col col-sm-auto align-self-center order-1 order-sm-2 text-end">
                          {conectedWhatsapp ? (
                            <a className="btn btn-sm btn-outline-danger rounded-pill" onClick={() => {
                              handleFinishWhastapp();
                            }}>Desconectar</a>

                          ) : (
                            <a className="btn btn-sm btn-outline-success rounded-pill" onClick={() => {
                              setShowWhatsappModal(true);
                            }}>Conectar</a>
                          )}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="card p-3">
                  <div className="row g-3">
                    <div className="col col-auto position-relative align-self-center">
                      <div className={`bg-danger p-1 rounded-circle`}></div>
                    </div>
                    <div className="col">
                      <div className="row g-3">
                        <div className="col col-auto position-relative align-self-center order-0">
                          <span className="bg-google px-2 fs-2 d-block rounded-circle lh-1 py-2 text-pure-white"><i className="uil uil-google"></i></span>
                        </div>
                        <div className="col-12 col-sm order-2 order-sm-1">
                          <b>Google Calendar</b>
                          <p className="text-muted small mb-0">Sincronize sua agenda com o google.</p>
                        </div>
                        <div className="col col-sm-auto align-self-center order-1 order-sm-2 text-end">
                          <a className="btn btn-sm btn-outline-secondary rounded-pill disabled" onClick={() => { }}>Em breve</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card p-3">
                  <div className="row g-3">
                    <div className="col col-auto position-relative align-self-center">
                      <div className={`bg-danger p-1 rounded-circle`}></div>
                    </div>
                    <div className="col">
                      <div className="row g-3">
                        <div className="col col-auto position-relative align-self-center order-0">
                          <span className="bg-instagram px-2 fs-2 d-block rounded-circle lh-1 py-2 text-pure-white"><i className="uil uil-instagram"></i></span>
                        </div>
                        <div className="col-12 col-sm order-2 order-sm-1">
                          <b>Instagram</b>
                          <p className="text-muted small mb-0">Converse diretamente com seus pacientes e transforme-os em novos leads.</p>
                        </div>
                        <div className="col col-sm-auto align-self-center order-1 order-sm-2 text-end">
                          <a className="btn btn-sm btn-outline-secondary rounded-pill disabled" onClick={() => { }}>Em breve</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-none d-lg-block col-5 col-xl-6 text-end align-self-end">
              <img src={intagrationImg} style={{ maxHeight: "70vh", maxWidth: "100%", transform: 'scaleX(-1)' }} />
            </div>
          </div>
        </div>
      </ContainerAnimation>
      {/* modal tag */}
      <ModalComponent
        size="lg"
        title={"Conectar WhatsApp"}
        show={showWhatsappModal}
        onHide={() => {
          setShowWhatsappModal(false);
        }}
      >
        <ModalWhatsapp onHide={() => {
          setShowWhatsappModal(false);
        }} />
      </ModalComponent>
    </>
  );
};

export default Integrations;

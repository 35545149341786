import { useState, useEffect } from "react";
// import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";
import "moment/locale/pt-br";
import moment from "moment";

import ContainerAnimation from "components/ContainerAnimation";
import { Board, BoardDefault, Id, Column, ColumnDefault, Task, ToTaskForward, Tag } from "types/board";
import { CircularProgress } from "@mui/material";
import { hsvaToHex, hexToRgba, rgbaToRgb, getContrastingColor } from '@uiw/color-convert';
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { useWhatsapp } from "providers/Whatsapp";
import { useTutorial } from "providers/Tutorial";
import { useBoard } from "providers/Board";
import { usePatient } from "providers/Patient";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2'
import logger from "utils/logger";
import { Console } from "console";
import { ch } from "@fullcalendar/core/internal-common";
import { useDarkMode } from "providers/DarkMode";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
);

const chartColors = [
  'rgba(255, 99, 132, 0.5)',  // Vermelho
  'rgba(54, 162, 235, 0.5)',  // Azul
  'rgba(255, 206, 86, 0.5)',  // Amarelo
  'rgba(75, 192, 192, 0.5)',  // Verde Água
  'rgba(153, 102, 255, 0.5)', // Roxo
  'rgba(255, 159, 64, 0.5)',  // Laranja
  'rgba(255, 140, 180, 0.5)', // Rosa
  'rgba(60, 179, 113, 0.5)',  // Verde Médio
  'rgba(123, 104, 238, 0.5)', // Azul Lavanda
  'rgba(244, 164, 96, 0.5)',  // Salmão
  'rgba(72, 209, 204, 0.5)',  // Turquesa
  'rgba(220, 20, 60, 0.5)',   // Carmesim
  'rgba(218, 165, 32, 0.5)',  // Dourado
  'rgba(0, 191, 255, 0.5)',   // Azul Céu
  'rgba(124, 252, 0, 0.5)',   // Verde Limão
  'rgba(240, 128, 128, 0.5)', // Rosa Claro
  'rgba(70, 130, 180, 0.5)',  // Azul Aço
  'rgba(255, 215, 0, 0.5)',   // Amarelo Ouro
  'rgba(186, 85, 211, 0.5)',  // Orquídea
  'rgba(95, 158, 160, 0.5)'   // Azul Acinzentado
];


const options = {
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  maintainAspectRatio: false
};

interface FunnelData {
  total: number;
  totals: number[];
  titles: string[];
  colors: string[];
  data: [number, number][];
}


interface Dataset {
  data: number[];
  qtd?: number[];
  backgroundColor: string | string[];
  borderColor?: string | string[];
  borderWidth?: number;
}

interface ChartData {
  labels: string[];
  datasets: Dataset[];
}

interface FunnelChartOptions {
  funnelData: FunnelData;
}

declare module 'chart.js' {
  interface PluginOptionsByType<TType> {
    funnelChart?: FunnelChartOptions;
  }
}


export default function Dashboard() {
  useEffect(() => { document.title = 'Dashboard | CRM - Conectadoc'; }, []);

  const navigate = useNavigate();

  const { token } = useAuth();
  const { userData } = useUser();
  const [funnelData, setFunnelData] = useState({} as FunnelData);
  const [tagsChartData, setTagsChartData] = useState({} as ChartData);
  const [originChartData, setOriginChartData] = useState({} as ChartData);
  const [totalVal, setTotalVal] = useState(0);
  const [totalBoardVal, setTotalBoardVal] = useState(0);
  const { darkMode } = useDarkMode();


  const updateTaskById = (data: any, taskIdToUpdate: Id, newTaskData: any) => {
    return data.map((patient: any) => ({
      ...patient,
      items: patient.items.map((item: any) =>
        item.task.id === taskIdToUpdate
          ? { ...item, task: { ...item.task, ...newTaskData } } // Atualiza apenas os dados da task com o id correspondente
          : item // Mantém os itens inalterados
      )
    }));
  };

  const {
    patients,
    patientLoading,
    setPatients,
    patientData,
    setPatientData,
    isLoadingPaciente,
    isLoadingPatientSearch,
    searchPatient,
    getPatientById,
    showAddPatientModal,
    setShowAddPatientModal
  } = usePatient();

  const {
    boards,
    setBoards,
    getBoards,
    boardData,
    isLoading,
    isLoadingBoards,
    getBoardById,
    getPatientsByBoards,
    patientsByBoard,
    getCardsByBoards,
    cardsByBoard,
    tags,
    leadOrigin,
  } = useBoard();


  const {
    conectedWhatsapp,
    checkInstance,
    getChats,
    chats,
  } = useWhatsapp();

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    if (userData && userData.id) {
      getBoards();
      // verifica o whats
      checkInstance();
    }
  }, [token, userData.id]);

  useEffect(() => {
    if (conectedWhatsapp) {
      getChats();
    }
  }, [conectedWhatsapp]);

  useEffect(() => {
    if (isLoadingBoards) {
      return;
    }
    if (boards.length > 0) {

      const fetchData = async () => {
        await Promise.all([
          getPatientsByBoards(boards),
          getCardsByBoards(boards)
        ]);
        setIsLoadingPage(false);
      };

      fetchData();

    }
  }, [boards]);

  const calculateColumnTotal = (tasks: Task[] = []) => {
    return tasks.reduce((sum, task) => {
      const value = Number(task.taskVal) || 0;
      return sum + value;
    }, 0);
  };

  const formatChartData = (columns: Column[]) => {
    var titles: string[] = [];
    var colors: string[] = [];
    var totals: number[] = [];
    var total: number = 0;
    var data: [number, number][] = [];
    if (columns) {
      columns.map((column, index: number) => {
        var totalColumn = calculateColumnTotal(column.tasks);
        if (column.tasks && totalColumn > 0) {
          var bgColor = chartColors[index];
          if (column.color && column.color != "" && column.color != "#FFFFFF") {
            var rgbColor = rgbaToRgb(hexToRgba(column.color));
            var bgColor = `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b},.5`;
          }

          titles.push(column.title);
          totals.push(totalColumn);
          colors.push(bgColor);
          total += totalColumn;
        }
      });

      totals.map((item) => {
        var percentage = (item / total) * 100;
        data.push([50 - (percentage / 2), 50 + (percentage / 2)]);
      });
    };
    setFunnelData({
      titles,
      totals,
      total,
      colors,
      data
    })
  }

  useEffect(() => {
    if (!boardData) {
      return;
    }
    formatChartData(boardData.columns);
    var tagsData: any = [];
    var originData: any = [];
    boardData?.columns?.map((column: Column) => {
      column.tasks?.map((task: Task) => {

        if (tags) {
          //seta a qtd de tags
          task.tags?.map((tag: Tag) => {
            var tagData = tags.find((t: any) => t.id === tag.id);
            if (tagData) {
              if (!tagsData.some((t: any) => t.id === tag.id)) {
                tagsData.push({ id: tag.id, total: task.taskVal ?? 0, qtd: 1, color: tagData.color, tag: tagData.tagName });
              } else {
                tagsData.map((t: any) => {
                  if (t.id === tag.id) {
                    t.total += task.taskVal ?? 0;
                    t.qtd += 1;
                  }
                });
              }
            }
          });
        }

        if (leadOrigin) {

          var originInfo = leadOrigin.find(o => o.value === task.taskOrigin);

          if (originInfo) {
            if (!originData.some((o: any) => o.value === task.taskOrigin)) {
              originData.push({ ...originInfo, qtd: 1 });
            } else {
              originData.map((o: any) => {
                if (o.value === task.taskOrigin) {
                  o.qtd += 1;
                }
              });
            }
          }
        }
      });

    });

    if (tags && tagsData.length > 0) {
      setTagsChartData({
        labels: tagsData.map((item: any) => item.tag),
        datasets: [
          {
            data: tagsData.map((item: any) => item.qtd),
            qtd: tagsData.map((item: any) => item.total),
            backgroundColor: tagsData.map((item: any, index: number) => {
              var bgColor = chartColors[index % chartColors.length];
              if (item.color) {
                var rgbColor = rgbaToRgb(hexToRgba(item.color));
                return `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b},.3)`;
              }
              return bgColor;
            }),
          }
        ]
      });
    } else {
      setTagsChartData({} as ChartData);

    }
    if (leadOrigin && originData.length > 0) {
      setOriginChartData({
        labels: originData.map((item: any) => item.label),
        datasets: [
          {
            data: originData.map((item: any) => item.qtd),
            backgroundColor: originData.map((item: any, index: number) => {
              var bgColor = chartColors[index % chartColors.length];
              if (item.color) {
                var rgbColor = rgbaToRgb(hexToRgba(item.color));
                return `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b},.3)`;
              }
              return bgColor;
            }),
            borderWidth: 1,
            borderColor: originData.map((item: any, index: number) => {
              const bgColor = item.color || chartColors[index % chartColors.length];
              const rgbColor = item.color ? rgbaToRgb(hexToRgba(item.color)) : rgbaToRgb(hexToRgba(bgColor));
              const yiq = (rgbColor.r * 299 + rgbColor.g * 587 + rgbColor.b * 114) / 1000;
              return yiq < 128 ? `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b},0)` : `rgba(0,0,0,.1)`;
            })
          }
        ]
      });
    } else {
      setOriginChartData({} as ChartData);
    }

  }, [darkMode, boardData, tags, leadOrigin]);

  useEffect(() => {
    if (isLoadingBoards) {
      return;
    }
    if (boards.length > 0) {
      logger.log(boards);
      if (selectedNavTab != "" && boards.some(item => item.id === selectedNavTab)) {
        logger.log("Get tab Board: " + selectedNavTab);
        getBoardById(selectedNavTab);
      } else {
        logger.log("Get first Board: " + boards.sort((a, b) => Number(b.isDefault) - Number(a.isDefault))[0].id);
        getBoardById(boards.sort((a, b) => Number(b.isDefault) - Number(a.isDefault))[0].id);
      }
      return;
    }
  }, [boards]);

  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const handleTabClick = (id: Id) => {
    logger.log(id);
    getBoardById(id);
    sessionStorage.setItem(`crm-board`, id.toString());
    setSelectedNavTab(id);
  };

  const [selectedNavTab, setSelectedNavTab] = useState(sessionStorage.getItem(`crm-board`) ?? "" as Id);

  useEffect(() => {
    var totalGeral = 0;
    var totalBoard = 0;
    if (cardsByBoard) {
      console.log(cardsByBoard);
      cardsByBoard.map((item: any) => {
        // seta o valor no otal do board selecionado
        console.log(item.board.id, selectedNavTab);
        if((selectedNavTab === item.board.id) || (selectedNavTab == "" &&  boards[0].id === item.board.id )){
          totalBoard += item.task.taskVal;
        }
        // seta o valor total
        totalGeral += item.task.taskVal;
      });
      setTotalVal(totalGeral);
      setTotalBoardVal(totalBoard);
    }
  }, [cardsByBoard, selectedNavTab]);
  return (
    <>
      <ContainerAnimation className="content">
        <div id="top" className="d-print-none">
          <div className="container">
            <div className="row justify-content-md-between align-items-center gy-2 gx-2">
              <div className="col">
                <h1 className="mb-0 fw-semibold lh-1 d-inline-block me-2">Dashboard</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row g-3 mb-3">
            <div className="col-12 col-sm-6 col-lg-4">
              <div className={`${isLoadingPage ? "placeholder-wave" : ""} card card-hover p-3 h-100`}>
                <Link to="/boards" className="stretched-link"></Link>
                <div className={`row g-2 ${isLoadingPage ? "opacity-0" : ""}`}>
                  <div className="col">
                    <p className="fw-medium text-uppercase opacity-50 small mb-2">Boards</p>
                    <h2 className="fw-bold lh-1 mb-0 text-nowrap">{boards.length}</h2>
                  </div>
                  <div className="col col-auto">
                    <span className="text-bg-primary p-3 fs-4 d-block rounded-circle lh-1">
                      <i className="uil uil-grids"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className={`${isLoadingPage ? "placeholder-wave" : ""} card card-hover p-3 h-100`}>
                <Link to="/boards" className="stretched-link"></Link>
                <div className={`row g-2 ${isLoadingPage ? "opacity-0" : ""}`}>
                  <div className="col">
                    <p className="fw-medium text-uppercase opacity-50 small mb-2">Cards</p>
                    <h2 className="fw-bold lh-1 mb-0 text-nowrap">{cardsByBoard.length}</h2>
                  </div>
                  <div className="col col-auto">
                    <span className="text-bg-primary p-3 fs-4 d-block rounded-circle lh-1">
                      <i className="uil uil-postcard"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className={`${isLoadingPage ? "placeholder-wave" : ""} card card-hover p-3 h-100`}>
                <Link to="/pacientes" className="stretched-link"></Link>
                <div className={`row g-2 ${isLoadingPage ? "opacity-0" : ""}`}>
                  <div className="col">
                    <p className="fw-medium text-uppercase opacity-50 small mb-2">Pacientes</p>
                    <h2 className="fw-bold lh-1 mb-0 text-nowrap">{patientsByBoard.length}</h2>
                  </div>
                  <div className="col col-auto">
                    <span className="text-bg-primary p-3 fs-4 d-block rounded-circle lh-1">
                      <i className="uil uil-chat-bubble-user"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className={`${isLoadingPage ? "placeholder-wave" : ""} card card-hover p-3 h-100`}>
                <Link to="/boards" className="stretched-link"></Link>
                <div className={`row g-2 ${isLoadingPage ? "opacity-0" : ""}`}>
                  <div className="col">
                    <p className="fw-medium text-uppercase opacity-50 small mb-2">Tasks</p>
                    <h2 className="fw-bold lh-1 mb-0 text-nowrap">{cardsByBoard.filter((item: any) => (item.task.type !== 0)).length}</h2>
                  </div>
                  <div className="col col-auto">
                    <span className="text-bg-primary p-3 fs-4 d-block rounded-circle lh-1">
                      <i className="uil uil-check-square"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className={`${isLoadingPage ? "placeholder-wave" : ""} card card-hover p-3 h-100`}>
                <Link to="/conversas" className="stretched-link"></Link>
                <div className={`row g-2 ${isLoadingPage ? "opacity-0" : ""}`}>
                  <div className="col">
                    <p className="fw-medium text-uppercase small mb-2">
                      <span className={`${conectedWhatsapp ? 'on-pulse bg-success' : 'bg-danger'} p-1 rounded-circle d-inline-block me-1`}></span>
                      <span className="opacity-50">Conversas</span>
                    </p>
                    {conectedWhatsapp ? (
                      <h2 className="fw-bold lh-1 mb-0 text-nowrap">{chats.length}</h2>
                    ) : (
                      <small className="text-danger">desconectado</small>
                    )}
                  </div>
                  <div className="col col-auto">
                    <span className="text-bg-primary p-3 fs-4 d-block rounded-circle lh-1">
                      <i className="uil uil-comments-alt"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className={`${isLoadingPage ? "placeholder-wave" : ""} card p-3`}>
                <div className={`row g-2 ${isLoadingPage ? "opacity-0" : ""}`}>
                  <div className="col">
                    <p className="fw-medium text-uppercase opacity-50 small mb-2">Valor Total</p>
                    <h2 className="fw-bold lh-1 mb-0 text-nowrap">
                      <span className="fw-normal opacity-50 fs-6">R$ </span>
                      {new Intl.NumberFormat('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(totalVal)}
                    </h2>
                  </div>
                  <div className="col col-auto">
                    <span className="text-bg-primary p-3 fs-4 d-block rounded-circle lh-1">
                      <i className="uil uil-comments-alt"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <ul className="nav nav-tabs position-relative scrollable pt-3 border-0" style={{ marginBottom: -1, zIndex: 1 }}>
            {(boards && boards.length > 0) && (<>
              {boards.sort((a, b) => Number(b.isDefault) - Number(a.isDefault)).map((b, index) => (
                <li key={b.id} className="nav-item">
                  <button
                    type="button"
                    className={`nav-link link-primary ${(selectedNavTab === (b.id).toString()) ? "active" : (selectedNavTab == "" && index === 0) ? "active" : ""
                      }`}
                    onClick={() => { handleTabClick(b.id) }}
                  >
                    {b.isDefault && (<i className="uil uil-star text-warning me-2 small"></i>)}{b.name}
                  </button>
                </li>
              ))}
            </>)}
          </ul>
        </div>
        <div className="border-top bg-white py-5">
          <div className="container">
            <div className="row g-3">
              <div className="col-12 col-md-6">
                <div className={`${isLoadingPage || isLoadingBoards ? "placeholder-wave" : ""} card h-100`}>
                  <div className={`${isLoadingPage || isLoadingBoards ? "opacity-0" : ""}`}>
                    <h5 className="px-3 pt-3 mb-0">Valor | <span className="fw-normal opacity-50 fs-6">R$ </span>
                      {new Intl.NumberFormat('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(totalBoardVal)} <span className="fw-normal opacity-50 fs-6"><small>(no board selecionado)</small></span></h5>

                    <div className="card-body" style={{ height: 300, overflowY: "auto" }}>
                      <div style={{ height: funnelData.data ? Math.max(268, funnelData.data?.length * 45) : 268 }}>
                        {(!isLoadingPage || !isLoadingBoards) && (
                          funnelData && funnelData.data?.length ? (
                            <Bar
                              plugins={[{
                                id: 'funnelChart',
                                afterDatasetsDraw(chart: any, args: any, pluginOptions: any) {
                                  const { ctx, data, chartArea: { top, left, bottom, right, width, height }, scales: { x, y } } = chart;
                                  const { funnelData } = pluginOptions;
                                  const TOTAL = funnelData.total;


                                  for (let i = 0; i < chart.getDatasetMeta(0).data.length - 1; i++) {
                                    // desenha o triangulo abaixo da barra
                                    if (chart.getDatasetMeta(0).data[i].width > 0) {
                                      ctx.save();
                                      ctx.beginPath();
                                      ctx.fillStyle = chart.getDatasetMeta(0).data[i].options.backgroundColor;
                                      // ctx.strokeStyle = chart.getDatasetMeta(0).data[i].options.borderColor;
                                      ctx.moveTo(chart.getDatasetMeta(0).data[i].base, chart.getDatasetMeta(0).data[i].y + (chart.getDatasetMeta(0).data[i].height / 2));
                                      ctx.lineTo(chart.getDatasetMeta(0).data[i].x, chart.getDatasetMeta(0).data[i].y + (chart.getDatasetMeta(0).data[i].height / 2));
                                      ctx.lineTo(chart.getDatasetMeta(0).data[i + 1].x, chart.getDatasetMeta(0).data[i + 1].y - (chart.getDatasetMeta(0).data[i + 1].height / 2));
                                      ctx.lineTo(chart.getDatasetMeta(0).data[i + 1].base, chart.getDatasetMeta(0).data[i + 1].y - (chart.getDatasetMeta(0).data[i + 1].height / 2));
                                      ctx.closePath();
                                      // ctx.stroke();
                                      ctx.fill();
                                      ctx.restore();
                                    }
                                  }

                                  // exibe os labels na barra
                                  for (let i = 0; i < chart.getDatasetMeta(0).data.length; i++) {
                                    const dataPoint = chart.getDatasetMeta(0).data[i];
                                    const dataPointPercentage = data.datasets[0].data[i][1] - data.datasets[0].data[i][0];
                                    const qtd = funnelData.totals[i];
                                    const title = funnelData.titles[i];

                                    const formattedValue = new Intl.NumberFormat('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL'
                                    }).format(qtd);

                                    // Configurações do estilo para o valor em negrito
                                    ctx.textAlign = 'center';
                                    ctx.textBaseline = 'middle';
                                    ctx.font = 'bold 15px sans-serif'; // Negrito
                                    ctx.fillStyle = darkMode ? '#FFF' : '#000'; // Cor para o valor em negrito

                                    const textX = (left + right) / 2; // Centraliza horizontalmente
                                    const textY = dataPoint.y; // Usa a posição y do item

                                    // Desenha o nome
                                    ctx.fillText(`${title}`, textX, textY - 10);

                                    // Desenha o valor em negrito
                                    ctx.fillText(`${formattedValue}`, textX - ctx.measureText(`${formattedValue}`).width / 2 - 2.5, textY + 10);

                                    // Configurações do estilo para o percentual
                                    ctx.font = 'normal 12px sans-serif'; // Texto normal
                                    ctx.fillStyle = darkMode ? 'rgba(255, 255, 255, .5)' : 'rgba(0, 0, 0, .5)'; // Cor para o valor em negrito

                                    // Desenha o percentual logo após o valor
                                    ctx.fillText(` (${dataPointPercentage.toFixed(2)}%)`, textX + ctx.measureText(`${formattedValue}`).width / 2 + 2.5, textY + 10);
                                  }
                                }
                              }]}
                              options={{
                                indexAxis: 'y',
                                scales: {
                                  x: {
                                    min: 0,
                                    max: 100,
                                    border: {
                                      display: false
                                    },
                                    grid: {
                                      display: false
                                    },
                                    ticks: {
                                      display: false
                                    }
                                  },
                                  y: {
                                    border: {
                                      display: false
                                    },
                                    beginAtZero: true,
                                    grid: {
                                      display: false
                                    },
                                    ticks: {
                                      display: false
                                    }
                                  }
                                },
                                plugins: {
                                  legend: { display: false },
                                  tooltip: {
                                    enabled: true,
                                    callbacks: {
                                      label: function (context) {
                                        const label = context.label || '';
                                        const dataIndex = context.dataIndex;
                                        const dataset: any = context.dataset;

                                        const item = funnelData.totals[dataIndex];
                                        const percentage = (item / funnelData.total) * 100;

                                        const formattedValue = new Intl.NumberFormat('pt-BR', {
                                          style: 'currency',
                                          currency: 'BRL'
                                        }).format(item);

                                        return `${formattedValue} (${percentage.toFixed(2)}%)`;
                                      },
                                    },
                                  },
                                  funnelChart: {
                                    funnelData: funnelData
                                  }
                                },
                                maintainAspectRatio: false
                              }}
                              data={{
                                labels: funnelData.titles,
                                datasets: [
                                  {
                                    data: funnelData.data,
                                    borderWidth: 0,
                                    backgroundColor: funnelData.colors
                                  },
                                ],
                              }}
                            />
                          ) : (
                            <p className="h-100 d-flex align-items-center justify-content-center">sem dados para exibir</p>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className={`${isLoadingPage || isLoadingBoards ? "placeholder-wave" : ""} card`}>
                  <div className={`${isLoadingPage || isLoadingBoards ? "opacity-0" : ""}`}>
                    <h5 className="px-3 pt-3 mb-0">Tags</h5>
                    <div className="card-body" style={{ height: 300, overflowY: "auto" }}>
                      <div style={{ height: tagsChartData.datasets ? Math.max(268, tagsChartData.datasets?.[0].data.length * 30) : 268 }}>
                        {(!isLoadingPage || !isLoadingBoards) && (
                          tagsChartData.datasets ? (
                            <Bar
                              plugins={[{
                                id: 'tagsChart',
                                afterDatasetsDraw(chart: any) {
                                  const { ctx, chartArea: { right }, scales: { x } } = chart;
                                  chart.getDatasetMeta(0).data.forEach((dataPoint: any, i: number) => {
                                    const barColor = chart.data.datasets[0].backgroundColor[i];
                                    // const formattedValue = new Intl.NumberFormat('pt-BR', {
                                    //   style: 'currency',
                                    //   currency: 'BRL'
                                    // }).format(dataPoint.$context.raw);

                                    ctx.textAlign = 'left';
                                    ctx.textBaseline = 'middle';
                                    ctx.font = 'bold 15px sans-serif';
                                    ctx.fillStyle = darkMode ? "#FFF" : "#000";

                                    const barX = x.getPixelForValue(dataPoint.$context.raw);
                                    const textWidth = ctx.measureText(dataPoint.$context.raw).width;
                                    const padding = 10;

                                    let textX;

                                    // Verifica se a barra é maior que o texto
                                    if (barX + padding + textWidth <= right - padding) {
                                      // Se a barra for larga o suficiente, desenha o texto dentro da barra, à direita
                                      textX = barX + padding;
                                    } else {
                                      // Caso contrário, desenha fora da barra, mas limitado à borda do gráfico
                                      textX = barX - textWidth - padding;
                                    }

                                    const textY = dataPoint.y;

                                    ctx.fillText(dataPoint.$context.raw, textX, textY);
                                  });
                                },
                              }]}
                              options={{
                                indexAxis: 'y',
                                elements: {
                                  bar: {
                                    borderWidth: 1,
                                  },
                                },
                                responsive: true,
                                plugins: {
                                  legend: {
                                    display: false,
                                  },
                                  tooltip: {
                                    enabled: true,
                                    callbacks: {
                                      label: function (context) {
                                        const label = context.label || '';
                                        const index = context.dataIndex;
                                        const qtd = tagsChartData.datasets[0].data?.[index] ?? 0;
                                        const valor = tagsChartData.datasets[0].qtd?.[index] ?? 0;

                                        const formattedValue = new Intl.NumberFormat('pt-BR', {
                                          style: 'currency',
                                          currency: 'BRL'
                                        }).format(valor);

                                        return `Utilizada ${qtd} vezes (${formattedValue})`;
                                      },
                                    },
                                  },
                                  title: {
                                    display: false,
                                  }
                                },
                                scales: {
                                  x: {
                                    ticks: {
                                      callback: function (value, index, ticks) {
                                        return `${value.toLocaleString('pt-BR')}`;
                                      }
                                    }
                                  }
                                },
                                maintainAspectRatio: false
                              }}
                              data={tagsChartData}
                            />
                          ) : (
                            <p className="h-100 d-flex align-items-center justify-content-center">sem dados para exibir</p>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className={`${isLoadingPage || isLoadingBoards ? "placeholder-wave" : ""} card h-100`}>
                  <div className={`${isLoadingPage || isLoadingBoards ? "opacity-0" : ""}`}>
                    <h5 className="px-3 pt-3 mb-0">Origem</h5>
                    <div className="card-body" style={{ height: 300 }}>
                      {(!isLoadingPage || !isLoadingBoards) && (
                        originChartData.datasets ? (
                          <Pie
                            plugins={[{
                              id: 'tagsChart',
                              beforeDatasetsDraw(chart: any) {
                                const { ctx, chartArea: { right }, scales: { x } } = chart;
                                const dataset = chart.data.datasets[0];
                                const total = dataset.data.reduce((sum: number, currentValue: number) => sum + currentValue, 0);
                                const scale = 0.8;

                                ctx.save();

                                if(!chart.originalOuterRadius){
                                  chart.originalOuterRadius = chart.getDatasetMeta(0).data[0].outerRadius;
                                }

                                chart.getDatasetMeta(0).data.forEach((element: any, i: number) => {
                                  element.outerRadius = chart.originalOuterRadius * scale;
                                });
                              },
                              afterDatasetsDraw(chart: any) {
                                const { ctx, chartArea: { right }, scales: { x } } = chart;
                                const dataset = chart.data.datasets[0];
                                const total = dataset.data.reduce((sum: number, currentValue: number) => sum + currentValue, 0);

                                chart.getDatasetMeta(0).data.forEach((element: any, i: number) => {
                                  const value = dataset.data[i];
                                  const percentage = ((value / total) * 100).toFixed(2);
                                  const label = `${value}`;
                                  const barColor = chart.data.datasets[0].backgroundColor[i];

                                  // Define o ponto de texto
                                  const position = element.tooltipPosition();
                                  ctx.fillStyle = darkMode ? "#FFF" : "#000";
                                  ctx.font = 'bold 14px sans-serif';
                                  ctx.textAlign = 'center';
                                  ctx.textBaseline = 'middle';
                                  ctx.fillText(label, position.x, position.y);

                                  // Cálculo do ângulo central da fatia
                                  const startAngle = element.startAngle;
                                  const endAngle = element.endAngle;
                                  const midAngle = (startAngle + endAngle) / 2;
                                  const radius = element.outerRadius + 20; // Distância da borda ajustável
                                  const x = element.x + Math.cos(midAngle) * radius;
                                  const y = element.y + Math.sin(midAngle) * radius;
                                  ctx.font = 'normal 12px sans-serif'; // Texto normal
                                  ctx.fillStyle = 'rgba(102, 102, 102, 1)'; // Cor para o percentual
                                  ctx.fillText(`${percentage}%`, x, y);
                                });
                              },
                            }]}
                            options={
                              {
                                ...options,
                                layout: {
                                  padding: {
                                    top: 0,
                                    bottom: 0,
                                    left: 30,
                                    right: 30
                                  }
                                },
                                plugins: {
                                  ...options.plugins,
                                  legend: {
                                    display: true,
                                    position: 'bottom',
                                    labels: {
                                      boxWidth: 10,   // Largura do quadrado
                                      boxHeight: 10,  // Altura (opcional, no Chart.js 4+)
                                      usePointStyle: false,  // Usa quadrado em vez de círculo
                                      padding: 15,   // Espaçamento entre as legendas
                                      font: {
                                        size: 12
                                      }
                                    },
                                  },
                                  tooltip: {
                                    callbacks: {
                                      label: function (tooltipItem) {
                                        const dataset = tooltipItem.dataset;
                                        const total = dataset.data.reduce((sum, currentValue) => sum + currentValue, 0);
                                        const currentValue = dataset.data[tooltipItem.dataIndex];
                                        const percentage = ((currentValue / total) * 100).toFixed(2);
                                        return `${currentValue} (${percentage}%)`;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            data={originChartData}
                          />
                        ) : (
                          <p className="h-100 d-flex align-items-center justify-content-center">sem dados para exibir</p>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className={`${isLoadingPage || isLoadingBoards ? "placeholder-wave" : "blur-content"} card h-100`}>
                  <div className={`${isLoadingPage || isLoadingBoards ? "opacity-0" : ""}`}>
                    <h5 className="px-3 pt-3 mb-0">Gráfico de Linha</h5>
                    <div className="card-body" style={{ height: 300 }}>
                      <Line
                        options={options}
                        data={{
                          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                          datasets: [
                            {
                              label: 'Dataset 1',
                              data: [500, 100, -200, 300, 700, -500, 400],
                              borderColor: 'rgb(255, 99, 132)',
                              backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                            {
                              label: 'Dataset 2',
                              data: [-100, 300, 200, 400, -300, 500, -100],
                              borderColor: 'rgb(53, 162, 235)',
                              backgroundColor: 'rgba(53, 162, 235, 0.5)',
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContainerAnimation>
    </>
  );
}
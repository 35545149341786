import { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router';
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";
import Footer from "components/Footer";
import { useUser } from "providers/User";
import { useBoard } from "providers/Board";
import { useDarkMode } from "providers/DarkMode";
import { usePatient } from "providers/Patient";
import logger from "utils/logger";
import Aside from "components/Aside";
import user from "assets/user.png";
import intagrationImg from "assets/integration.png";
import moment from "moment";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import ModalComponent from 'components/ModalComponent';
import ModalTask from "pages/Boards/components/ModalTask";
import ModalPatient from "pages/Boards/components/ModalPatient";
import ModalForward from "pages/Boards/components/ModalForward";
import ModalColumn from "pages/Boards/components/ModalColumn";
import ModalTag from "pages/Boards/components/ModalTag";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from '@fullcalendar/core/locales/pt-br';
import "./utils/calendar.css";
import { EventInput, EventSourceFuncArg } from "@fullcalendar/core";
import SelectTag from "components/SelectTag";
import { Board, BoardDefault, Id, Column, ColumnDefault, Task, ToTaskForward } from "types/board";

const Calendar = () => {
  useEffect(() => { document.title = 'Calendário | CRM - Conectadoc'; }, []);

  const { token } = useAuth();
  const { userData } = useUser();
  const navigate = useNavigate();
  const { darkMode } = useDarkMode();
  const { patients,
    patientLoading,
    setPatients,
    patientData,
    setPatientData,
    isLoadingPaciente,
    isLoadingPatientSearch,
    searchPatient,
    getPatientById,
    showAddPatientModal,
    setShowAddPatientModal
  } = usePatient();

  const {
    boards,
    setBoards,
    getBoards,
    boardData,
    isLoading,
    isLoadingBoards,
    setBoardData,
    tasksData,
    setTasksData,
    editTaskData,
    setEditTaskData,
    editColumnData,
    setEditColumnData,
    columnsData,
    setColumnsData,
    taskName,
    setTaskName,
    taskDescription,
    setTaskDescription,
    taskDate,
    setTaskDate,
    taskMoveTo,
    setTaskMoveTo,
    taskType,
    setTaskType,
    taskId,
    setTaskId,
    showAddTaskModal,
    setShowAddTaskModal,
    showForwardPatientModal,
    setShowForwardPatientModal,
    createBoard,
    updateBoard,
    deleteBoard,
    getBoardById,
    getTasksByBoard,
    getPatientsByBoards,
    patientsByBoard,
    setPatientsByBoard,
    addColumnToBoard,
    editColumnFromBoard,
    addTaskToBoardColumn,
    getTasksByColumn,
    delColumnFromBoard,
    delTaskFromBoardColumn,
    editTaskFromBoardColumn,
    moveTaskBetweenBoardColumn,
    autoUpdate,
    setAutoUpdate,
    stopAutoUpdate,
    setStopAutoUpdate,
    checkDuplicatedTasks,
    keepDuplicates,
    setKeepDuplicates,
    showDuplicates,
    setShowDuplicates,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    searchTerm,
    setSearchTerm,
    filteredTasks,
    setFilteredTasks,
    handleSearchChange,
    updateTasksOrder,
    handleDeleteTask,
    handleTaskModal,
    columnAddIndex,
    setColumnAddIndex,
    columnName,
    setColumnName,
    columnId,
    setColumnId,
    showAddColumnModal,
    setShowAddColumnModal,
    hex,
    setHex,
    createColumn,
    deleteColumn,
    updateColumn,
    showModalTag,
    setShowModalTag,
    clearData,
    updateTask,
    getCardsByBoards,
    cardsByBoard,
    setCardsByBoard,
  } = useBoard();

  const [events, setEvents] = useState<EventInput[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<EventInput[]>([]);


  const [filter, setFilter] = useState({
    board: '',
  });

  // Função para lidar com mudanças no filtro
  const handleFilterChange = (e: any) => {
    const { value } = e;
    setFilter({ ...filter, ['board']: value });
  };

  const handleDateClick = (arg: any) => {
    logger.log(arg.dateStr)
  }

  const handleEventClick = (info: any) => {
    const card = info.event.extendedProps;
    handleOpenModalTask(card);
  }

  const handleOpenModalTask = (task: any) => {
    handleTaskModal(true, task.task as Task);
    setColumnsData(task.board.columns as Column[] ?? []);
    setBoardData(task.board as Board);
    setTasksData(task.column.tasks);
  }

  const updateCardByTaskId = (data: any, taskIdToUpdate: Id, newTaskData: any) => {
    return data.map((item: any) =>
      item.task.id === taskIdToUpdate
        ? { ...item, task: { ...item.task, ...newTaskData } } // Atualiza apenas os dados da task com o id correspondente
        : item // Mantém os itens inalterados
    );
  };

  // atualiza a task recém editada
  useEffect(() => {
    if (tasksData.length > 0 && tasksData[0].id) {
      const newCards = updateCardByTaskId(cardsByBoard, tasksData[0].id, tasksData[0]);
      setCardsByBoard(newCards);
    }
  }, [tasksData]);


  const renderEventContent = (eventInfo: EventInput) => {
    return (
      <>
        <div className="fc-daygrid-event-dot" style={{ backgroundColor: eventInfo.backgroundColor, borderColor: eventInfo.backgroundColor }}></div>
        {eventInfo.timeText != "00" && (
          <div className="fc-event-time"><small>{eventInfo.timeText}</small></div>
        )}
        <OverlayTrigger
          placement="top"
          container={document.body}
          overlay={<Tooltip>{eventInfo.event.title}</Tooltip>}
        >
          <div className="fc-event-title text-truncate small">{eventInfo.event.title}</div>
        </OverlayTrigger >

      </>
    )
  }

  const handleEventClassNames = (eventInfo: EventInput) => {
    // Adiciona classes CSS diferentes com base no tipo do evento
    if (eventInfo.event.extendedProps.column === 'meeting') {
      return ['event-meeting']; // Classe CSS específica para reuniões
    }
    return ['text-body']; // Sem classe adicional
  };

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    if (userData && userData.id) {
      getBoards();
    }
  }, [token, userData.id]);

  useEffect(() => {
    return () => {
      setBoards([]); // Zera os boards
      setBoardData({} as Board); // Zera o board atual
      setTasksData([]); // Zera as tasks
      setColumnsData([]); // Zera as colunas
    };
  }, []);

  useEffect(() => {
    if (boards.length > 0) {
      getCardsByBoards(boards);
    }
  }, [boards]);

  useEffect(() => {
    var newEvents: EventInput[] = [];
    cardsByBoard.map((card) => {
      if (card.task?.appointment) {
        newEvents.push({
          title: card.task?.title,
          date: moment(card.task?.appointment.date).format("YYYY-MM-DD"),
          allDay: false,
          start: moment(card.task?.appointment.date).format(),
          end: moment(card.task?.appointment.date).format(),
          backgroundColor: card.column.color,
          borderColor: card.column.color,
          textColor: (darkMode ? 'var(--bs-pure-white)' : 'var(--bs-body-color)'),
          extendedProps: card
        });
      }
    });
    if (newEvents) {
      setEvents(newEvents);
    }
  }, [cardsByBoard]);

  const {
    control
  } = useForm();

  useEffect(() => {
    var newEvents = events.filter(event => {
      return (filter.board === '' || event.extendedProps?.board.id?.includes(filter.board));
    });
    setFilteredEvents(newEvents);
  }, [events, filter]);
  return (
    <>
      <ContainerAnimation className="content">
        <div id="top">
          <div className="container">
            <div className="row justify-content-md-between align-items-center gy-2 gx-2">
              <div className="col">
                <h1 className="mb-0 fw-semibold lh-1">Calendário</h1>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                <div className="mt-sm-n3">
                  <span className="text-muted small">Exibir:</span>
                  <SelectTag
                    control={control}
                    name="board"
                    defaultValue={
                      filter.board == "" ? { value: "", label: "Todos os Boards" } : filter.board
                    }
                    placeholder="Selecione um board"
                    options={[
                      { value: "", label: "Todos os Boards" },
                      ...boards.map(board => {
                        return { value: board.id, label: board.name }
                      })
                    ]}
                    closeMenuOnSelect={true}
                    handleChange={handleFilterChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {isLoadingBoards ? (
            <></>
          ) : (
            <FullCalendar
              locale={ptLocale}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              events={filteredEvents}
              height={'auto'}
              headerToolbar={{
                left: "prev,today,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay"
              }}
              dateClick={handleDateClick}
              // select={handleDateSelect}
              eventContent={renderEventContent} // custom render function
              eventClassNames={handleEventClassNames}
              eventClick={handleEventClick}
            // eventsSet={() => handleEvents(events)}
            // eventDrop={handleEventDrop}
            // eventResize={handleEventResize}
            // eventAdd={(e) => {
            //   console.log("eventAdd", e);
            // }}
            // eventChange={(e) => {
            //   console.log("eventChange", e);
            // }}
            // eventRemove={(e) => {
            //   console.log("eventRemove", e);
            // }}
            />
          )}
        </div>
      </ContainerAnimation>

      {/* modal task */}
      <ModalTask />

    </>
  );
};

export default Calendar;

import { Dispatch, SetStateAction, useState, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonGroup, Modal, OverlayTrigger, ToggleButton, Tooltip } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import Button from "components/Button";
import Input from "components/Input";
import { hsvaToHex, hexToRgba, rgbaToRgb, getContrastingColor } from '@uiw/color-convert';
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { useBoard } from "providers/Board";
import Swal from "sweetalert2";
import { api } from "services";
import { toast } from "utils/toast";
import { TextareaAutosize } from "@mui/material";
import { Swatch } from "@uiw/react-color";
import { isDev } from 'utils/isDev';

interface ModalColumnProps {
  onHide?: () => void;
}

export interface createColumn {
  color: string;
  columnName: string;
  agendamento: boolean;
  agendamentoConecta: boolean;
}

const ModalColumn = ({
  onHide,
}: ModalColumnProps) => {
  const { token } = useAuth();
  const { userData } = useUser();
  const {
    editColumnData,
    setEditColumnData,
    columnName,
    setColumnName,
    columnId,
    setColumnId,
    showAddColumnModal,
    setShowAddColumnModal,
    columnsData,
    hex,
    setHex,
    createColumn,
    deleteColumn,
    updateColumn,
    boardData,
    getBoardById,
    columnAddIndex
  } = useBoard();


  const schema = yup.object().shape({
    color: yup.string().required("*Campo obrigatório"),
    columnName: yup.string().trim().required("*Campo obrigatório"),
  });

  const handleChangeColumType = (e: React.ChangeEvent<HTMLInputElement>) => {
    // debugger;
    var val = Number(e.currentTarget.value);

    if (!e.currentTarget.checked) {
      if (val === 4) {
        val = 3;
      } else {
        val = 0;
      }
    }

    // setValue('type', val);
    // trigger('type');
  };

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    control,
    getValues,
    reset,
    watch,
    trigger,
    formState: { errors },
  } = useForm<createColumn>({
    resolver: yupResolver(schema),
    defaultValues: {
      color: editColumnData.color ?? "#ffffff",
      columnName: editColumnData.title ?? "",
      agendamento: ([3, 4].includes(editColumnData.type) ? true : false),
      agendamentoConecta: (editColumnData.type === 4 ? true : false)
    },
  });

  // const selectedType = watch('type');
  const agendamento = watch('agendamento');
  useEffect(() => {
    if (!agendamento) {
      setValue('agendamentoConecta', false);
    }
  }, [agendamento, setValue]);

  const Point = (props: { color?: string; checked?: boolean }) => {
    if (!props.checked) return null;
    return (
      <div
        style={{
          height: 5,
          width: 5,
          borderRadius: '50%',
          backgroundColor: getContrastingColor(props.color!),
        }}
      />
    );
  }

  const onSubmit = (data: createColumn) => {
    // Lógica de envio
    console.log('Form data', data);

    const colConectaKey = columnsData.findIndex(column => column.type === 4);

    const save = ( refreshColums:boolean = false) => {
      if (editColumnData && editColumnData.id) {
        const newColumn = {
          ...editColumnData,
          color: data.color,
          title: data.columnName,
          type: ([1,2].includes(editColumnData.type) ? editColumnData.type : (data.agendamentoConecta ? 4 : data.agendamento ? 3 : 0)) // 0 = normal, 1 = start, 2 = end, 3 = appointment
        }
        if(!refreshColums){
          updateColumn(editColumnData.id, newColumn);
        }else{
          updateColumn(editColumnData.id, newColumn, () => getBoardById(boardData.id));
        }
      } else {
        const newColumn = {
          title: data.columnName,
          type: (data.agendamentoConecta ? 4 : data.agendamento ? 3 : 0), // 0 = normal, 1 = start, 2 = end, 3 = appointment
          color: data.color,
          order: columnAddIndex
        }
        createColumn(newColumn);
      }
      reset();
      if (onHide) onHide();
    }

    // já existe uma coluna de agendamento e ela possui tasks
    if (data.agendamentoConecta && colConectaKey !== -1 && editColumnData.id != columnsData[colConectaKey]?.id) {
      if (columnsData[colConectaKey]?.tasks?.length) {
        toast.fire({
          icon: "error",
          title: "Você já possui uma coluna de Agenda Conectado e a mesa possui tasks.",
          html: "Mova as tasks da coluna de Agenda para outra coluna e tente novamente.",
        });
        return;
      }
    }

    // quer criar remover o tipo agenda, mas tem task
    var qtdTasks = (editColumnData.tasks && editColumnData.tasks?.length > 0 ? editColumnData.tasks?.length : 0);
    if (editColumnData && editColumnData.type == 4 && !data.agendamentoConecta && qtdTasks > 0) {
      toast.fire({
        icon: "error",
        title: "Não é possível alterar o tipo desta coluna enquanto ela possuir tasks.",
        html: "Mova as tasks para outra coluna e tente novamente.",
      });
      return;
    }
    

    // já possui uma coluna conectadoc e pergunta se deseja alterar-las para normal
    if (data.agendamentoConecta && colConectaKey !== -1 && editColumnData.id != columnsData[colConectaKey]?.id) {
      Swal.fire({
        title: "Você já possui uma coluna de Agenda Conectadoc.",
        html: `Deseja alterar a coluna <b>${columnsData[colConectaKey].title}</b> para normal e coluna <b>${data.columnName}</b> como Agenda Conectadoc?`,
        icon: "question",
        iconColor: 'var(--bs-danger)',
        position: "center",
        showConfirmButton: true,
        confirmButtonText: "Sim, alterar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
        reverseButtons: true,
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          confirmButton: "btn btn-outline-danger",
          cancelButton: "btn btn-light",
        },
      }).then((res) => {
        if (res.isConfirmed) {
          save(true);
        }
      });
      return;
    }

    // caso nao de erro, atualiza a coluna.
    save();

  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <Input
              type="text"
              placeholder="Título da Coluna"
              autoFocus
              autoComplete="off"
              // defaultValue={columnName}
              error={errors.columnName ? errors.columnName.message : ""}
              register={register("columnName")}
              onChange={(e) => setColumnName(e.target.value)}
            />
          </div>
          <div className="row gx-3 align-items-center mb-2">
            <div className="col col-auto pb-1">
              Color:
            </div>
            <div className="col">
              <Swatch
                colors={[
                  { color: '#ffffff', title: 'Default' },
                  { color: '#fad0c3', title: 'Vermelho' },
                  { color: '#ffe5d0', title: 'Laranja' },
                  { color: '#fef3bd', title: 'Amarelo' },
                  { color: '#c1e1c5', title: 'Verde' },
                  { color: '#bedadc', title: 'Azul' },
                  { color: '#c4def6', title: 'Ciano' },
                  { color: '#bed3f3', title: 'Anil' },
                  { color: '#d4c4fb', title: 'Roxo' },
                  { color: '#f6b2d1', title: 'Rosa' }]}
                color={getValues("color")}
                rectProps={{
                  children: <Point />,
                  style: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid var(--bs-border-color)'
                  },
                }}
                onChange={(hsvColor) => {
                  setValue('color', hsvaToHex(hsvColor));
                  setHex(hsvaToHex(hsvColor));
                }}
              />
            </div>
          </div>
          <div className="mb-4 text-center">
            {errors.color && <p className="text-danger small text-start">{errors.color.message}</p>}
          </div>
          {(!editColumnData || (editColumnData && ![1, 2].includes(editColumnData.type))) && (<>
            <label className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                {...register("agendamento")}
              />
              <span className="form-check-label">
                Exibir agendamento?
                <OverlayTrigger
                  placement="bottom-end"
                  overlay={<Tooltip>Ao mover um card para esta coluna, abrirá automaticamente o modal para adicionar a data.</Tooltip>}
                >
                  <i className="uil uil-info-circle opacity-50 ms-2"></i>
                </OverlayTrigger>
              </span>
            </label>
            <label className="form-check form-switch mt-3">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                {...register("agendamentoConecta")}
                disabled={!agendamento}
              />
              <span className="form-check-label">
                Agenda Conectadoc?
                <OverlayTrigger
                  placement="bottom-end"
                  overlay={<Tooltip>Marcar horário na agenda Conectadoc deste profissional.<br /><br />Quando o bord for default, os agendamentos cairão diretamente nesta coluna.</Tooltip>}
                >
                  <i className="uil uil-info-circle opacity-50 ms-2"></i>
                </OverlayTrigger>
              </span>
            </label>
          </>)}
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button
            type="submit"
            className="btn-primary rounded-pill"
          >
            {editColumnData ? 'Salvar' : 'Adicionar'}
          </Button>
        </Modal.Footer>
      </form>
    </>
  );
};

export default ModalColumn;

/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { Presencial, Teleconsulta } from "../components/ConsultaOptions";
import Image from "components/Image";
import { Appointment } from "types/patient";

export interface ConsultaCardInterface {
  appointment: Appointment;
  isPastAppointment?: boolean;
}
const ConsultaCard = ({
  appointment,
  isPastAppointment,
}: ConsultaCardInterface) => {

  return (
    <>
      <div className="card p-3 mb-3">
        <div className="row align-items-center">
          <div className="col-4 col-md-2 text-end border-end px-2">
            <p className="text-muted mb-0 lh-1 text-capitalize">
              {moment(appointment.date).format("ddd")}
            </p>
            <p className="fw-bold h5 mb-0 lh-1">
              {moment(appointment.date).date()}
            </p>
            <p className="small mb-0 lh-1">
              {moment(appointment.date).format("MMM")}
            </p>
          </div>

          <div className="col-8 col-md-3 text-center">
            <p className="fw-medium mb-0">{`${appointment.scheduleTime?.initialTimeHours}:${String(appointment.scheduleTime?.initialTimeMinutes).padStart(2, "0")}`}</p>
          </div>

          <div className="col-12 col-md mt-3 mt-md-0 border-top border-md-top-0">
            <div className="row g-3 align-items-center justify-content-center pt-3 pt-md-0">
              <div className="col col-auto">
                <Image
                  className="avatar img-fluid rounded-circle"
                  alt={`${appointment.specialistDoctor?.gender === 0 ? "Dra." : "Dr."} ${appointment.specialistDoctor?.name}`}
                  src={appointment.specialistDoctor?.image ?? ""}
                />
              </div>
              <div className="col text-left text-md-left">
                <h6 className="fw-medium mb-0">
                  {appointment.specialistDoctor?.gender === 0 ? "Dra." : "Dr."} {appointment.specialistDoctor?.name}
                </h6>
              </div>
            </div>
          </div>
          <div className="col-12 border-top mt-3 pt-3">

            {appointment.scheduleType === 0 ? (
              <Presencial
                isPastAppointment={isPastAppointment}
                appointment={appointment}
              />
            ) : (
              <Teleconsulta
                paymentStatus={appointment.paymentStatus}
                isPastAppointment={isPastAppointment}
                appointment={appointment}
              />
            )}
          </div>

        </div>
      </div>
    </>
  );
};

export default ConsultaCard;

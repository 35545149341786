import { Routes, Route, useLocation } from "react-router-dom";

import Page from "components/Page";
import Site from "components/Site";
import Index from "pages/Index";
import Dashboard from "pages/Dashboard";
import Boards from "pages/Boards";
import QrCode from "pages/QrCode";
import Chats from "pages/Chats";
import Integrations from "pages/Integrations";
import Calendar from "pages/Calendar";
import Flows from "pages/Flows";
import Scripts from "pages/Scripts";
import FlowBuilder from "pages/FlowBuilder";
import TagsPage from "pages/Tags";

import Appointments from "pages/Appointments";
import Exams from "pages/Exams";
import Queue from "pages/Queue";
import Call from "pages/Call";
import CallInfo from "pages/CallInfo";
import CallDetails from "pages/CallDetails";
import Payment from "pages/Payment";

import Notes from "pages/Notes";
import Patients from "pages/Patients";
import Cadastro from "pages/Cadastro";
import Login from "pages/Login";
import Logout from "pages/Logout";
import { useAuth } from "providers/Auth";
import { RoleDescription } from "types/enums";

import {
  ProfessionalRoute,
  PatientRoute,
  PrivateRoute,
  PublicRoute,
  AdminRoute,
} from "./route";

const Router = () => {
  const { userRole } = useAuth();

  return (
    <Routes>

      <Route element={<PublicRoute />}>
        <Route element={<Site />}>
          <Route path="/" element={<Index />} />
        </Route>

        <Route element={<Page />}>
          <Route path="/cadastro" element={<Cadastro />} />
          <Route path="/:id" element={<Index />} />
          <Route path="/:id/:idboard" element={<Index />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/:token" element={<Login />} />
        </Route>
      </Route>

      <Route element={<Page />}>
        <Route element={<PrivateRoute />}>
          <Route element={<ProfessionalRoute />}>
            <Route index={userRole === RoleDescription.Admin} element={<Dashboard />} />
            <Route path="/boards" element={<Boards />} />
            <Route path="/qr-code" element={<QrCode />} />
            <Route path="/pacientes" element={<Patients />} />
            <Route path="/conversas" element={<Chats />} />
            <Route path="/conversas/:id" element={<Chats />} />
            <Route path="/notas-de-versao" element={<Notes />} />
            <Route path="/integracoes" element={<Integrations />} />
            <Route path="/calendario" element={<Calendar />} />
            <Route path="/fluxos" element={<Flows />} />
            <Route path="/fluxos/:id" element={<FlowBuilder />} />
            <Route path="/scripts" element={<Scripts />} />
            {/* <Route path="/scripts/:id" element={<Script />} /> */}
            <Route path="/tags" element={<TagsPage />} />

            <Route path="/atendimento" element={<Call />} />
            <Route path="/info" element={<CallInfo />} />
            <Route path="/atendimento-detalhes" element={<CallDetails />} />
            <Route path="/consultas" element={<Appointments />} />
            <Route path="/fila" element={<Queue />} />
            <Route path="/pagamento" element={<Payment />} />
            <Route path="/exames" element={<Exams />} />
          </Route>
        </Route>

        <Route path="/logout" element={<Logout />} />

      </Route>
    </Routes>
  );
};

export default Router;

import { Dispatch, SetStateAction, useState, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonGroup, Modal, OverlayTrigger, ToggleButton, Tooltip } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import Button from "components/Button";
import Input from "components/Input";
import { hsvaToHex, hexToRgba, rgbaToRgb, getContrastingColor } from '@uiw/color-convert';
import { Board, BoardDefault, Id, Column, ColumnDefault, Task, TaskDefault, FileInfo } from "types/board";
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { useBoard } from "providers/Board";
import { api } from "services";
import { toast } from "utils/toast";
import { TextareaAutosize } from "@mui/material";
import { Swatch } from "@uiw/react-color";
import { isDev } from 'utils/isDev';
import FileViewer from 'react-file-viewer';
import { formatFileSize, imageExtensions, videoExtensions } from "pages/Boards/components/TaskTabs/FilesTab";

interface ModalFileProps {
  files: FileInfo[];
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  currentFile?: number;
}

const onError = (e: any) => {
  console.log(e, 'error in file-viewer');
  toast.fire({
    icon: "error",
    title: "Ocorreu um erro ao carregar o arquivo.",
  });
}

export const ModalFile = ({
  files,
  show,
  setShow,
  currentFile = 0
}: ModalFileProps) => {

  const [currentFileIndex, setCurrentFileIndex] = useState(currentFile);
  const [fullscreen, setFullscreen] = useState<true | "">('');

  useEffect(() => {
    if (show) {
      setCurrentFileIndex(currentFile);
    }
  }, [show, currentFile]);

  const handlePrev = () => {
    setCurrentFileIndex((prevIndex) => (prevIndex === 0 ? files.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentFileIndex((prevIndex) => (prevIndex === files.length - 1 ? 0 : prevIndex + 1));
  };

  function getVideoSourceType(fileExtension: string): string {
    const extensionToMimeType: Record<'mp4' | 'webm' | 'ogv' | 'mov' | 'avi' | 'mpeg', string> = {
      mp4: 'video/mp4',
      webm: 'video/webm',
      ogv: 'video/ogg',
      mov: 'video/quicktime',
      avi: 'video/avi',
      mpeg: 'video/mpeg',
    };
  
    // Verifica se a extensão está no mapeamento
    return extensionToMimeType[fileExtension as keyof typeof extensionToMimeType] || extensionToMimeType["mp4"];
  }

  return (
    <>
      <Modal show={(show)} size="lg" fullscreen={fullscreen} scrollable onHide={() => { setShow(false); }} backdrop="static" centered>
        <Modal.Header className="d-block" closeButton={false}>
          <div className="row">
            <div className="col">
              <Modal.Title className="h5 m-0">{files[currentFileIndex]?.description} <small className="opacity-50 fw-normal">({formatFileSize(files[currentFileIndex].length)})</small></Modal.Title>
            </div>
            <div className="col col-auto">
              <button type="button"
                className="btn-close lh-1 me-0"
                style={{ background: "none", fontSize: 20 }}
                onClick={() => {
                  setFullscreen(fullscreen === "" ? true : "");
                }}><i className="uil uil-expand-arrows"></i></button>
              <button type="button" className="btn-close" aria-label="Close"
                onClick={() => {
                  setShow(false);
                }}></button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column" /*style={{ minHeight: '50vh' }}*/>
          {files.length > 0 && currentFileIndex >= 0 ? (
            <>
              {files.map((file, index) => {
                if (index === currentFileIndex) {
                  if (file.fileExtension === "pdf") {
                    return <FileViewer
                      key={index}
                      fileType={file.fileExtension}
                      filePath={file.url}
                      onError={onError} />
                  } else if (imageExtensions.includes(file.fileExtension)) {
                    return <div className="text-center"><img src={file.url} alt={file.fileName} className="img-fluid" /></div>
                  } else if (videoExtensions.includes(file.fileExtension)) {
                    return <video controls>
                      <source src={file.url} type={getVideoSourceType(file.fileExtension)} />
                      <b>Seu navegador não suporta este tipo de arquivo.</b><br />
                      Faça o download e tente visualiza-lo no computador.
                    </video>
                  } else {
                    return <div className="py-5">
                      <b>Arquivo não suportado.</b><br />
                      Faça o download e tente visualiza-lo no computador.
                    </div>
                  }
                }
              }
              )}
            </>
          ) : (
            <div className="py-5 text-center">Nenhum arquivo selecionado.</div>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button
            onClick={handlePrev}
            className="btn-outline-primary rounded-pill"
            disabled={currentFileIndex === 0}
          >
            <i className="uil uil-arrow-left me-2"></i>Anterior
          </Button>
          <a
            href={files[currentFileIndex].url}
            download={files[currentFileIndex].fileName}
            className="btn btn-dark rounded-pill"
          >
            <i className="uil uil-download-alt me-2"></i>Download
          </a>
          <Button
            onClick={handleNext}
            className="btn-outline-primary rounded-pill"
            disabled={currentFileIndex === files.length - 1}
          >
            Próximo<i className="uil uil-arrow-right ms-2"></i>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalFile;

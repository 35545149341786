import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Script, Group, Message } from '..';

interface SortableMessageProps {
  message: Message;
  group: Script;
  subgroup?: Group;
  onShowModal: (type: "group" | "subgroup" | "message", group: Group, subgroupId?: string, messageId?: string) => void;
  onRemoveItem: (type: "group" | "subgroup" | "message", group: Group, subgroupId?: string, messageId?: string) => void;
}

const SortableMessage: React.FC<SortableMessageProps> = ({ message, group, subgroup, onShowModal, onRemoveItem }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: message.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="list-group-item py-3 d-flex align-items-center content-hover"
    >
      <div className="w-100 ps-3 d-flex align-items-center content-hover">
        <div className="drag-handle border rounded bg-white p-1 me-2 ms-n5 show-hover"
          {...attributes}
          {...listeners}>
          <i className="uil uil-draggabledots"></i>
        </div>
        <p className='small lh-base mb-0' style={{ whiteSpace: "pre-wrap" }}>{message.message}</p>
      </div>
      <div className="show-hover ms-auto">
        <Dropdown>
          <Dropdown.Toggle variant="light" size="sm" className="no-arrow p-2 lh-1 rounded-circle">
            <i className="uil uil-ellipsis-h"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu align='end'>
            <Dropdown.Item as="button" onClick={() => onShowModal("message", group, (subgroup ? subgroup.id : ''), message.id)}>
              <i className="uil uil-edit-alt me-2"></i>Editar Mensagem
            </Dropdown.Item>
            <Dropdown.Item as="button" className='text-danger' onClick={() => onRemoveItem("message", group, (subgroup ? subgroup.id : ''), message.id)}>
              <i className="uil uil-trash me-2"></i>Remover Mensagem
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default SortableMessage;
import { Dispatch, SetStateAction, useState, useEffect, ChangeEvent } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, Accordion, Dropdown, OverlayTrigger, Tooltip, Tab, Nav } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import "moment/locale/pt-br";
import moment from "moment";
import { useSpeechRecognition } from 'react-speech-kit';

import Button from "components/Button";
import Input from "components/Input";
import { hsvaToHex, hexToRgba, rgbaToRgb, getContrastingColor } from '@uiw/color-convert';
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { useBoard } from "providers/Board";
import { Board, BoardDefault, Id, Column, ColumnDefault, Task, TaskDefault, DescriptionLog } from "types/board";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import { toast } from "utils/toast";

interface ModalObservationsProps {
  // onHide?: () => void;
}

interface observationData {
  description: string;
}

const ModalObservations = ({
  // onHide,
}: ModalObservationsProps) => {
  const { token } = useAuth();
  const { userData } = useUser();
  const {
    boards,
    setBoards,
    getBoards,
    boardData,
    isLoading,
    isLoadingBoards,
    setBoardData,
    tasksData,
    setTasksData,
    editTaskData,
    setEditTaskData,
    editColumnData,
    setEditColumnData,
    columnsData,
    setColumnsData,
    taskName,
    setTaskName,
    taskDescription,
    setTaskDescription,
    taskDate,
    setTaskDate,
    taskMoveTo,
    setTaskMoveTo,
    taskType,
    setTaskType,
    taskId,
    setTaskId,
    showAddTaskModal,
    setShowAddTaskModal,
    showForwardPatientModal,
    setShowForwardPatientModal,
    createBoard,
    updateBoard,
    deleteBoard,
    getBoardById,
    getTasksByBoard,
    getPatientsByBoards,
    patientsByBoard,
    setPatientsByBoard,
    addColumnToBoard,
    editColumnFromBoard,
    addTaskToBoardColumn,
    getTasksByColumn,
    delColumnFromBoard,
    delTaskFromBoardColumn,
    editTaskFromBoardColumn,
    moveTaskBetweenBoardColumn,
    autoUpdate,
    setAutoUpdate,
    stopAutoUpdate,
    setStopAutoUpdate,
    checkDuplicatedTasks,
    keepDuplicates,
    setKeepDuplicates,
    showDuplicates,
    setShowDuplicates,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    searchTerm,
    setSearchTerm,
    filteredTasks,
    setFilteredTasks,
    handleSearchChange,
    updateTasksOrder,
    handleDeleteTask,
    handleTaskModal,
    columnAddIndex,
    setColumnAddIndex,
    columnName,
    setColumnName,
    columnId,
    setColumnId,
    showAddColumnModal,
    setShowAddColumnModal,
    hex,
    setHex,
    createColumn,
    deleteColumn,
    updateColumn,
    showModalTag,
    setShowModalTag,
    clearData,
    updateTask,
    obsLoading,
    setObsLoading
  } = useBoard();

  const taskById = (id: Id) => {
    const taskIndex = tasksData.findIndex((t) => t.id === id);
    if (tasksData && tasksData[taskIndex]) {
      return tasksData[taskIndex];
    } else {
      return {} as Task;
    }
  }

  const onEnd = () => {
    const actualText = getValues("description");
    if (actualText.trim() != "" && !actualText.trim().endsWith(".")) {
      if (actualText.trim().endsWith(",")) {
        setValue("description", actualText.replace(/,$/, ". "));
      }else{
        setValue("description", actualText + ". ");
      }
    }
  };
  const onResult = (result: any) => {
    const actualText = getValues("description");
    if(actualText.trim().endsWith(".")){
      setValue("description", actualText.trim() + " " + result);
    }else if(actualText.trim() != ""){
      setValue("description", actualText.trim() + ", " + result);
    }else{
      setValue("description", actualText.trim() + result);
    }
  };
  const onError = (event: any) => {
    console.log(event);
    if (event.error === 'not-allowed') {
      toast.fire({
        icon: "error",
        title: "Microfone está bloqueado no seu navegador.",
      });
    }
  };
  // docs: https://github.com/MikeyParton/react-speech-kit
  const { listen, listening, stop, supported } = useSpeechRecognition({
    onResult,
    onEnd,
    onError,
  });
  const toggleListening = listening
    ? stop
    : () => {
      listen({ interimResults: false, lang: 'pt-BR' });
    };

  const [obsSelected, setObsSelected] = useState("clinica");
  const [obsFilters, setObsFilters] = useState({
    clinica: true,
    admin: true
  });

  // Função para lidar com mudanças nos checkboxes
  const handleFilterChange = (filterName: 'clinica' | 'admin') => {
    setObsFilters(prev => ({
      ...prev,
      [filterName]: !prev[filterName]
    }));
  };

  const orderObservations = (data: Task) => {
    // Combine the two arrays, adding the 'type' property to each item

    var mergedDescriptions: DescriptionLog[] = [];

    if (data.description && data.description !== "" && (data.descriptionLog && data.descriptionLog?.length <= 0)) {
      mergedDescriptions = [
        {
          author: {
            id: data.author?.id,
            name: data.author?.name ?? ""
          },
          createdDate: data.createdDate ?? "",
          description: data.description ?? "",
          type: 'clinica'
        }
      ];
    } else {
      if (data.descriptionLog && obsFilters.clinica) {
        mergedDescriptions = [
          ...mergedDescriptions,
          ...data.descriptionLog.map(item => ({ ...item, type: 'clinica' }))
        ];
      }
      if (data.descriptionAdminLog && obsFilters.admin) {
        mergedDescriptions = [
          ...mergedDescriptions,
          ...data.descriptionAdminLog.map(item => ({ ...item, type: 'admin' }))
        ];
      }
    }


    // Sort the merged array by 'createdDate'
    return (mergedDescriptions.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()));
  }

  useEffect(() => {
    orderObservations(editTaskData);
  }, [obsFilters, editTaskData]);

  useEffect(() => {
    const taskIndex = tasksData?.findIndex((task: Task) => task.id === taskId);
    if (tasksData[taskIndex]) {
      setEditTaskData(tasksData[taskIndex]);
    }
  }, [tasksData]);

  const schemaTask = yup.object().shape({
    description: yup.string().required("Escreva uma observação."),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setFocus,
    control,
    reset,
    formState: { errors },
  } = useForm<observationData>({
    resolver: yupResolver(schemaTask),
  });


  const [fullscreen, setFullscreen] = useState<true | "">('');

  const onSubmit = async (task: observationData) => {
    stop();
    var newTaskData
    if (obsSelected === 'clinica') {
      newTaskData = {
        ...editTaskData,
        description: task.description
      }
    } else {
      newTaskData = {
        ...editTaskData,
        descriptionAdmin: task.description
      }
    };

    if (editTaskData.id) {
      editTaskFromBoardColumn(boardData.id, editTaskData.columnId, editTaskData.id, newTaskData, true, function () {
        reset();
      });
    }
  };
  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  return (
    <>
      <div className={`modal-dialog modal-xl my-0 mx-auto ${fullscreen ? "modal-full modal-dialog-scrollable" : ""}`}>
        <form onSubmit={handleSubmit(onSubmit)} className="modal-content">
          <Modal.Header className="d-block" closeButton={false}>
            <div className="row">
              <div className="col">
                <Modal.Title className="h5 m-0">Observações {(taskType === 1 || editTaskData.type === 1) ? "de atendimento" : ""}</Modal.Title>
              </div>
              <div className="col col-auto">
                <button type="button"
                  className="d-none d-lg-inline-block btn-close lh-1 me-0"
                  style={{ background: "none", fontSize: 20 }}
                  onClick={() => {
                    setFullscreen(fullscreen === "" ? true : "");
                  }}><i className="uil uil-expand-arrows"></i></button>
                <button type="button" className="btn-close" aria-label="Close"
                  onClick={() => {
                    setShowAddTaskModal(false);
                    setStopAutoUpdate(false);
                  }}></button>
              </div>
            </div>
            {editTaskData && editTaskData.duplicated && (<>
              <Nav variant="tabs" className="pt-2 align-items-center border-0 small mb-n3">
                {editTaskData.duplicated.map((tId: Id, index: number) => {
                  if (taskById(tId)) {
                    return <Nav.Item key={index} className={`${tId == editTaskData.id ? 'order-0' : 'order-' + (index + 1)}`}>
                      <Nav.Link className="light-tab position-relative" eventKey={`obs-${tId}`} onClick={() => { orderObservations(taskById(tId)) }}>
                        {taskById(tId).columnTitle}
                        {tId == editTaskData.id && (
                          <>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Atual</Tooltip>}
                            >
                              <i className="text-primary uil uil-bookmark position-absolute top-0 right-0 me-2" style={{ marginTop: -6 }}></i>
                            </OverlayTrigger>
                          </>
                        )}
                      </Nav.Link>
                    </Nav.Item>
                  }
                })}
              </Nav>
            </>)}
          </Modal.Header>
          {isLoading ? (
            <div className="text-center py-5">
              <CircularProgress size={40} />
            </div>
          ) : (
            <>
              <Modal.Body className="bg-light px-3 pb-1 rounded-bottom">
                <div className="mb-3">
                  <div className={`row g-2 align-items-center justify-content-between`}>
                    <div className="col col-auto">
                      <label htmlFor="description" className="small me-auto"><i className="uil uil-comment-alt-lines me-2"></i>Exibir Observações:</label>
                    </div>
                    {(taskType === 0 || editTaskData.type === 0) && (
                      <>
                        <div className="col col-auto">
                          <div className={`row align-items-center`}>
                            <div className="col col-auto">
                              <label className="form-check">
                                <input className="form-check-input"
                                  type="checkbox"
                                  onChange={() => handleFilterChange('clinica')}
                                  defaultChecked={obsFilters.clinica} />
                                <span className="form-check-label small">
                                  <i className="uil uil-heartbeat me-1"></i>
                                  Clínicas
                                </span>
                              </label>
                            </div>
                            <div className="col col-auto">
                              <label className="form-check">
                                <input className="form-check-input success"
                                  type="checkbox"
                                  onChange={() => handleFilterChange('admin')}
                                  defaultChecked={obsFilters.admin} />
                                <span className="form-check-label small">
                                  <i className="uil uil-comment-alt-notes me-1"></i>
                                  Atendimento
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <Tab.Content>
                    {editTaskData && editTaskData.duplicated ? (<>
                      {editTaskData.duplicated.map((tId: Id, index: number) => (
                        <Tab.Pane key={index} eventKey={`obs-${tId}`}>
                          <ul className={`list-unstyled form-control mb-2 overflow-y-auto overflow-x-hidden observations-list`}>
                            {orderObservations(taskById(tId)) && orderObservations(taskById(tId)).length > 0 ? (
                              orderObservations(taskById(tId)).map((obs, index) => (
                                <li key={index} className="content-hover ps-4 ps-xl-0 position-relative">
                                  <div className="row gx-5 small">
                                    <div className="col-12 col-xl-3 text-xl-end pt-3" style={{ minWidth: 100 }}>
                                      <div className="border-start border-2 position-absolute top-0 bottom-0 start-0 pt-xl-3 d-xl-none"></div>
                                      <span className="small lh-1 opacity-50">{moment(obs.createdDate).isSame(moment(), 'day') ? moment(obs.createdDate).format("DD/MM/YYYY HH:mm") : moment(obs.createdDate).format("DD/MM/YYYY HH:mm")}</span>
                                    </div>
                                    <div className="col position-relative pt-xl-3">
                                      <div className="border-start border-2 position-absolute top-0 bottom-0 start-0 pt-xl-3">
                                        <div className={`bg-${obs.type === 'admin' ? 'success' : 'primary'} p-2 rounded-circle position-absolute mt-1 translate-middle-x`}></div>
                                        {/* {tId == editTaskData.id && (
                                        <Dropdown drop='down-centered'>
                                          <Dropdown.Toggle variant="light" size="sm" className="show-hover no-arrow rounded-circle p-2 lh-1 position-absolute top-0 left-0 my-n1 translate-middle-x">
                                            <i className="uil uil-ellipsis-h"></i>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item as="button" onClick={() => { }}>
                                              <i className="uil uil-edit-alt me-2"></i>Editar
                                            </Dropdown.Item>
                                            <Dropdown.Item as="button" className='text-danger' onClick={() => { }}>
                                              <i className="uil uil-trash me-2"></i>Remover
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      )} */}
                                      </div>
                                      <p>{obs.description}</p>
                                    </div>
                                  </div>
                                </li>
                              ))
                            ) : (
                              <li className="px-3 py-5 text-center small">Nenhuma observação cadastrada.</li>
                            )}
                          </ul>
                        </Tab.Pane>
                      ))}
                    </>) : (
                      <>
                        <ul className={`list-unstyled form-control mb-2 overflow-y-auto overflow-x-hidden observations-list`}>
                          {orderObservations(editTaskData) && orderObservations(editTaskData).length > 0 ? (
                            orderObservations(editTaskData).map((obs, index) => (
                              <li key={index} className="content-hover ps-4 ps-xl-0 position-relative">
                                <div className="row gx-5 small">
                                  <div className="col-12 col-xl-3 text-xl-end pt-3" style={{ minWidth: 100 }}>
                                    <div className="border-start border-2 position-absolute top-0 bottom-0 start-0 pt-xl-3 d-xl-none"></div>
                                    <span className="small lh-1 opacity-50">{moment(obs.createdDate).isSame(moment(), 'day') ? moment(obs.createdDate).format("DD/MM/YYYY HH:mm") : moment(obs.createdDate).format("DD/MM/YYYY HH:mm")}</span>
                                  </div>
                                  <div className="col position-relative pt-xl-3">
                                    <div className="border-start border-2 position-absolute top-0 bottom-0 start-0 pt-xl-3">
                                      <div className={`bg-${obs.type === 'admin' ? 'success' : 'primary'} p-2 rounded-circle position-absolute mt-1 translate-middle-x`}></div>
                                      {/* <Dropdown drop='down-centered'>
                                      <Dropdown.Toggle variant="light" size="sm" className="show-hover no-arrow rounded-circle p-2 lh-1 position-absolute top-0 left-0 my-n1 translate-middle-x">
                                        <i className="uil uil-ellipsis-h"></i>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item as="button" onClick={() => { }}>
                                          <i className="uil uil-edit-alt me-2"></i>Editar
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button" className='text-danger' onClick={() => { }}>
                                          <i className="uil uil-trash me-2"></i>Remover
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown> */}
                                    </div>
                                    <p>{obs.description}</p>
                                  </div>
                                </div>
                              </li>
                            ))
                          ) : (
                            <li className="px-3 py-5 text-center small">Nenhuma observação cadastrada.</li>
                          )}
                        </ul>
                      </>
                    )}
                  </Tab.Content>
                </div>
              </Modal.Body>
              <Tab.Content className="modal-footer d-block">
                {editTaskData && editTaskData.duplicated ? (<>
                  {editTaskData.duplicated.map((tId: Id, index: number) => {
                    if (tId == editTaskData.id) {
                      return <Tab.Pane key={index} eventKey={`obs-${tId}`}>
                        <div className="d-flex">
                          <div className="w-100 d-flex position-relative">
                            <div className="d-flex flex-column gap-1">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Clínica</Tooltip>}
                              >
                                <label className={`btn px-2 py-1 ${obsSelected === 'clinica' ? 'btn-primary border-primary' : 'btn-outline-primary'} border border-end-0 rounded-end-0`}>
                                  <i className="uil uil-heartbeat"></i>
                                  <input type="radio" className="d-none" name="obsType" onChange={(e) => { setObsSelected(e.target.value) }} defaultChecked={obsSelected === 'clinica'} value={'clinica'} />
                                </label>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip>Atendimento</Tooltip>}
                              >
                                <label className={`btn px-2 py-1 ${obsSelected === 'admin' ? 'btn-success border-success' : 'btn-outline-success'} border border-end-0 rounded-end-0`}>
                                  <i className="uil uil-comment-alt-notes"></i>
                                  <input type="radio" className="d-none" name="obsType" onChange={(e) => { setObsSelected(e.target.value) }} defaultChecked={obsSelected === 'admin'} value={'admin'} />
                                </label>
                              </OverlayTrigger>
                            </div>
                            <TextareaAutosize
                              placeholder={`Nova Observação ${obsSelected === 'clinica' ? 'Clínica' : 'de Atendimento'}...`}
                              defaultValue={""}
                              {...register("description")}
                              name="description"
                              disabled={obsLoading || listening}
                              id="description"
                              minRows={2}
                              className={`form-control rounded-start-0 ${errors?.description?.message ? 'is-invalid' : ''}`}
                              // autoFocus={editTaskData && columnsData[editTaskData.columnKey] && columnsData[editTaskData.columnKey].type != 3}
                            ></TextareaAutosize>
                            <div className="d-flex flex-column gap-1 justify-content-between ps-2">
                              <div className="text-center position-relative">
                                {listening && (
                                  <span className="on-pulse bg-success p-1 rounded-circle position-absolute start-0 top-0 ms-n3"></span>
                                )}
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Fala para texto</Tooltip>}
                                >
                                  <button type="button" disabled={!supported} className={`btn btn-sm ${!listening ? "btn-outline-dark" : "btn-danger"} rounded-pill`} onClick={toggleListening}>
                                    <i className={`uil uil-${!listening ? "microphone" : "microphone-slash"}`}></i>
                                  </button>
                                </OverlayTrigger>
                              </div>
                              {obsLoading ? (
                                <button disabled className={`btn btn-${obsSelected === 'admin' ? 'success' : 'primary'} px-3 py-2 lh-1 rounded-pill`}>
                                  <CircularProgress
                                    color="inherit"
                                    size={16}
                                    className="ms-2"
                                  />
                                </button>
                              ) : (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip>Enviar observação {obsSelected === 'admin' ? 'de Atendimento' : 'Clínica'}</Tooltip>}
                                >
                                  <button type="submit" className={`btn btn-${obsSelected === 'admin' ? 'success' : 'primary'} p-2 lh-1 rounded-pill`}><i className="uil uil-message"></i></button>
                                </OverlayTrigger>

                              )}
                            </div>
                          </div>
                        </div>
                        {errors?.description?.message ? (
                          <div className="text-start text-danger ps-4">
                            <small className="ms-2">{errors?.description?.message}</small>
                          </div>
                        ) : (<></>)}
                      </Tab.Pane>
                    }
                  })}
                </>) : (<>
                  <div className="d-flex">
                    <div className="w-100 d-flex position-relative">
                      <div className="d-flex flex-column gap-1">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Clínica</Tooltip>}
                        >
                          <label className={`btn px-2 py-1 ${obsSelected === 'clinica' ? 'btn-primary border-primary' : 'btn-outline-primary'} border border-end-0 rounded-end-0`}>
                            <i className="uil uil-heartbeat"></i>
                            <input type="radio" className="d-none" name="obsType" onChange={(e) => { setObsSelected(e.target.value) }} defaultChecked={obsSelected === 'clinica'} value={'clinica'} />
                          </label>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>Atendimento</Tooltip>}
                        >
                          <label className={`btn px-2 py-1 ${obsSelected === 'admin' ? 'btn-success border-success' : 'btn-outline-success'} border border-end-0 rounded-end-0`}>
                            <i className="uil uil-comment-alt-notes"></i>
                            <input type="radio" className="d-none" name="obsType" onChange={(e) => { setObsSelected(e.target.value) }} defaultChecked={obsSelected === 'admin'} value={'admin'} />
                          </label>
                        </OverlayTrigger>
                      </div>
                      <TextareaAutosize
                        placeholder={`Nova Observação ${obsSelected === 'clinica' ? 'Clínica' : 'de Atendimento'}...`}
                        defaultValue={""}
                        {...register("description")}
                        name="description"
                        disabled={obsLoading || listening}
                        id="description"
                        minRows={2}
                        className={`form-control rounded-start-0 ${errors?.description?.message ? 'is-invalid' : ''}`}
                        // autoFocus={editTaskData && columnsData[editTaskData.columnKey] && columnsData[editTaskData.columnKey].type != 3}
                      ></TextareaAutosize>
                      <div className="d-flex flex-column gap-1 justify-content-between ps-2">
                        <div className="text-center position-relative">
                          {listening && (
                            <span className="on-pulse bg-success p-1 rounded-circle position-absolute start-0 top-0 ms-n3"></span>
                          )}
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Fala para texto</Tooltip>}
                          >
                            <button type="button" disabled={!supported} className={`btn btn-sm ${!listening ? "btn-outline-dark" : "btn-danger"} rounded-pill`} onClick={toggleListening}>
                              <i className={`uil uil-${!listening ? "microphone" : "microphone-slash"}`}></i>
                            </button>
                          </OverlayTrigger>
                        </div>
                        {obsLoading ? (
                          <button disabled className={`btn btn-${obsSelected === 'admin' ? 'success' : 'primary'} px-3 py-2 lh-1 rounded-pill`} >
                            <CircularProgress
                              color="inherit"
                              size={16}
                              className="ms-2"
                            />
                          </button>
                        ) : (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Enviar</Tooltip>}
                          >
                            <button type="submit" className={`btn btn-${obsSelected === 'admin' ? 'success' : 'primary'} p-2 lh-1 rounded-pill`}><i className="uil uil-message"></i></button>
                          </OverlayTrigger>

                        )}
                      </div>
                    </div>
                  </div>
                  {errors?.description?.message ? (
                    <div className="text-start text-danger ps-4">
                      <small className="ms-2">{errors?.description?.message}</small>
                    </div>
                  ) : (<></>)}
                </>)}
              </Tab.Content>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default ModalObservations;

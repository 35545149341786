import { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { ButtonGroup, Modal, ToggleButton, Accordion, Dropdown, OverlayTrigger, Tooltip, Tab, Nav } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import SelectTag from 'components/SelectTag';
import { ModalFile } from 'components/ModalFile';
import { useAuth } from "providers/Auth";
import { useBoard } from "providers/Board";
import { useUser } from "providers/User";
import { Board, BoardDefault, Id, Column, ColumnDefault, Task, TaskDefault } from "types/board";
import { Appointment } from "types/patient";
import { api } from "services";
import Swal from "sweetalert2";
import { toast } from "utils/toast";
import logger from "utils/logger";
import { isDev } from 'utils/isDev';
import { usePatient } from "providers/Patient";
import { Chat, Message, GroupedMessages, NewMessage } from "types/whatsapp";
import ChatMessages from "pages/Chats/components/ChatMessages";
import ChatInput from "pages/Chats/components/ChatInput";
import Image from "components/Image";
import ConsultaCard from "pages/Appointments/components/ConsultaCard";
import { HealthInsurance } from "types/patient";

interface PropsAppointmentTab {
  id?: number;
}

export function AppointmentTab({
  id,
}: PropsAppointmentTab) {
  const { token, userRole, loginQuick } = useAuth();
  const { userData } = useUser();
  const navigate = useNavigate();

  const {
    editTaskData,
    tasksData,
    updateTask,
    handleTaskModal
  } = useBoard();

  const {
    patients,
    setPatients,
    patientData,
    setPatientData,
    editPatientData,
    patientLoading,
    isLoadingPaciente,
    isLoadingPatientSearch,
    getPatientById,
    getPatientByCpf,
    searchPatient,
    getPatientAppointments,
    appointments,
    setAppointments,
    showAddPatientModal,
    setShowAddPatientModal,
    getAppointmentById,
    appointmentData,
    setAppointmentData,
  } = usePatient();

  useEffect(() => {
    if (id) {
      getAppointmentById(id);
    }
    return () => setAppointmentData({} as Appointment);
  }, []);

  const getHealthInsurances = async () => {
    try {
      const response = await api.get(`/Schedule/GetHealthInsurances`);
      if (response.data) {
        const convenio = response.data.find((convenio:any) => convenio.idConvenio === appointmentData.healthInsuranceId);
        if (convenio) {
          const hi: HealthInsurance = {
            id: convenio.idConvenio,
            name: convenio.nome
          }
          const newAppointment = { ...appointmentData, healthInsurance: hi };
          console.log(newAppointment);
          setAppointmentData(newAppointment);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!appointmentData.healthInsurance) {
      getHealthInsurances();
    }
  }, [appointmentData]);

  return (
    <>
      <Modal.Body className="bg-light p-0">
        {isLoadingPaciente || patientLoading? (
          <>
            <div className="d-flex align-items-center justify-content-center h-100 py-5">
              <CircularProgress size={40} />
            </div>
          </>
        ) : (
          <>
            {appointmentData.id ? (<>
              <div className="position-relative">
                {editTaskData && editTaskData.appointment && editTaskData.appointment.scheduleId && !editTaskData.appointment.date && (
                  <div className="position-absolute z-1 h-100 w-100 d-flex align-items-center bg-white bg-opacity-50 rounded px-4">
                    <div className="w-100 text-danger border-top border-bottom border-2 border-danger text-center p-3" style={{ transform: "rotate(-3deg)" }}>
                      <b>CANCELADA</b>
                    </div>
                  </div>
                )}
                <ConsultaCard appointment={appointmentData} />
              </div>
            </>) : (
              <>
                <div className="card px-3 py-5 text-center mb-3">
                  Nenhuma consulta agendada.
                </div>
              </>
            )}
          </>
        )}
      </Modal.Body>
      {/* <Modal.Footer className="flex-nowrap p-0 gap-0">

      </Modal.Footer> */}
    </>
  );
};

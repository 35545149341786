import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useParams } from 'react-router';
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";
import Footer from "components/Footer";
import { useUser } from "providers/User";
import { useBoard } from "providers/Board";
import { useDarkMode } from "providers/DarkMode";
import logger from "utils/logger";
import Aside from "components/Aside";
import moment from "moment";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import ModalComponent from "components/ModalComponent";
import StartNode from "./components/StartNode";
import EndNode from "./components/EndNode";
import MessageNode from "./components/MessageNode";
import WaitTimeNode from "./components/WaitTimeNode";
import LoopNode from "./components/LoopNode";
import ButtonEdge from "./components/ButtonEdge";
import MessageForm from "./forms/MessageForm";
import WaitTimeForm from "./forms/WaitTimeForm";
import LoopForm from "./forms/LoopForm";
import { CustomNode, NodeData, Button, components } from "./utils/nodeTypes";
import { v4 as uuidv4 } from 'uuid';
import {
  ReactFlowProvider,
  Background,
  Controls,
  MiniMap,
  ReactFlow,
  ReactFlowInstance,
  addEdge,
  Node,
  Connection,
  Edge,
  useNodesState,
  useEdgesState,
  Handle,
  Position,
  NodeProps,
  MarkerType,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import "./utils/reactflow.css";
import { Prev } from "react-bootstrap/esm/PageItem";

export interface FlowBuilderProps {
  mini?: boolean;
  id?: string | number;
}

const initialNodes: Node[] = [
  {
    id: '1',
    type: 'start',
    data: { label: 'Start' },
    position: { x: 250, y: 5 },
    selectable: true,
  },
];
const initialEdges: Edge[] = [];

const FlowBuilder = ({
  mini,
  id
}: FlowBuilderProps) => {
  useEffect(() => { document.title = 'Flow Builder | CRM - Conectadoc'; }, []);
  const { token } = useAuth();
  const { userData } = useUser();
  const navigate = useNavigate();
  const { darkMode } = useDarkMode();

  const [asideLeftOpen, setAsideLeftOpen] = useState(true);

  const handleToggleAside = (aside: string) => {
    const asideContent = document.querySelector("aside#" + aside);
    if (asideContent) {
      const button = asideContent.querySelector(".abre-aside");
      if (button) {
        (button as HTMLElement).click();
      }
    }
  }

  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();
  const [selectedNode, setSelectedNode] = useState<CustomNode | null>(null);

  const updateNodeSelection = useCallback((selectedNodeId: string | null) => {
    setNodes((nds) =>
      nds.map((node) => ({
        ...node,
        selected: node.id === selectedNodeId,
      }))
    );
    setSelectedNode(selectedNodeId ? nodes.find(node => node.id === selectedNodeId) as CustomNode || null : null);
  }, [setNodes, nodes]);

  const onSelectionChange = useCallback(({ nodes: selectedNodes }: { nodes: Node[] }) => {
    if (selectedNodes.length > 0) {
      updateNodeSelection(selectedNodes[0].id);
    } else {
      updateNodeSelection(null);
    }
  }, [updateNodeSelection]);

  // Function to handle clicking the back button in the Aside component
  const handleBackClick = useCallback(() => {
    updateNodeSelection(null);
  }, [updateNodeSelection]);

  const onDeleteNode = (nodeId: string) => {
    console.log(nodes, nodeId);
    setNodes((nds) => nds.filter((node) => node.id !== nodeId));
  };

  const nodeTypes = useMemo(
    () => ({
      start: (props: any) => <StartNode {...props} onDelete={onDeleteNode} />,
      end: (props: any) => <EndNode {...props} onDelete={onDeleteNode} />,
      message: (props: any) => <MessageNode {...props} onDelete={onDeleteNode} />,
      waitTime: (props: any) => <WaitTimeNode {...props} onDelete={onDeleteNode} />,
      loop: (props: any) => <LoopNode {...props} onDelete={onDeleteNode} />,
    }), []);

  const edgeTypes = useMemo(
    () => ({
      buttonedge: ButtonEdge,
    }), []);

  // Captura a conexão
  const onConnect = (params: Connection | Edge) => {
    // Verifica se o ponto de conexão é de um botão específico
    if (params.sourceHandle && params.sourceHandle.startsWith('button-')) {
      console.log(`Conectado a partir do botão ${params.sourceHandle}`);
    }

    setEdges((eds) => addEdge({
      ...params,
      type: 'buttonedge',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
        // color: 'var(--bs-primary)',
      },
      selectable: false

    }, eds));
  };

  const onDragStart = (event: React.DragEvent, nodeType: string) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const onDrop = (event: React.DragEvent) => {
    event.preventDefault();

    const type = event.dataTransfer.getData('application/reactflow');
    if (typeof type === 'undefined' || !type) {
      return;
    }

    if (reactFlowInstance) {

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const newNode: Node = {
        id: uuidv4(),
        type,
        position,
        selectable: true,
        data: {
          text: type === 'message' ? 'Nova Mensagem' : '',
          buttons: type === 'message' ? [{ id: uuidv4(), label: 'Botão 1' }, { id: uuidv4(), label: 'Botão 2' }, { id: uuidv4(), label: 'Botão 3' }] : [],
          interval: type === 'waitTime' ? 1 : undefined,
          unit: type === 'waitTime' ? 'minutos' : undefined,
          times: type === 'loop' ? 1 : undefined,
          // onChange: (key: string, value: any) => {
          //   setNodes((nds) =>
          //     nds.map((node) => {
          //       if (node.id === newNode.id) {
          //         node.data = {
          //           ...node.data,
          //           [key]: value,
          //         };
          //       }
          //       return node;
          //     })
          //   );
          // },
        },
      };

      setNodes((nds) => nds.concat(newNode));
      // seleciona o nó após cria-lo
      if (!["start", "end"].includes(type)) {
        updateNodeSelection(newNode.id);
      }

      if(!document.querySelector(".aside-overlay")?.classList.contains("d-none")){
        handleToggleAside('left')
      }
      
    }

  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const handleUpdateNode = useCallback((id: string, data: NodeData) => {
    setNodes((nds) => {
      return nds.map((node) => (node.id === id ? { ...node, data: data } : node))
    });

    setEdges((eds) => {
      console.log(eds);
      // Primeiro filtra as edges dos botões que não existem mais
      const validButtonEdges = eds.filter((edge) => {
        const buttonId = edge.sourceHandle?.split('button-')[1];
        if (edge.source === id && buttonId) {
          return data.buttons.some((button: Button) => button.id === buttonId);
        }
        return true;
      });

      // Depois filtra edges que apontam para nodes que não existem mais
      return validButtonEdges.filter((edge) => {
        const sourceNodeExists = nodes.some(node => node.id === edge.source);
        const targetNodeExists = nodes.some(node => node.id === edge.target);
        return sourceNodeExists && targetNodeExists;
      });
    });

    updateNodeSelection(null);

  }, [setNodes, setEdges, nodes]);

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    if (userData && userData.id) {
      // getBoards();
    }
  }, [token, userData.id]);

  if (mini === true) {
    return (
      <div className="mini">
        <ReactFlow
          nodes={nodes}
          onNodesChange={onNodesChange}
          nodeTypes={nodeTypes}
          onInit={setReactFlowInstance}
          fitView
        >
          <MiniMap />
        </ReactFlow>
      </div>
    );
  }

  return (
    <>

      <ContainerAnimation className="content p-0 vh-100">
        <div id="top" className="d-print-none mb-0 mt-0">
          <div className="container">
            <div className="row justify-content-md-between align-items-center gy-2 gx-2">
              <div className="col">
                <h1 className="mb-0 fw-semibold lh-1">Flow Builder</h1>
              </div>
              <div className="col col-auto d-lg-none">
                <a className="btn btn-primary rounded-pill" onClick={() => { handleToggleAside('left'); }}><i className="uil uil-layers me-2"></i>Componentes</a>
              </div>
            </div>
          </div>
        </div >
        <div id="inner-content" className="h-100 no-overlay">
          <ReactFlowProvider>
            <Aside id="left" isOpen={asideLeftOpen} onToggleAside={setAsideLeftOpen}>
              <div className="d-flex flex-column h-100 position-relative">
                {selectedNode ? (
                  <>
                    <div className="d-block bg-light sticky-top border-bottom p-3">
                      <div className="row g-2">
                        <div className="col col-auto">
                          <OverlayTrigger
                            overlay={<Tooltip>Voltar para Componentes</Tooltip>}
                          >
                            <a onClick={handleBackClick} className="btn btn-light lh-sm px-2 text-primary"><i className="uil uil-arrow-left"></i></a>
                          </OverlayTrigger>

                        </div>
                        <div className="col">
                          <h5 className="mb-1">Editar {components.find(component => component.type === selectedNode.type)?.title}</h5>
                          <p className="opacity-50 mb-0 small lh-sm">Altere o componente e salve para visualizar as alterações.</p>
                        </div>
                      </div>
                    </div>
                    {(() => {
                      switch (selectedNode.type) {
                        case "message":
                          return <MessageForm node={selectedNode} onUpdate={handleUpdateNode} />;
                        case "waitTime":
                          return <WaitTimeForm node={selectedNode} onUpdate={handleUpdateNode} />;
                        case "loop":
                          return <LoopForm node={selectedNode} onUpdate={handleUpdateNode} />;
                        default:
                          return null;
                      }
                    })()}
                  </>
                ) : (
                  <>
                    <div className="d-block bg-light sticky-top border-bottom p-3 text-center">
                      <h5 className="mb-1">Componentes</h5>
                      <p className="opacity-50 mb-0 small lh-sm">Arraste e solte os componentes para adicionar ao fluxo.</p>
                    </div>
                    <div className="row p-3 g-3">
                      {components.map((component, index) => {
                        const isStartDisabled = component.type === 'start' && nodes.some(node => node.type === 'start');

                        return (<div key={index} className='col-12'>
                          <div className={`flow-component card p-2 ${isStartDisabled ? 'opacity-50 disabled' : ''}`} onDragStart={(event) => onDragStart(event, component.type)} draggable={`${isStartDisabled ? false : true}`}>
                            <div className="row g-2">
                              <div className="col col-auto">
                                <div className="bg-light rounded px-2">
                                  <i className={`uil uil-${component.icon} text-${component.color} fs-3`}></i>
                                </div>
                              </div>
                              <div className="col">
                                <h6 className="mb-0">{component.title}</h6>
                                <p className="opacity-50 mb-0 small lh-sm">{component.description}</p>
                              </div>
                            </div>
                          </div>
                        </div>);
                      })}
                    </div>
                  </>
                )}
              </div>
            </Aside>
            <div id="center" className="h-100">
              <div ref={reactFlowWrapper} className="h-100">
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  // onNodeClick={onNodeClick}
                  onSelectionChange={onSelectionChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  nodeTypes={nodeTypes}
                  edgeTypes={edgeTypes}
                  onInit={setReactFlowInstance}
                  onDrop={onDrop}
                  onDragOver={handleDragOver}
                  fitView
                >
                  <MiniMap />
                  <Controls />
                  <Background />
                </ReactFlow>
              </div>
              <Footer />
            </div>
          </ReactFlowProvider>
        </div>
      </ContainerAnimation>
    </>
  );
};

export default FlowBuilder;

/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import AppointmentCancellation from "../../../../Modals/AppointmentCancellation";
import { Appointment, DoctorAddress } from "types/patient";

export interface TeleconsultaInterface {
  paymentStatus: number;
  isPastAppointment?: boolean;
  appointment: Appointment;
}

export interface PresencialInterface {
  isPastAppointment?: boolean;
  appointment: Appointment;
}

export const Teleconsulta = ({
  paymentStatus,
  isPastAppointment,
  appointment,
}: TeleconsultaInterface) => {
  const navigation = useNavigate();
  const [modal, setModal] = useState(false);

  let paymentStatusMessage = "";
  let icon = "";
  switch (paymentStatus) {
    case 1:
      paymentStatusMessage = "Pagamento confirmado";
      icon = "uil-video text-success";
      break;
    case 2:
      paymentStatusMessage = "Pagamento cancelado, refaça o agendamento";
      icon = "uil-file-contract-dollar text-danger";
      break;
    case 3:
      paymentStatusMessage = "Aguardando pagamento";
      icon = "uil-video text-muted";
      break;
    case 4:
      paymentStatusMessage = "Pagamento reembolsado";
      icon = "uil-video text-danger";
      break;
    default:
      icon = "uil-video text-primary";

  }

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-5">
          <div className="row g-3 align-items-center justify-content-center">
            <div className="col col-auto text-end pe-0">
              <i className={`fs-3 fw-bolder uil ${icon}`}></i>
            </div>
            <div className="col col-auto text-left text-md-left">
              <p className="fw-medium mb-0">Teleconsulta</p>
              <p className="small lh-1 mb-0">
                <small>{paymentStatusMessage}</small>
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md pt-3 pt-md-0 text-center text-md-start border-top mt-3 border-md-top-0 mt-md-0">
          <span className="opacity-50 text-10 d-block">Convênio:</span>
          <span>{appointment.healthInsurance?.name}&nbsp;</span>
        </div>
        {/* <div className="col-12 col-md pt-3 pt-md-0 text-center border-top mt-3 border-md-top-0 mt-md-0 d-flex flex-wrap gap-2 justify-content-center">
          {paymentStatus === 1 && !isPastAppointment && (
            <>
              {moment().isBetween(
                moment(
                  moment(appointment.date)
                    .add(appointment.scheduleTime.initialTimeHours, "h")
                    .add(appointment.scheduleTime.initialTimeMinutes, "m")
                    .subtract(10, "m")
                ),
                moment(appointment.date).add(1, "d")
              ) ? (
                <button
                  className="text-nowrap btn btn-sm btn-success"
                  onClick={() => navigation("/info", { state: { appointment } })}
                >
                  <i className="uil uil-video me-2"></i>AGUARDAR ATENDIMENTO
                </button>
              ) : (
                <button
                  className="text-nowrap btn btn-sm btn-primary"
                  onClick={() =>
                    navigation("/atendimento-detalhes", {
                      state: { appointment },
                    })
                  }
                >
                  <i className="uil uil-eye me-2"></i>VISUALIZAR
                </button>
              )}
              <button
                className="text-nowrap btn btn-sm btn-outline-danger"
                onClick={() => setModal(true)}
              >
                <i className="uil uil-times me-2"></i>CANCELAR
              </button>
            </>
          )}

          {(paymentStatus === 2 || paymentStatus === 4) && (
            <button
              className="text-nowrap btn btn-sm btn-success"
              onClick={() =>
                navigation("/profissional", {
                  state: appointment.specialistDoctor.id,
                })
              }
            >
              <i className="uil uil-redo-alt me-2"></i>REAGENDAR
            </button>
          )}

          {paymentStatus === 3 && !isPastAppointment && (
            <>
              <button className="text-nowrap btn btn-sm btn-success">
                <i className="uil uil-dollar-sign me-2"></i>PAGAR
              </button>
              <button
                className="text-nowrap btn btn-sm btn-outline-danger"
                onClick={() => setModal(true)}
              >
                <i className="uil uil-times me-2"></i>CANCELAR
              </button>
            </>
          )}

          {(paymentStatus === 1 || paymentStatus === 3) && !!isPastAppointment && (
            <button
              className="text-nowrap btn btn-sm btn-primary"
              onClick={() =>
                navigation("/atendimento-detalhes", { state: { appointment } })
              }
            >
              <i className="uil uil-eye me-2"></i>VISUALIZAR
            </button>
          )}
        </div> */}
      </div>

      {/* Modal de cancelamento de consulta */}
      {/* <AppointmentCancellation
        modal={modal}
        setModal={setModal}
        appointment={appointment}
      /> */}
    </>
  );
};

export const Presencial = ({
  isPastAppointment,
  appointment,
}: PresencialInterface) => {
  const [modal, setModal] = useState(false);
  const navigation = useNavigate();

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-8">
          <div className="row align-items-center justify-content-center pt-3 pt-md-0">
            <div className="col col-auto text-end pe-0">
              <i className="fs-3 fw-bolder uil uil-map-marker text-success"></i>
            </div>
            {appointment.address && (
              <div className="col col-md-8 col-lg-9 pl-0 text-left text-md-left">
                <p className="fw-medium mb-0">{appointment.address.name}</p>
                <p className="small lh-1 mb-0">
                  <small>{`${appointment.address.addressName}, ${appointment.address.number} ${appointment.address.optionalName} ${appointment.address.neighborhood} - ${appointment.address.city}/${appointment.address.state}`}</small>
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="col-12 col-md pt-3 pt-md-0 text-center border-top mt-3 border-md-top-0 mt-md-0">
          <span className="opacity-50 text-10 d-block">Convênio:</span>
          {appointment.healthInsurance?.name}
        </div>
        {/*         
        <div className="col-12 col-lg-4 pt-3 pt-lg-0 text-center border-top mt-3 border-lg-top-0 mt-lg-0 d-flex flex-wrap gap-2 justify-content-center">
          <button
            className="text-nowrap btn btn-sm btn-primary"
            onClick={() =>
              navigation("/atendimento-detalhes", { state: { appointment } })
            }
          >
            <i className="uil uil-eye me-2"></i>VISUALIZAR
          </button>
          {!isPastAppointment && (
            <button
              className="text-nowrap btn btn-sm btn-outline-danger"
              onClick={() => setModal(true)}
            >
              <i className="uil uil-times me-2"></i>CANCELAR
            </button>
          )}
        </div> */}
      </div>

      {/* Modal de cancelamento de consulta */}
      {/* <AppointmentCancellation
        modal={modal}
        setModal={setModal}
        appointment={appointment}
      /> */}
    </>
  );
};

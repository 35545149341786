import { Dispatch, SetStateAction, useState, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonGroup, Modal, ModalFooter, ToggleButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import QRCode from "react-qr-code";

import Button from "components/Button";
import Input from "components/Input";
import { hsvaToHex, hexToRgba, rgbaToRgb, getContrastingColor } from '@uiw/color-convert';
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { useWhatsapp } from "providers/Whatsapp";
import { api } from "services";
import { toast } from "utils/toast";
import { Swatch } from "@uiw/react-color";
import { CircularProgress } from "@mui/material";
import chatImg from "assets/chat.png";

interface ModalWhatsappProps {
    onHide?: () => void;
}

export interface TagData {
    userId: number;
    color: string;
    title: string;
}

const ModalWhatsapp = ({
    onHide,
}: ModalWhatsappProps) => {
    const { token } = useAuth();
    const { userData } = useUser();
    const navigate = useNavigate();

    const {
        showWhatsappModal,
        setShowWhatsappModal,
        isLoadingWhatsapp,
        setIsLoadingWhatsapp,
        conectedWhatsapp,
        setConectedWhatsapp,
        checkInstance,
        inicializeInstance,
        qrCode,
        setQrCode
    } = useWhatsapp();

    const [timeLeft, setTimeLeft] = useState(60); // 60 segundos (1 minuto)
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        let timer: any;
        if (isRunning && timeLeft > 0 && !conectedWhatsapp) {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000); // atualiza a cada 1 segundo

            if (timeLeft % 5 === 0) {
                checkInstance();
            }
        } else if (timeLeft === 0) {
            clearInterval(timer);
            executeEndFunction(); // Chama a função ao terminar o tempo
        }
        return () => clearInterval(timer); // Limpeza do intervalo ao desmontar ou atualizar
    }, [isRunning, timeLeft, conectedWhatsapp]);

    const handleStart = () => {
        inicializeInstance();
        setTimeLeft(60); // reseta o timer
        setIsRunning(true); // inicia o timer
    };

    const executeEndFunction = () => {
        setIsRunning(false);
        handleStart();
    };

    const progress = ((60 - timeLeft) / 60) * 100;

    useEffect(() => {
        setQrCode("");
        setIsRunning(false);

        return () => {
            setIsRunning(false);
        }
    }, []);

    return (
        <>
            <Modal.Body className="bg-light">
                <div className="row justify-content-center align-items-center mb-3">
                    <div className="col col-auto text-center position-relative">
                        <div className="text-bg-primary d-inline-block lh-1 ratio ratio-1x1 rounded-circle p-3 position-relative z-index-1"><div className="d-flex align-items-center justify-content-center">1</div></div>
                    </div>
                    <div className="col col-auto text-center position-relative">
                        <div className="border-top position-absolute w-100 start-0 ms-n3 top-0 mt-3"></div>
                        <div className={`${conectedWhatsapp || isRunning || qrCode != "" ? "text-bg-primary" : "text-bg-white"} d-inline-block lh-1 ratio ratio-1x1 rounded-circle p-3 position-relative z-index-1`}><div className="d-flex align-items-center justify-content-center">2</div></div>
                    </div>
                    <div className="col col-auto text-center position-relative">
                        <div className="border-top position-absolute w-100 start-0 ms-n3 top-0 mt-3"></div>
                        <div className={`${conectedWhatsapp ? "text-bg-primary" : "text-bg-white"} d-inline-block lh-1 ratio ratio-1x1 rounded-circle p-3 position-relative z-index-1`}><div className="d-flex align-items-center justify-content-center">3</div></div>
                    </div>
                </div>
                {conectedWhatsapp ? (
                    <>
                        <div className="swal2-icon swal2-success swal2-icon-show d-flex"><div className="swal2-success-circular-line-left"></div>
                            <span className="swal2-success-line-tip"></span> <span className="swal2-success-line-long"></span>
                            <div className="swal2-success-ring"></div> <div className="swal2-success-fix"></div>
                            <div className="swal2-success-circular-line-right"></div>
                        </div>
                        <p className="fw-bold mb-0 text-center">Seu WhatsApp foi conectado com sucesso.</p>
                        <p className="opacity-75 text-center">Como deseja prosseguir?</p>
                    </>
                ) : (
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="row g-3 mb-3">
                                <div className="col col-auto position-relative align-self-center">
                                    <span className="bg-whatsapp px-2 fs-2 d-block rounded-circle lh-1 py-2 text-pure-white"><i className="uil uil-whatsapp"></i></span>
                                </div>
                                <div className="col">
                                    <p className="fw-bold mb-0">Siga o passo a passo para integrar seu WhatsApp e converse com seus pacientes pelo CRM!</p>
                                </div>
                            </div>
                            <p className="opacity-75">Antes de inciar, tenha seu celular em mãos para escanear o Qr Code que será gerado a seguir.</p>
                            <ul>
                                <li>Abra o WhatsApp no seu celular que deseja conectar.</li>
                                <li>Toque no menu (ícone de três pontos).</li>
                                <li>Selecione "Dispositivos conectados".</li>
                                <li>Toque em "Conectar um aparelho".</li>
                                <li>Se necessário, valide o processo com a sua senha.</li>
                                <li>Aponte a câmera para escanear o QR Code.</li>
                            </ul>
                        </div>
                        <div className="col-12 col-xl-6 align-self-center">
                            {isLoadingWhatsapp || isRunning ? (
                                <>
                                    <div className="mx-auto" style={{ maxWidth: 256, width: "100%" }}>
                                        {qrCode != "" ? (
                                            <div className="rounded-4 shadow mb-3 text-center bg-white">
                                                <img
                                                    width={256}
                                                    className="img-fluid rounded-4"
                                                    src={qrCode}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <div className="rounded-4 shadow mb-3 text-center bg-white d-flex algin-items-center ratio ratio-1x1">
                                                    <div>
                                                        <CircularProgress
                                                            size={40}
                                                            className="position-absolute top-50 start-50 translate-middle"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {isRunning && (
                                            <>
                                                <div className="progress mb-3 mx-3" style={{ height: 5 }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }}></div>
                                                </div>
                                                <p className="opacity-75 lh-sm text-center"><small>Um novo Qr Code será gerado ao expirar o tempo.</small></p>
                                            </>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <div className="text-center">
                                    <img src={chatImg} style={{ maxHeight: '30vh', maxWidth: "100%" }} />
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Modal.Body>
            <ModalFooter className="justify-content-between">
                {conectedWhatsapp ? (
                    <>
                    <button onClick={() => { setShowWhatsappModal(false); setQrCode(""); }} className="btn btn-light rounded-pill">Fechar</button>
                    <a className="btn btn-dark rounded-pill" onClick={()=>{navigate('/conversas')}}><i className="uil uil-comments-alt me-2"></i>Ir para Conversas</a>
                    </>
                ) : (
                    <>
                        <a onClick={() => { setShowWhatsappModal(false); setQrCode(""); }} className="btn btn-light rounded-pill align-self-start">Cancelar</a>
                        <button onClick={handleStart} className="btn btn-primary rounded-pill" disabled={isRunning}>Gerar QR Code<i className="uil uil-arrow-right ms-2"></i></button>
                    </>
                )}
            </ModalFooter>
        </>
    );
};

export default ModalWhatsapp;

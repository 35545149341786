import { useState, useEffect, useRef, ReactNode } from "react";
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";
import { useParams } from 'react-router';
import { Accordion, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ModalComponent from 'components/ModalComponent';
import { ErrorBoundary } from "react-error-boundary";
import { useAuth } from "providers/Auth";
import { useDarkMode } from "providers/DarkMode";
import Footer from "components/Footer";
import imgGirl from "assets/girl.png";
import imgLinha from "assets/gerenciamento.png";
import imgLogo from "assets/logo-conectadoc.svg";
import imgLogoHorizontal from "assets/logo-horizontal.svg";
import imgLogoWhite from "assets/logo-branco.svg";
import imgTasks from "assets/tasks.png";
import imgPacientes from "assets/pacientes.png";
import imgEncaminhamento from "assets/encaminhamento.png";
import imgEncaminhamentoBoard from "assets/encaminhamento-board.png";
import imgIntegracoes from "assets/integracoes.png";
import imgTask from "assets/task.png";
import imgDashboard from "assets/dashboard.png";
import imgDash1 from "assets/dash-1.png";
import imgDash2 from "assets/dash-2.png";
import imgDash3 from "assets/dash-3.png";
import imgMobile1 from "assets/mobile1.png";
import imgMobile2 from "assets/mobile2.png";
import imgMobile3 from "assets/mobile3.png";
import imgMobile4 from "assets/mobile4.png";
import imgMobile5 from "assets/mobile5.png";
import imgMobile6 from "assets/mobile6.png";
import user from "assets/user.png";
import medico1 from "assets/medicos/medico-1.png";
import medico2 from "assets/medicos/medico-2.png";
import medico3 from "assets/medicos/medico-3.png";
import medico4 from "assets/medicos/medico-4.png";
import medico5 from "assets/medicos/medico-5.png";
import medico6 from "assets/medicos/medico-6.png";
import medico7 from "assets/medicos/medico-7.png";
import medico8 from "assets/medicos/medico-8.png";
import Magnetic from './utils/Magnetic';
import Animated from './components/Animated';
import { motion, useScroll, useTransform, useAnimation, Variants } from "framer-motion";

import "./utils/home.css";


const Index = () => {
  useEffect(() => { document.title = 'CRM - Conectadoc'; }, []);

  const navigate = useNavigate();
  const { id } = useParams();
  const { idboard } = useParams();
  const URL_CONTATO = "https://wa.me/554198508195"

  useEffect(() => {
    if (id && idboard) {
      navigate(
        '/cadastro',
        {
          state: {
            id: id,
            idboard: idboard
          }
        });
    }
  });

  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end end"]
  });
  useEffect(() => {
    const unsubscr = scrollYProgress.onChange(value => {
      console.log("Scroll Y Progress:", value); // Log do progresso do scroll
    });

    return () => unsubscr(); // Limpa o listener ao desmontar
  }, [scrollYProgress]);

  // Transformação de rotação com base no progresso do scroll
  const rotateX = useTransform(scrollYProgress, [0, 1], [40, 0]);

  const { scrollY } = useScroll(); // Captura o valor de scroll vertical
  const [hasScrolled, setHasScrolled] = useState(false); // Define estado para o scroll

  useEffect(() => {
    const unsubscribe = scrollY.onChange((current) => {
      setHasScrolled(current > 0); // Atualiza o estado se o scroll for maior que zero
    });

    return () => unsubscribe(); // Cleanup da função onChange
  }, [scrollY]);

  const [visibleImageIndex, setVisibleImageIndex] = useState<number>(1);
  const toggleImage = (index: number) => {
    setVisibleImageIndex(index);
  }

  const scrollTo = (id: string) => {
    const anchor = document.getElementById(id);
    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const { darkMode, menuShow, setMenuShow, handleMenuShow } = useDarkMode();
  const [hideTooltip, setHideTooltip] = useState(false);

  const handleMenuClick = () => {
    if (document.body.clientWidth <= 640) {
      setMenuShow(false);
    }
  }

  const handleResize = () => {
    if (document.body.clientWidth < 576) {
      setHideTooltip(true);
    } else {
      setHideTooltip(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <div className="flex flex-column w-100">
        <header id="header-site" className={`${hasScrolled ? "scrolled" : ""}`}>
          <div className="container">
            <div className="bg-header py-3">
              <div className="row align-items-center justify-content-between justify-content-md-center g-2">
                <div className="col col-lg-auto text-lg-center order-0">
                  <a href="/" className="logo">
                    <h1><img src={imgLogoHorizontal} className="img-fluid" alt="CRM Conectadoc" /></h1>
                  </a>
                </div>
                <div className="col col-auto col-md-12 col-lg text-end text-md-center order-2 order-lg-1">
                  <a role="button" className="abre-menu btn rounded-pill btn-outline-primary" onClick={handleMenuShow}><i className="uil uil-bars"></i><span className="ms-2">Menu</span></a>
                  <nav id="menu">
                    <ul className="flex-wrap">
                      <li><a role="button" onClick={() => { handleMenuClick(); scrollTo('como-funciona'); }}><span>Como funciona</span></a></li>
                      <li><a role="button" onClick={() => { handleMenuClick(); scrollTo('funcionalidades') }}><span>Funcionalidades</span></a></li>
                      <li><a role="button" onClick={() => { handleMenuClick(); scrollTo('planos') }}><span>Planos e Preços</span></a></li>
                      {/* <li><a role="button" onClick={() => { handleMenuClick(); scrollTo('faq') }}><span>FAQ</span></a></li> */}
                      <li><a target="_blank" href="https://conectadoc.com.br/quem-somos"><span>Quem Somos</span></a></li>
                    </ul>
                  </nav>
                </div>
                <div className="col col-auto text-center order-1 order-lg-2 gap-2">
                  <div className="d-inline-block">
                    <Magnetic>
                      <Link to="/login" onClick={() => { handleMenuClick(); }} className="btn btn-primary rounded-pill px-3"><i className="uil uil-lock me-2"></i>Login</Link>
                    </Magnetic>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div id="site">
          <div className="container">

            <section id="banner" className="row justify-content-center padding-header">
              <div className="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 col-xxl-7 text-center pt-5">
                <h1 className="display-3 fw-semibold lh-1 mb-4 animate__animated animate__delay-200ms animate__fadeInUp">Transforme a gestão dos seus pacientes.</h1>
                <div className="animate__animated animate__delay-300ms animate__fadeInUp">
                  <p className="fs-4 lh-sm opacity-50">Organize e acompanhe o fluxo de pacientes de forma eficiente com nosso CRM personalizado para profissionais da saúde.</p>
                </div>
                <div className="row justify-content-center align-items-center py-5 animate__animated animate__delay-400ms animate__fadeInUp">
                  <div className="col-12 col-sm-auto">
                    <OverlayTrigger placement="top" overlay={<Tooltip>RODRIGO KOPP REZENDE</Tooltip>}>
                      <img src={medico2} alt="RODRIGO KOPP REZENDE" className="avatar img-fluid rounded-circle ms-n3 border border-2 border-light" />
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>CASSIO WASSANO IWAMOTO</Tooltip>}>
                      <img src={medico1} alt="CASSIO WASSANO IWAMOTO" className="avatar img-fluid rounded-circle ms-n3 border border-2 border-light" />
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>JOICE CRISTINA DALTOE INGLEZ</Tooltip>}>
                      <img src={medico6} alt="JOICE CRISTINA DALTOE INGLEZ" className="avatar img-fluid rounded-circle ms-n3 border border-2 border-light" />
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>RAFAEL FERRI MARTINS</Tooltip>}>
                      <img src={medico3} alt="RAFAEL FERRI MARTINS" className="avatar img-fluid rounded-circle ms-n3 border border-2 border-light" />
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>IGOR YABUSAME FRANCO TERRUEL</Tooltip>}>
                      <img src={medico4} alt="IGOR YABUSAME FRANCO TERRUEL" className="avatar img-fluid rounded-circle ms-n3 border border-2 border-light" />
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>MARCO CÉSAR JORGE DOS SANTOS</Tooltip>}>
                      <img src={medico5} alt="MARCO CÉSAR JORGE DOS SANTOS" className="avatar img-fluid rounded-circle ms-n3 border border-2 border-light" />
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>PETER FERENCZY</Tooltip>}>
                      <img src={medico7} alt="PETER FERENCZY" className="avatar img-fluid rounded-circle ms-n3 border border-2 border-light" />
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>DIEGO SHERLON PIZZAMIGLIO</Tooltip>}>
                      <img src={medico8} alt="DIEGO SHERLON PIZZAMIGLIO" className="avatar img-fluid rounded-circle ms-n3 border border-2 border-light" />
                    </OverlayTrigger>
                  </div>
                  <div className="col col-auto small">
                    Profissionais que já utilizam o CRM.
                  </div>
                </div>
                <div className="animate__animated animate__delay-600ms animate__fadeInUp">
                  <Magnetic>
                    <a target="_blank" href={`${URL_CONTATO}?text=Gostaria de solicitar uma demonstraçào do CRM`} className="btn btn-lg btn-primary rounded-pill"><i className="uil uil-whatsapp me-2"></i>Solicite uma demonstração</a>
                  </Magnetic>
                </div>
              </div>
            </section>
          </div>
          <section className="bg-gradient-print">
            <div className="bg-rounded">
              <div className="container animate__animated animate__delay-800ms animate__fadeInUp mb-n5" style={{ perspective: 1000 }}>
                <motion.div
                  ref={ref}
                  initial={{ rotateX: 40, y: 0 }}
                  className="row justify-content-center"
                  style={{
                    rotateX,
                    transformStyle: "preserve-3d"
                  }}>
                  <div className="col-12 col-md-10 col-xl-9 text-center">
                    <div className="row justify-content-center justify-content-xl-between mb-xl-n5">
                      <div className="col-6 col-xl-3">
                        <img src={imgDash3} className="img-fluid rounded-4 shadow img-print-1" />
                      </div>
                      <div className="col-6 col-xl-4 pb-5">
                        <img src={imgDash1} className="img-fluid rounded-4 shadow img-print-2" />
                      </div>
                      <div className="col-6 col-xl-4 pb-2">
                        <img src={imgDash2} className="img-fluid rounded-4 shadow img-print-3" />
                      </div>
                    </div>
                    <img src={imgDashboard} className="img-fluid rounded-4 shadow" />
                  </div>
                </motion.div>
              </div>
            </div>
          </section>
          <section id="como-funciona" className="py-5 bg-gradient-login text-pure-white">
            <div className="container py-5">
              <Animated type="fadeIn" threshold={1}>
                <div className="row justify-content-center mb-5">
                  <div className="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 col-xxl-6 text-center pt-5">
                    <div className="d-inline-block bg-light bg-opacity-10 px-3 py-1 rounded-pill">
                      <p className="mb-0">Como funciona</p>
                    </div>
                    <h1 className="fw-bold">Revolucione seu gerenciamento</h1>
                    <h5 className="opacity-50">Com uma interface intuitiva, projetada para simplificar a administração dos seus pacientes.</h5>
                  </div>
                </div>
              </Animated>

              <Animated type="fadeInUp">
                <div className="card shadow border-0 rounded-4 p-5 mb-5">
                  <div className="row align-items-center gx-3 gy-5">
                    <div className="col-12 col-md-6 feature-image">
                      <div className="position-absolute start-50 feature-image-cursor">
                        <motion.div
                          ref={ref}
                          animate={{
                            x: [0, 50, 35, 0, 0],
                            y: [0, 50, 80, 20, 0]
                          }}
                          transition={{ type: 'ease', duration: 5, repeat: Infinity }}
                        >
                          <img src={imgTask} className="img-fluid rounded-4 shadow img-mouse" />
                        </motion.div>
                      </div>
                      <img src={imgTasks} />
                    </div>
                    <div className="col offset-md-1">
                      <h2 className="mb-4">Aumente suas conversões em até <b className="hand-circle">200%</b>!</h2>
                      <p>O CRM CONECTADOC foi pensado para atender as necessidades reais dos profissionais da saúde.</p>
                      <p>Suas funcionalidades são intuitivas e práticas, facilitando a gestão eficiente de consultórios, clínicas e hospitais.</p>
                      <p>Com foco na rotina do profissional, o sistema oferece soluções que otimizam o tempo e garantem um atendimento de qualidade, ajudando a organizar todo o processo de atendimento, desde o recebimento de interesse do paciente pelas redes sociais e WhatsApp, até o encaminhamento adequado com ele em cada etapa da jornada em seu consultório.</p>
                    </div>
                  </div>
                </div>
              </Animated>
              <div className="row g-5 justify-content-center">
                <div className="col-12 col-sm-6 col-lg-3">
                  <Animated type="fadeInUp" delay={0}>
                    <div className="d-flex flex-column text-center">
                      <div>
                        <div className="text-bg-light rounded-circle p-3 d-inline-block mb-3"><i className="uil uil-minus-path fs-2 lh-1"></i></div>
                      </div>
                      <p>Arraste e solte pacientes entre fases de tratamento.</p>
                    </div>
                  </Animated>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  <Animated type="fadeInUp" delay={0.1}>
                    <div className="d-flex flex-column text-center">
                      <div>
                        <div className="text-bg-light rounded-circle p-3 d-inline-block mb-3"><i className="uil uil-grids fs-2 lh-1"></i></div>
                      </div>
                      <p>Personalize as colunas para refletir o seu fluxo de trabalho.</p>
                    </div>
                  </Animated>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  <Animated type="fadeInUp" delay={0.2}>
                    <div className="d-flex flex-column text-center">
                      <div>
                        <div className="text-bg-light rounded-circle p-3 d-inline-block mb-3"><i className="uil uil-history fs-2 lh-1"></i></div>
                      </div>
                      <p>Acompanhe o histórico e progresso dos pacientes em tempo real.</p>
                    </div>
                  </Animated>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  <Animated type="fadeInUp" delay={0.3}>
                    <div className="d-flex flex-column text-center">
                      <div>
                        <div className="text-bg-light rounded-circle p-3 d-inline-block mb-3"><i className="uil uil-user-arrows fs-2 lh-1"></i></div>
                      </div>
                      <p>Rede de encaminhamento entre profissionais.</p>
                    </div>
                  </Animated>
                </div>
              </div>
              <Animated type="fadeInUp" delay={0.4}>
                <p className="text-center pt-5">e muito mais...</p>
              </Animated>
            </div>
          </section>
          <section id="funcionalidades" className="py-5 bg-white">
            <div className="container py-5">
              <Animated type="fadeIn" threshold={1}>
                <div className="row justify-content-center mb-5">
                  <div className="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 col-xxl-6 text-center pt-5">
                    <div className="d-inline-block bg-secondary bg-opacity-10 px-3 py-1 rounded-pill">
                      <p className="text-primary mb-0">Principais Funcionalidades</p>
                    </div>
                    <h1 className="fw-bold">Automatize processos para ganhar tempo</h1>
                    <h5 className="opacity-50">Elimine tarefas manuais repetitivas e concentre-se no atendimento ao paciente.</h5>
                  </div>
                </div>
              </Animated>

              <div className="row g-5 justify-content-center">
                <div className="col-12 col-lg-6 d-flex flex-column">
                  <Animated type="fadeInLeft" delay={0.3}>
                    <div className="card rounded-4 p-5 flex-grow-1">
                      <div className="row align-items-center g-4">
                        <div className="col-12">
                          <h3>Rede de encaminhamento</h3>
                          <p>Ferramenta de encaminhamento de pacientes à demais profissionais e instituições.</p>
                        </div>
                        <div className="col-12">
                          <div className="row align-items-end g-4">
                            <div className="col-6">
                              <img src={imgEncaminhamentoBoard} className="rounded-2 img-fluid border" />
                            </div>
                            <div className="col-6">
                              <img src={imgEncaminhamento} className="rounded-2 img-fluid border mt-n4" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Animated>
                </div>
                <div className="col-12 col-lg-6 d-flex flex-column">
                  <Animated type="fadeInRight">
                    <div className="card rounded-4 p-5 pb-0 flex-grow-1">
                      <div className="row align-items-center g-4">
                        <div className="col-12">
                          <h3>Gestão de contatos</h3>
                          <p>Registro e organização de pacientes e suas jornadas no consultório com dados cadastrais, e observações relevantes.</p>
                        </div>
                        <div className="col-12">
                          <img src={imgPacientes} className="rounded-2 rounded-bottom-0 img-fluid border border-bottom-0" />
                        </div>
                      </div>
                    </div>
                  </Animated>
                </div>
                <div className="col-12 d-flex flex-column pt-5">
                  <Animated type="fadeInUp">
                    <div className="card rounded-4 px-5 mb-5 flex-grow-1">
                      <div className="row align-items-center gx-5 gy-4">
                        <div className="col-12 col-lg-5 py-5">
                          <h3>Integração com outras ferramentas</h3>
                          <p>Integra-se com WhatsApp, sistemas de e-mail e redes sociais para centralizar todas as interações e dados.</p>
                          <div className="text-center text-lg-start">
                            <Magnetic>
                              <a target="_blank" href={`${URL_CONTATO}?text=Gostaria de solicitar uma demonstraçào do CRM`} className="btn btn-lg btn-primary rounded-pill"><i className="uil uil-whatsapp me-2"></i>Solicite uma demonstração</a>
                            </Magnetic>
                          </div>
                        </div>
                        <div className="col-12 col-lg-7 text-end">
                          <img src={imgIntegracoes} className="rounded-2 border img-fluid my-n5" />
                        </div>
                      </div>
                    </div>
                  </Animated>
                </div>
              </div>
            </div>
          </section>

          <section className="py-5">
            <div className="container py-5">
              <Animated type="fadeIn" threshold={1}>
                <div className="row justify-content-center mb-5">
                  <div className="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 col-xxl-6 text-center pt-5">
                    <div className="d-inline-block bg-secondary bg-opacity-10 px-3 py-1 rounded-pill">
                      <p className="text-primary mb-0">Onde e quando você precisar!</p>
                    </div>
                    <h1 className="fw-bold">Integração e Mobilidade</h1>
                    <h5 className="opacity-50">Totalmente integrado a dispositivos móveis, permitindo que você e sua equipe acompanhem os pacientes de qualquer lugar.</h5>
                  </div>
                </div>
              </Animated>
              <div className="row justify-content-center align-items-center g-5">
                <div className="col-12 col-md-6 col-lg order-1 order-lg-0">
                  <div className="d-flex flex-column justify-content-between text-center gap-5">
                    <Animated type="fadeInLeft">
                      <div className={`mobile-indicator p-4 shadow-sm rounded-4 ${visibleImageIndex == 1 ? 'text-bg-primary' : 'bg-white'}`} role="button" onClick={() => { toggleImage(1); }}>
                        <h5>Seus Boards</h5>
                        <p className="mb-0 small">Crie quantos boards precisar para organizar sua rotina, é possível encaminhar paciente entre boards.</p>
                      </div>
                    </Animated>
                    <Animated type="fadeInLeft">
                      <div className={`mobile-indicator p-4 shadow-sm rounded-4 ${visibleImageIndex == 2 ? 'text-bg-primary' : 'bg-white'}`} role="button" onClick={() => { toggleImage(2); }}>
                        <h5>Lista de Pacientes</h5>
                        <p className="mb-0 small">Exiba os pacientes de todos os boards em uma única lista, filtre e edite os dados de seus pacientes em uma única tela.</p>
                      </div>
                    </Animated>
                    <Animated type="fadeInLeft">
                      <div className={`mobile-indicator p-4 shadow-sm rounded-4 ${visibleImageIndex == 3 ? 'text-bg-primary' : 'bg-white'}`} role="button" onClick={() => { toggleImage(3); }}>
                        <h5>Integrações</h5>
                        <p className="mb-0 small">Integre e converse com seus pacientes diretamente através do CRM, sem precisar de uma ferramenta externa.</p>
                      </div>
                    </Animated>
                  </div>
                </div>
                <div className="col-11 col-sm-10 col-md-8 col-lg-4 order-0 order-lg-1">
                  <Animated type="fadeIn">
                    <img src={imgMobile1} className={`img-fluid ${visibleImageIndex == 1 ? '' : 'd-none'}`} alt="Gerenciamento eficiente" />
                    <img src={imgMobile2} className={`img-fluid ${visibleImageIndex == 2 ? '' : 'd-none'}`} alt="Gerenciamento eficiente" />
                    <img src={imgMobile3} className={`img-fluid ${visibleImageIndex == 3 ? '' : 'd-none'}`} alt="Gerenciamento eficiente" />
                    <img src={imgMobile4} className={`img-fluid ${visibleImageIndex == 4 ? '' : 'd-none'}`} alt="Gerenciamento eficiente" />
                    <img src={imgMobile5} className={`img-fluid ${visibleImageIndex == 5 ? '' : 'd-none'}`} alt="Gerenciamento eficiente" />
                    <img src={imgMobile6} className={`img-fluid ${visibleImageIndex == 6 ? '' : 'd-none'}`} alt="Gerenciamento eficiente" />
                  </Animated>
                </div>
                <div className="col-12 col-md-6 col-lg order-2">
                  <div className="d-flex flex-column justify-content-between text-center gap-5">
                    <Animated type="fadeInRight" delay={0.3}>
                      <div className={`mobile-indicator right p-4 shadow-sm rounded-4 ${visibleImageIndex == 4 ? 'text-bg-primary' : 'bg-white'}`} role="button" onClick={() => { toggleImage(4); }}>
                        <h5>Calendário</h5>
                        <p className="mb-0 small">Organize e visualize os agendamentos através do calendário, fica muito mais fácil e intuitivo.</p>
                      </div>
                    </Animated>
                    <Animated type="fadeInRight" delay={0.3}>
                      <div className={`mobile-indicator right p-4 shadow-sm rounded-4 ${visibleImageIndex == 5 ? 'text-bg-primary' : 'bg-white'}`} role="button" onClick={() => { toggleImage(5); }}>
                        <h5>Conversas</h5>
                        <p className="mb-0 small">Através da integração do WhatsApp, Instagram e Facebook, converta suas conversas em novas oportunidades.</p>
                      </div>
                    </Animated>
                    <Animated type="fadeInRight" delay={0.3}>
                      <div className={`mobile-indicator right p-4 shadow-sm rounded-4 ${visibleImageIndex == 6 ? 'text-bg-primary' : 'bg-white'}`} role="button" onClick={() => { toggleImage(6); }}>
                        <h5>QrCode</h5>
                        <p className="mb-0 small">Gere um QrCode para cada board, facilitando a entrada do paciente como oportunidade, onde ele mesmo preenche seus dados enquanto aguarda ser atendido.</p>
                      </div>
                    </Animated>
                  </div>

                </div>
              </div>
            </div>
          </section>

          <section className="row g-0 min-vh-100">
            <div className="col-12 bg-gradient-login px-5 d-flex flex-column align-items-center justify-content-center">
              <div className="bg-image-login position-relative d-flex ps-5 pt-5 shadow-lg">
                <div className="col col-2 pb-5">
                  <img src={imgLinha} className="img-fluid animate__animate__animated animate__slideInLeft" alt="Gerenciamento eficiente" />
                </div>
                <div className="col d-flex flex-column">
                  <div className="icon-animation">
                    <div className="circle shadow-lg">
                      <i className="uil uil-lock fs-1 lh-1 text-primary"></i>
                    </div>
                  </div>
                  <div className="ps-4">
                    <h3 className="mb-4 me-4 text-pure-white fw-light animate__animated animate__delay-100ms animate__aslideInDown">Facilite seu fluxo de trabalho com o acompanhamento de seus pacientes.</h3>
                  </div>
                  <div className="flex-grow-1">
                    <img src={imgGirl} className="position-absolute animate__animated animate__delay-100ms animate__slideInUp" style={{ width: '90%', left: '20%' }} />
                  </div>
                </div>
              </div>
              <div className="text-center mt-n3">
                <Magnetic>
                  <a target="_blank" href={`${URL_CONTATO}?text=Gostaria de solicitar uma demonstraçào do CRM`} className="btn btn-lg btn-light rounded-pill"><i className="uil uil-whatsapp me-2"></i>Solicite uma demonstração</a>
                </Magnetic>
              </div>
            </div>
          </section>

          <section id="planos" className="py-5">
            <div className="container py-5">
              <Animated type="fadeIn" threshold={1}>
                <div className="row justify-content-center mb-5">
                  <div className="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 col-xxl-6 text-center pt-5">
                    <div className="d-inline-block bg-secondary bg-opacity-10 px-3 py-1 rounded-pill">
                      <p className="text-primary mb-0">Preços e Planos</p>
                    </div>
                    <h1 className="fw-bold">Automatize processos para ganhar tempo</h1>
                    <h5 className="opacity-50">Elimine tarefas manuais repetitivas e concentre-se no atendimento ao paciente.</h5>
                  </div>
                </div>
              </Animated>
              <div className="row g-4 justify-content-center">
                <div className="col-12 col-lg-4 d-flex flex-column">
                  <div className="card shadow border-0 rounded-4 p-5">
                    <h3>Assinatura mensal</h3>
                    <p className="d-flex align-items-center gap-2"><b className="fs-3">R$ 299,00</b>por mês</p>
                    <ul className="list-unstyled">
                      <li className="d-flex gap-2 align-items-center">
                        <i className="uil uil-check-circle text-success fs-3"></i>
                        <span>Jornada do Paciente</span>
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <i className="uil uil-check-circle text-success fs-3"></i>
                        <span>Relatórios e Análises</span>
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <i className="uil uil-check-circle text-success fs-3"></i>
                        <span>Integrações</span>
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <i className="uil uil-check-circle text-success fs-3"></i>
                        <span>Rede de Encaminhamento</span>
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <i className="uil uil-check-circle text-success fs-3"></i>
                        <span>Gestão de Contatos</span>
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <i className="uil uil-check-circle text-success fs-3"></i>
                        <span>Calendário</span>
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <i className="uil uil-times-circle text-danger fs-3"></i>
                        <span>Implantação!</span>
                      </li>
                    </ul>
                    <div className="text-end mt-auto">
                      <Magnetic>
                        <a target="_blank" href={`${URL_CONTATO}?text=Gostaria de contratar a Assinatura Mensal do CRM`} role="button" className="btn btn-outline-primary btn-lg rounded-pill px-3">Selecionar este plano<i className="uil uil-arrow-right ms-2"></i></a>
                      </Magnetic>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 d-flex flex-column">
                  <div className="card shadow border-0 rounded-4 p-5">
                    <h3>Assinatura anual</h3>
                    <p className="d-flex align-items-center gap-2"><b className="fs-3">R$ 199,00</b>por mês</p>
                    <ul className="list-unstyled">
                      <li className="d-flex gap-2 align-items-center">
                        <i className="uil uil-check-circle text-success fs-3"></i>
                        <span>Jornada do Paciente</span>
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <i className="uil uil-check-circle text-success fs-3"></i>
                        <span>Relatórios e Análises</span>
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <i className="uil uil-check-circle text-success fs-3"></i>
                        <span>Integrações</span>
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <i className="uil uil-check-circle text-success fs-3"></i>
                        <span>Rede de Encaminhamento</span>
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <i className="uil uil-check-circle text-success fs-3"></i>
                        <span>Gestão de Contatos</span>
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <i className="uil uil-check-circle text-success fs-3"></i>
                        <span>Calendário</span>
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <i className="uil uil-check-circle text-success fs-3"></i>
                        <span>Ao assinar o plano anual, <b>ganhe</b> a implantação!</span>
                      </li>
                    </ul>
                    <div className="text-end mt-auto">
                      <Magnetic>
                        <a target="_blank" href={`${URL_CONTATO}?text=Gostaria de contratar a Assinatura Anual do CRM`} role="button" className="btn btn-primary btn-lg rounded-pill px-3">Selecionar este plano<i className="uil uil-arrow-right ms-2"></i></a>
                      </Magnetic>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 d-flex flex-column gap-4">
                  <div className="card shadow border-0 rounded-4 p-5 flex-grow-1 text-bg-dark">
                    <h3>Implantação e Consultoria</h3>
                    <p className="small">Processo de implantação do sistema em seu consultório e consultoria personalizada das funcionalidades para toda a sua equipe!</p>
                    <p className="d-flex align-items-center gap-2"><b className="fs-3">R$ 2.000,00</b> em até 12x</p>
                    <div className="text-end mt-auto">
                      <Magnetic>
                        <a target="_blank" href={`${URL_CONTATO}?text=Gostaria de contratar a Implantação e Consultoria do CRM`} role="button" className="btn btn-primary btn-lg rounded-pill px-3">Contratar<i className="uil uil-arrow-right ms-2"></i></a>
                      </Magnetic>
                    </div>
                  </div>
                  <div className="card shadow border-0 rounded-4 p-5 flex-grow-1 text-bg-primary">
                    <h3>Imersão CRM</h3>
                    <p className="small">Curso presencial certificado, realizado por equipe capacitada, oferecendo em todas as ferramentas e funcionalidades da plataforma CRM CONECTADOC.</p>
                    <p className="d-flex align-items-center gap-2"><b className="fs-3">R$ 999</b> em até 12x</p>
                    <div className="text-end mt-auto">
                      <Magnetic>
                        <a target="_blank" href={`${URL_CONTATO}?text=Gostaria de contratar a Imersão CRM`} role="button" className="btn btn-light btn-lg rounded-pill px-3">Contratar<i className="uil uil-arrow-right ms-2"></i></a>
                      </Magnetic>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section id="faq" className="py-5 bg-white">
            <div className="container py-5">
              <div className="row mb-5">
                <div className="col-12 col-md">
                  <Animated type="fadeIn" threshold={1}>
                    <div className="d-inline-block bg-secondary bg-opacity-10 px-3 py-1 rounded-pill">
                      <p className="text-primary mb-0">FAQ</p>
                    </div>
                    <h1 className="fw-bold">Perguntas Frequentes</h1>
                    <h5 className="opacity-50">Encontre respostas para as perguntas mais comuns sobre nossa ferramenta de gerenciamento de pacientes.</h5>
                  </Animated>
                </div>
                <div className="col-12 col-md-7 offset-md-1">
                  <Accordion className="mb-3">
                    <Accordion.Item eventKey="pergunta-1" >
                      <Accordion.Header>O que está incluído no plano Starter gratuito?</Accordion.Header>
                      <Accordion.Body>
                        <>
                          O plano Starter inclui projetos ilimitados, gerenciamento básico de tarefas, colaboração em tempo real e acesso ao suporte da nossa comunidade. É perfeito para indivíduos e pequenas equipes que estão começando com o link de amostra de gerenciamento de projetos.
                        </>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="pergunta-2" >
                      <Accordion.Header>Posso atualizar meu plano a qualquer momento?</Accordion.Header>
                      <Accordion.Body>
                        <>
                          Sim, você pode atualizar seu plano a qualquer momento para acessar recursos e suporte mais avançados. Basta ir até as configurações da sua conta e escolher o plano que melhor se adapta às suas necessidades.
                        </>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="pergunta-3" >
                      <Accordion.Header>Como funciona o teste gratuito do plano Teams?</Accordion.Header>
                      <Accordion.Body>
                        <>
                          Ao assinar o plano Teams, você ganha um teste gratuito de 14 dias com acesso a todos os recursos incluídos no plano. Você pode decidir continuar com o plano Teams, fazer upgrade para o plano Business ou reverter para o plano Starter após o período de teste.
                        </>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="pergunta-4" >
                      <Accordion.Header>Quais métodos de pagamento vocês aceitam?</Accordion.Header>
                      <Accordion.Body>
                        <>
                          Aceitamos todos os principais cartões de crédito, incluindo Visa, MasterCard, American Express e Discover. Os pagamentos são processados com segurança por meio do nosso gateway de pagamento.
                        </>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="pergunta-5" >
                      <Accordion.Header>Meus dados estão seguros?</Accordion.Header>
                      <Accordion.Body>
                        <>
                          Sim, levamos a segurança de dados muito a sério. Nossa plataforma usa medidas de segurança avançadas, incluindo criptografia e auditorias de segurança regulares, para garantir que seus dados estejam protegidos o tempo todo.
                        </>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="pergunta-6" >
                      <Accordion.Header>Vocês oferecem suporte se eu precisar de ajuda?</Accordion.Header>
                      <Accordion.Body>
                        <>
                          Com certeza! O plano Starter inclui suporte da comunidade, enquanto os planos Teams e Business oferecem suporte prioritário. Usuários do plano Business também têm acesso a um gerente de conta dedicado para assistência personalizada.
                        </>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="pergunta-7" >
                      <Accordion.Header>Posso cancelar minha assinatura a qualquer momento?</Accordion.Header>
                      <Accordion.Body>
                        <>
                          Sim, você pode cancelar sua assinatura a qualquer momento nas configurações da sua conta. Sua conta permanecerá ativa até o final do período de cobrança atual.
                        </>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="pergunta-8" >
                      <Accordion.Header>Que integrações vocês oferecem?</Accordion.Header>
                      <Accordion.Body>
                        <>
                          Nossa plataforma integra-se perfeitamente com uma variedade de ferramentas e plataformas, incluindo Slack, Google Drive, Dropbox e muitas outras. O plano Business inclui acesso total a todas as integrações.
                        </>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </section> */}

          <section className="py-5 bg-white">
            <div className="container py-5">
              <div className="row justify-content-center mb-5">
                <div className="col-12">
                  <Animated type="bounceInUp">
                    <div className="card p-5 text-bg-primary bg-cta rounded-4 text-center">
                      <div className="row justify-content-center">
                        <div className="col-12 col-md-11 col-xl-10">
                          <div className="d-flex flex-column">
                            <h1 className="fw-bold">Transforme a Gestão do Seu Consultório com o CRM Conectadoc</h1>
                            <h5 className="opacity-75 mb-4">Junte-se à crescente comunidade de profissionais da saúde que estão aprimorando seus atendimentos e otimizaram seus fluxos de trabalho com o CRM Conectadoc.</h5>
                            <p className="mb-4">Nossa plataforma oferece uma ferramenta poderosa e intuitiva, desenvolvida especialmente para médicos e clínicas, com o objetivo de facilitar o gerenciamento de pacientes, consultas e todas as demandas do seu consultório.</p>
                            <div>
                              <Magnetic>
                                <a target="_blank" href={`${URL_CONTATO}?text=Gostaria de solicitar uma demonstraçào do CRM`} role="button" className="btn btn-light btn-lg rounded-pill px-3"><i className="uil uil-whatsapp me-2"></i>Solicite uma demonstração</a>
                              </Magnetic>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Animated>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="class-footer-purple text-white">
          <div>
            <div className="container border-bottom border-light border-opacity-50 py-5">
              <div className="row g-5 justify-content-between">
                <div className="col-12 col-sm-6 col-lg-4 col-lg-3 order-0">
                  <Magnetic>
                    <a href="/" className="logo">
                      <h1><img src={imgLogoWhite} className="img-fluid" alt="CRM Conectadoc" width={100} /></h1>
                    </a>
                  </Magnetic>
                  <p className="pt-2 small opacity-75">Somos uma plataforma de saúde integrada que utiliza ferramentas para melhoria da comunicação entre profissionais de saúde e pacientes.</p>
                </div>
                <div className="col-12 col-lg-4 order-2 order-lg-1">
                  <div className="row g-5">
                    <div className="col-12 col-sm-6">
                      <small className="opacity-75 d-inline-block border-bottom border-opacity-50 mb-3 lh-1 px-2 block pb-2 mx-n2">Menu</small>
                      <ul className="list-unstyled mb-0 d-flex flex-column gap-3">
                        <li><a className="text-decoration-hover text-white" role="button" onClick={() => scrollTo('como-funciona')}><span>Como funciona</span></a></li>
                        <li><a className="text-decoration-hover text-white" role="button" onClick={() => scrollTo('funcionalidades')}><span>Funcionalidades</span></a></li>
                        <li><a className="text-decoration-hover text-white" role="button" onClick={() => scrollTo('planos')}><span>Planos e Preços</span></a></li>
                        <li><a className="text-decoration-hover text-white" role="button" onClick={() => scrollTo('faq')}><span>FAQ</span></a></li>
                        <li><a className="text-decoration-hover text-white" target="_blank" href="https://conectadoc.com.br/quem-somos"><span>Quem Somos</span></a></li>
                      </ul>
                    </div>
                    <div className="col-12 col-sm-6">
                      <small className="opacity-75 d-inline-block border-bottom border-opacity-50 mb-3 lh-1 px-2 block pb-2 mx-n2">Segurança</small>
                      <ul className="list-unstyled mb-0 d-flex flex-column gap-3">
                        <li><a className="text-decoration-hover text-white" role="button" target="_blank" href="https://conectadoc.com.br/protecao-de-dados">Proteção de Dados</a></li>
                        <li><a className="text-decoration-hover text-white" role="button" target="_blank" href="https://conectadoc.com.br/lgpd">LGPD</a></li>
                        <li><a className="text-decoration-hover text-white" role="button" target="_blank" href="https://conectadoc.com.br/aviso-de-privacidade">Aviso de Privacidade</a></li>
                        <li><a className="text-decoration-hover text-white" role="button" target="_blank" href="https://conectadoc.com.br/politica-de-privacidade">Política de Privacidade</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-auto order-1 order-lg-2">
                  <small className="opacity-75 d-inline-block border-bottom border-opacity-50 mb-3 lh-1 px-2 block pb-2 mx-n2">Redes Sociais e Contato</small>
                  <ul className="list-unstyled mb-0 d-flex flex-row gap-3">
                    <li>
                      <OverlayTrigger placement="top" overlay={<Tooltip>Instagram</Tooltip>}>
                        <a className="text-decoration-hover btn-outline-light p-2 lh-1 btn rounded-circle" role="button" target="_blank" href="https://www.instagram.com/conectadoc/"><i className="lh-1 uil uil-instagram"></i></a>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger placement="top" overlay={<Tooltip>Facebook</Tooltip>}>
                        <a className="text-decoration-hover btn-outline-light p-2 lh-1 btn rounded-circle" role="button" target="_blank" href="https://www.facebook.com/conectadoc.com.br"><i className="lh-1 uil uil-facebook"></i></a>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger placement="top" overlay={<Tooltip>Linkedin</Tooltip>}>
                        <a className="text-decoration-hover btn-outline-light p-2 lh-1 btn rounded-circle" role="button" target="_blank" href="https://www.linkedin.com/company/conectadoc"><i className="lh-1 uil uil-linkedin"></i></a>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger placement="top" overlay={<Tooltip>contato@conectadoc.com.br</Tooltip>}>
                        <a className="text-decoration-hover btn-outline-light p-2 lh-1 btn rounded-circle" role="button" target="_blank" href="contato@conectadoc.com.br"><i className="lh-1 uil uil-envelope"></i></a>
                      </OverlayTrigger>
                    </li>

                    <li>
                      <OverlayTrigger placement="top" overlay={<Tooltip>(41) 99755-9100</Tooltip>}>
                        <a className="text-decoration-hover btn-outline-light p-2 lh-1 btn rounded-circle" role="button"><i className="lh-1 uil uil-whatsapp"></i></a>
                      </OverlayTrigger>
                    </li>
                  </ul>

                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
